import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import logo from '../media/ERateCentralLogo.png';
import ClientLogoAndHeaders from './ClientLogoAndHeaders.js';

const CACHE_INCREMENT = 1;
const CLIENT_CACHE_NAME = 'ced-client-funding-year-details-' + CACHE_INCREMENT;

export default function ClientFundingYearDetails(props) {
    const clientAPI = props.clientAPI;
    const kyList = props.kyList;
    let fundingYear = props.fundingYear;
    const state = props.state;
    const searchType = props.searchType;
    const f471appnum = props.f471appnum;
    const fundingYearsDetailsDataKey = `/ClientList/funding-year-details/${kyList}/${
        fundingYear === 'All' ? 'All' : fundingYear
    }/${searchType}`;
    const contactDataKey = `/ClientList/funding-year-details/contact-data/${kyList}/${
        fundingYear === 'All' ? 'All' : fundingYear
    }/${searchType}`;
    const applicantDataKey = `/ClientList/funding-year-details/client-data/${kyList}/${
        fundingYear === 'All' ? 'All' : fundingYear
    }/${searchType}`;
    const [usedCacheOnLoad, setUsedCacheOnLoad] = React.useState(false);
    const [applicantData, setApplicantData] = React.useState([]);
    const [clientContactData, setClientContactData] = React.useState(null);
    const [specificClientContactObj, setSpecificClientContactObj] = React.useState(null);
    const [allStates, setAllStates] = React.useState([]);
    const [specificStates, setSpecificStates] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState('');
    const [fundingDetailsData, setFundingDetailsData] = React.useState([]);
    const [filteredFundingDetailsData, setFilteredFundingDetailsData] = React.useState([]);
    const [cfydRecordData, setCfydRecordData] = React.useState({});
    const [selectedYear, setSelectedYear] = React.useState(fundingYear);
    const [mainSectionIsDone, setMainSectionIsDone] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    let navigate = useNavigate();

    React.useEffect(() => {
        // Sets data to a cache with a specific key
        const cacheData = async (key, data) => {
            try {
                if (data) {
                    let APICache = await caches.open(CLIENT_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    await APICache.put(key, new Response(JSON.stringify(data), headersOptions));
                    console.log(`[ClientFundingYearDetails][useEffect][cacheData] Data cached under key: ${key}`);
                }
            } catch (error) {
                console.error(
                    `[ClientFundingYearDetails][useEffect][cacheData] Error caching data under key: ${key}`,
                    error
                );
            }
        };

        // Checks for the existance of cache and sets that old data to state (temporary display for the user)
        const checkCacheForPreviousData = async (applicantKey, contactKey, fyddKey, ky) => {
            let APICache = await caches.open(CLIENT_CACHE_NAME);

            // Check for cached Client/Applicant data
            const applicantResponse = await APICache.match(applicantKey);
            console.log(
                '1) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] applicantResponse:',
                applicantResponse
            );

            if (applicantResponse !== undefined) {
                const cachedApplicantData = await applicantResponse.json();
                setApplicantData(cachedApplicantData);
                console.log(
                    '2) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] cachedApplicantData (cache found):',
                    cachedApplicantData
                );
            }

            // Check for cached Contact data
            const contactResponse = await APICache.match(contactKey);
            console.log(
                '3) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] contactResponse:',
                contactResponse
            );

            if (contactResponse !== undefined) {
                const cachedContactData = await contactResponse.json();
                setClientContactData(cachedContactData);

                const specificClientContactObjTemp = getClientContactDataObj(cachedContactData);
                if (specificClientContactObjTemp) {
                    setSpecificClientContactObj(specificClientContactObjTemp);
                }

                console.log(
                    '4) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] cachedContactData (cache found):',
                    cachedContactData
                );
            }

            // Check for cached FYDD data
            const fyddResponse = await APICache.match(fyddKey);
            console.log(
                '5) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] fyddResponse:',
                fyddResponse
            );

            if (fyddResponse !== undefined) {
                const cachedFYDData = await fyddResponse.json();
                setFundingDetailsData(cachedFYDData);
                console.log(
                    '6) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] cachedFYDData (cache found):',
                    cachedFYDData
                );

                // Search and set the specific record data
                findSpecificCFYDRecordData(cachedFYDData, ky);
            }

            // Return true if any data was found in the cache
            if (fyddResponse !== undefined || contactResponse !== undefined || applicantResponse !== undefined) {
                setUsedCacheOnLoad(true);
                console.log('7) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] didWeUseCache:', true);
            } else {
                console.log(
                    '7) [ClientFundingYearDetails][useEffect][checkCacheForPreviousData] didWeUseCache:',
                    false
                );
            }
        };

        const findLatestBilledEntityState = (data) => {
            // Get all the keys from the data
            const years = Object.keys(data);

            // Iterate over the keys (already in descending order)
            for (const yearKey of years) {
                // Check if the current year has a non-empty array
                if (data[yearKey].length > 0) {
                    // Return the billedentitystate from the first entry
                    return data[yearKey][0]['billedentitystate'];
                }
            }

            // Return null if no non-empty array was found
            return null;
        };

        const getApplicantBasicInfo = async (searchType, kyList) => {
            const applicantdata = await clientAPI.GetApplicantBasicInfo(searchType, kyList);
            setApplicantData(applicantdata[0]);
            console.log(
                '8) [ClientFundingYearDetails][useEffect][getBasicApplicantInfo] applicantdata:',
                applicantdata
            );
        };

        const getClientContactData = async (kyList) => {
            const clientContactDataVar = await clientAPI.GetClientContactDataOriginal(kyList);
            setClientContactData(clientContactDataVar);
            console.log(
                '9) [ClientFundingYearDetails][useEffect][getClientContactData] clientContactDataVar:',
                clientContactDataVar
            );
            return clientContactDataVar;
        };

        const getStates = async () => {
            const allStatesVar = await clientAPI.GetStates();
            setAllStates(allStatesVar);
            console.log('[ClientFundingYearDetails][useEffect][getStates] allStatesVar:', allStatesVar);
            return allStatesVar;
        };

        // Get the first available contact info
        const getClientContactDataObj = (allClientContactData) => {
            // Get all the keys of the object
            const keys = Object.keys(allClientContactData);

            if (keys.length === 0) {
                return null;
            }

            // Loop through the keys in their current order (most recent first)
            for (let i = 0; i < keys.length; i++) {
                const dataArray = allClientContactData[keys[i]];

                // If the array is not empty, return the first object
                if (dataArray && dataArray.length > 0) {
                    console.log(
                        '[ClientFundingYearDetails][useEffect][getClientContactDataObj] specificClientContactDataObj:',
                        dataArray[0]
                    );
                    return dataArray[0]; // Return the first non-empty object
                }
            }

            return null;
        };

        const getFundingYearDetailsData = async (searchType, ky, state, fundingYear) => {
            let fundingDetailsDataVar = [];
            try {
                // If we used cache, fundingDetailsData is never set for
                fundingDetailsDataVar = await clientAPI.GetClientFundingYearDetailsData(
                    searchType,
                    ky,
                    state,
                    fundingYear
                );
                if (fundingDetailsDataVar && fundingDetailsDataVar.length > 0) {
                    setFundingDetailsData(fundingDetailsDataVar);
                    // Search and set the specific record data
                    findSpecificCFYDRecordData(fundingDetailsDataVar, ky);
                }

                console.log(
                    '10) [ClientFundingYearDetails][useEffect][getFundingYearDetailsData] fundingDetailsDataVar:',
                    fundingDetailsDataVar
                );
            } catch (error) {
                console.error(error);
                toast.error(error);
            }

            return fundingDetailsDataVar;
        };

        const fetchAndCacheData = async () => {
            let dataResponse = [];
            let correctState = '';

            // Get client data. NOTE: this is unreliable to use for state, because the client data's state_abbrev may not match the billedentitystate
            const applicantdata = await getApplicantBasicInfo(searchType, kyList);
            await cacheData(applicantDataKey, applicantdata);

            // Get contact data only for specific searchTypes, and set the refreshed data to cache
            if (searchType === 'entNum' || searchType === 'f471') {
                const clientContactDataTemp = await getClientContactData(kyList);

                if (clientContactDataTemp) {
                    setSpecificClientContactObj(getClientContactDataObj(clientContactDataTemp));
                    correctState = findLatestBilledEntityState(clientContactDataTemp);
                    await cacheData(contactDataKey, clientContactDataTemp);
                }
            }

            // Correctly format the desired funding year(s)
            const yearValue = createYearsOptions()
                .filter((ea_year) => ea_year !== 'All Years Detail' && ea_year !== 'All Years Summary')
                .map((ea_year) => ea_year.replace('FY', '').replace('Detail', '').trim());

            if (searchType === 'entNum') {
                const stateValue = state || correctState;
                dataResponse = await getFundingYearDetailsData(searchType, kyList, stateValue, yearValue);
            } else if (searchType === 'f471') {
                dataResponse = await getFundingYearDetailsData(searchType, f471appnum, state, yearValue);
            } else if (searchType === 'spin') {
                const statesArray = await getStates();
                if (statesArray) {
                    const states = statesArray.map((ea_state_obj) => ea_state_obj.abbreviation);
                    dataResponse = await getFundingYearDetailsData(searchType, kyList, states, yearValue);
                }
            }

            // Store the data in cache if the response is valid
            if (dataResponse) {
                await cacheData(fundingYearsDetailsDataKey, dataResponse);
            }

            setUsedCacheOnLoad(false);
            return dataResponse;
        };

        const fetchAllFundingData = async () => {
            try {
                setIsLoading(true);

                // Check if the cache for the BEN, and searchType combo already exists or not
                await checkCacheForPreviousData(
                    applicantDataKey,
                    contactDataKey,
                    fundingYearsDetailsDataKey,
                    searchType === 'entNum' || searchType === 'spin' ? kyList : f471appnum
                );

                // Get funding year details based on searchType. Triggers a refresh despite using cached data (to stay up-to-date)
                await fetchAndCacheData();
            } catch (error) {
                console.error(
                    '9) [ClientFundingYearDetails][useEffect][fetchAllFundingData] Error fetching funding data:',
                    error
                );
            } finally {
                setIsLoading(false);
            }
        };

        // GOAL: When NOT selecting "all funding years", check cache and filter, otherwise query for the year (instead of querying for all years like we normally do for entNum)
        const fetchFYDDOnly = async () => {
            try {
                setIsLoading(true);

                let APICache = await caches.open(CLIENT_CACHE_NAME);
                const fyddResponse = await APICache.match(fundingYearsDetailsDataKey);
                if (fyddResponse !== undefined) {
                    const cachedFYDData = await fyddResponse.json();
                    setFundingDetailsData(cachedFYDData);
                    findSpecificCFYDRecordData(cachedFYDData, kyList);
                    filterDataByYear(cachedFYDData, fundingYear); // Performs setFilteredFundingDetailsData
                }

                const statesArray = await getStates();
                if (statesArray) {
                    setAllStates(statesArray);
                }

                const specificStatesTemp = statesArray.map((ea_state_obj) => ea_state_obj.abbreviation);
                setSpecificStates(specificStatesTemp);

                const fundingDetailsDataVar = await clientAPI.GetClientFundingYearDetailsData(
                    searchType,
                    kyList,
                    specificStatesTemp,
                    fundingYear.replace('FY', '').replace('Detail', '').trim()
                );
                if (fundingDetailsDataVar && fundingDetailsDataVar.length > 0) {
                    setFundingDetailsData(fundingDetailsDataVar); // Setting this state means you don't have to FILTER out the data (because you only have the year's dataset)
                    findSpecificCFYDRecordData(fundingDetailsDataVar, kyList);

                    const createStatesDropdown = (fyDataArray) => {
                        const uniqueStates = new Set(fyDataArray.map((ea_obj) => ea_obj.applicantstate));
                        return Array.from(uniqueStates);
                    };
                    setSpecificStates(createStatesDropdown(fundingDetailsDataVar).sort());
                }
            } catch (error) {
                console.error(
                    '9) [ClientFundingYearDetails][useEffect][fetchFYDDOnly] Error fetching funding data:',
                    error
                );
            } finally {
                setIsLoading(false);
            }
        };

        if (searchType !== 'spin' || (searchType === 'spin' && fundingYear === 'All')) {
            console.log('Condition 1');
            fetchAllFundingData();
        } else if (searchType === 'spin' && fundingYear !== 'All Years Detail') {
            console.log('Condition 2');
            fetchFYDDOnly();
        }
        setMainSectionIsDone(true);
    }, [
        clientAPI,
        applicantDataKey,
        contactDataKey,
        f471appnum,
        fundingYear,
        fundingYearsDetailsDataKey,
        kyList,
        searchType,
        state,
    ]);

    React.useEffect(() => {
        setSelectedYear(fundingYear === 'All' ? 'All Years Detail' : `FY ${fundingYear} Detail`);
    }, [fundingYear]);

    React.useEffect(() => {
        if (fundingDetailsData && fundingDetailsData.length > 0) {
            filterDataByYear(fundingDetailsData, selectedYear);
        }
    }, [fundingDetailsData, selectedYear]);

    // Don't display anything until we have data.
    if (
        isLoading ||
        !applicantData ||
        !allStates ||
        !fundingDetailsData ||
        !cfydRecordData ||
        ((searchType === 'entNum' || searchType === 'f471') && (!clientContactData || !specificClientContactObj))
    ) {
        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                            <img
                                height='100px'
                                src={logo}
                                alt='Logo'
                                style={{ cursor: 'pointer', marginTop: '-18.5px' }}
                            />
                        </Link>
                        <Link
                            sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%' }}
                            href='https://e-ratecentral.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {'https://e-ratecentral.com'}
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                    <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                        <em>{`Gathering data for E-Rate Funding Search Chart for ${
                            fundingYear === 'All' ? 'All Funding Years' : `Funding Year ${fundingYear}`
                        }...`}</em>
                    </Typography>
                </Box>
            </Box>
        );
    }

    function findSpecificCFYDRecordData(cfyddata, ky) {
        for (let i = 0; i < cfyddata.length; i++) {
            if (
                (searchType === 'entNum' &&
                    ky
                        .split(',')
                        .map((ben) => ben.trim())
                        .includes(cfyddata[i]['ben'].toString())) ||
                (searchType === 'f471' && cfyddata[i]['f471applicationnumber'] == ky) ||
                (searchType === 'spin' && cfyddata[i]['spin'] == ky)
            ) {
                setCfydRecordData(cfyddata[i]);
                break;
            }
        }
    }

    function createYearsOptions() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

        const startYear = 2016;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            if (year < currentYear || (year === currentYear && currentMonth >= 6)) {
                years.push(`FY ${year} Detail`);
            }
        }

        return years.sort((a, b) => {
            const yearA = typeof a === 'string' ? parseInt(a.match(/\d+/)) : a;
            const yearB = typeof b === 'string' ? parseInt(b.match(/\d+/)) : b;
            return yearB - yearA;
        });
    }
    const years = createYearsOptions();
    if (searchType !== 'spin') {
        years.unshift('All Years Detail');
    }
    years.unshift('All Years Summary');

    // const createStatesDropdown = (fyDataArray) => {
    //     const uniqueStates = new Set(fyDataArray.map((ea_obj) => ea_obj.applicantstate));
    //     return Array.from(uniqueStates);
    // };
    // const specificStates = createStatesDropdown(fundingDetailsData).sort();

    const handleStateSelectionChange = (event) => {
        setSelectedState(event.target.value);
    };

    const handleFundingYearSelectionChange = async (event, value) => {
        let newYear = event.target.value;
        setSelectedYear(newYear);
        setMainSectionIsDone(false);

        if (newYear === 'All Years Summary') {
            navigate(`/client-list/funding-summary/${kyList}/${searchType}`);
        } else {
            if (searchType !== 'spin') {
                newYear = newYear.replace('FY', '').replace('Detail', '').trim();
                filterDataByYear(fundingDetailsData, newYear);
            } else {
                let fundingDetailsDataVarSpecificYear = [];
                const statesArray = await clientAPI.GetStates();
                if (!statesArray) return;
                const statesArrayAbbrev = statesArray.map((ea_state_obj) => ea_state_obj.abbreviation);
                setAllStates(statesArrayAbbrev);

                if (newYear !== 'All Years Detail') {
                    fundingDetailsDataVarSpecificYear = await clientAPI.GetClientFundingYearDetailsData(
                        searchType,
                        kyList,
                        statesArrayAbbrev,
                        newYear.replace('FY', '').replace('Detail', '').trim()
                    );
                } else {
                    // Potentially check the cache for the ALL data, but for now just querying for it first
                    const allYears = years
                        .filter((ea_year) => ea_year !== 'All Years Detail' && ea_year !== 'All Years Summary')
                        .map((ea_year) => ea_year.replace('FY', '').replace('Detail', '').trim());
                    fundingDetailsDataVarSpecificYear = await clientAPI.GetClientFundingYearDetailsData(
                        searchType,
                        kyList,
                        statesArrayAbbrev,
                        allYears
                    );
                }

                if (fundingDetailsDataVarSpecificYear && fundingDetailsDataVarSpecificYear.length > 0) {
                    setFundingDetailsData(fundingDetailsDataVarSpecificYear); // Setting this state means you don't have to FILTER out the data (because you only have the year's dataset)
                    findSpecificCFYDRecordData(fundingDetailsDataVarSpecificYear, kyList);
                    const createStatesDropdown = (fyDataArray) => {
                        const uniqueStates = new Set(fyDataArray.map((ea_obj) => ea_obj.applicantstate));
                        return Array.from(uniqueStates);
                    };
                    setSpecificStates(createStatesDropdown(fundingDetailsDataVarSpecificYear).sort());
                }
            }
        }
        setMainSectionIsDone(true);
    };

    function filterDataByYear(allData, year) {
        if (year === 'All Years Detail') {
            setFilteredFundingDetailsData(allData); // Display all data if "All" is selected
        } else {
            const filteredData = allData.filter(
                (item) => item.fundingyear == year.replace('FY', '').replace('Detail', '').trim()
            ); // Filter based on selected year
            setFilteredFundingDetailsData(filteredData);
        }
    }

    return (
        <Box>
            {searchType === 'entNum' ? (
                <ClientLogoAndHeaders
                    searchType={searchType}
                    applicantData={applicantData}
                    kyList={kyList}
                    specificClientContactObj={specificClientContactObj}
                />
            ) : searchType === 'f471' ? (
                <ClientLogoAndHeaders
                    searchType={searchType}
                    applicantData={applicantData}
                    kyList={kyList}
                    specificClientContactObj={specificClientContactObj}
                />
            ) : (
                <Box>
                    {/* searchType === spin */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                                <img
                                    height='100px'
                                    src={logo}
                                    alt='Logo'
                                    style={{ cursor: 'pointer', marginTop: '-18px' }}
                                />
                            </Link>
                            <Link
                                sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%', cursor: 'pointer' }}
                                href='https://e-ratecentral.com'
                                target='_blank'
                                rel='noreferrer'
                            >
                                {'https://e-ratecentral.com'}
                            </Link>
                        </Box>

                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', marginRight: '10%' }}>
                            {!f471appnum && (
                                <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                                    <h3 style={{ margin: 0, marginRight: 4 }}>{`Report:   `}</h3>
                                    <FormControl sx={{ minWidth: 100 }}>
                                        <Select
                                            id='funding-year-selection'
                                            label='Funding Year Selection'
                                            value={selectedYear || ''}
                                            defaultValue={fundingYear || ''}
                                            onChange={handleFundingYearSelectionChange}
                                            sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                                        >
                                            {years.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <h3 style={{ margin: 0, marginLeft: 20, marginRight: 4 }}>{`State:   `}</h3>
                                    <FormControl sx={{ minWidth: 100 }}>
                                        <Select
                                            id='state-selection'
                                            label='State Selection'
                                            value={selectedState || ''}
                                            onChange={handleStateSelectionChange}
                                            sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                                        >
                                            {specificStates.map((state) => (
                                                <MenuItem
                                                    key={state}
                                                    value={state}
                                                    onClick={() => {
                                                        const section = document.getElementById(state);
                                                        section.scrollIntoView({ behavior: 'smooth' });
                                                    }}
                                                >
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}

            <Box sx={{ flexBasis: '700px' }}>
                {mainSectionIsDone && fundingDetailsData && fundingDetailsData.length > 0 ? (
                    <>
                        <ClientFundingYearDetailsSection
                            kyList={kyList}
                            f471appnum={f471appnum}
                            selectedYear={selectedYear}
                            searchType={searchType}
                            fundingDetailsData={filteredFundingDetailsData}
                            allStates={allStates}
                            usedCacheOnLoad={usedCacheOnLoad}
                            handleFundingYearSelectionChange={handleFundingYearSelectionChange}
                            years={years}
                        />

                        <Typography variant='body2' sx={{ marginTop: 5, fontSize: '0.875rem' }}>
                            <strong>Service Legend:</strong> IA=Internet Access; IC=Internal Connections; ICM=Internal
                            Connections Maintenance; MIB=Managed Internal Broadband Service; T=Telecomm; VS=Voice
                            Services
                        </Typography>

                        <Typography variant='body2' sx={{ marginTop: 1 }}>
                            NF = Not Funded
                        </Typography>
                    </>
                ) : !mainSectionIsDone ? (
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                                    <img
                                        height='100px'
                                        src={logo}
                                        alt='Logo'
                                        style={{ cursor: 'pointer', marginTop: '-18.5px' }}
                                    />
                                </Link>
                                <Link
                                    sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%' }}
                                    href='https://e-ratecentral.com'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {'https://e-ratecentral.com'}
                                </Link>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}
                        >
                            <CircularProgress />
                            <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                                <em>{`Gathering data for Market Funding Search Analysis for ${
                                    fundingYear === 'All' ? 'All Funding Years' : `${selectedYear}`
                                }...`}</em>
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    mainSectionIsDone &&
                    fundingDetailsData &&
                    fundingDetailsData.length === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh',
                            }}
                        >
                            <SentimentVeryDissatisfiedIcon sx={{ fontSize: '100px', color: 'gray' }} />
                            <Typography variant='h5' sx={{ marginTop: 2, color: 'red', fontSize: '20px' }}>
                                {`No data found for ${searchType === 'spin' ? 'SPIN' : 'Entity Number'} `}
                                <span style={{ textDecoration: 'underline' }}>{kyList}</span>
                                {` in `}
                                <span style={{ textDecoration: 'underline' }}>{selectedYear}</span>
                            </Typography>
                        </Box>
                    )
                )}
            </Box>
        </Box>
    );
}

function ClientFundingYearDetailsSection({
    kyList,
    f471appnum,
    selectedYear,
    searchType,
    fundingDetailsData,
    allStates,
    usedCacheOnLoad,
    handleFundingYearSelectionChange,
    years,
}) {
    const [fydOrder, setFydOrder] = React.useState('asc');
    const [fydOrderBy, setFydOrderBy] = React.useState('f471applicationnumber');
    const [newClientFundingYearDetailsData, setNewClientFundingYearDetailsData] = React.useState([]);
    const trimmedFundingYear =
        selectedYear === 'All Years Detail' ? 'All' : selectedYear.replace('FY', '').replace('Detail', '').trim();

    React.useEffect(() => {
        console.log('[ClientFundingYearDetailsSection][useEffect] fundingDetailsData: ', fundingDetailsData);

        const processedFYDResults = processAllFYD(fundingDetailsData);
        console.log('[ClientFundingYearDetailsSection][useEffect] processedFYDResults: ', processedFYDResults);

        setNewClientFundingYearDetailsData(processedFYDResults);
    }, [fundingDetailsData]);

    if (isTableEmpty(fundingDetailsData)) {
        return (
            <Box>
                {!f471appnum && (
                    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                        <h3 style={{ margin: 0, marginRight: 4 }}>{`Report:`}</h3>
                        <FormControl sx={{ minWidth: 100 }}>
                            <Select
                                id='funding-year-selection'
                                label='Funding Year Selection'
                                value={selectedYear || ''}
                                defaultValue={selectedYear || ''}
                                onChange={handleFundingYearSelectionChange}
                                sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh',
                    }}
                >
                    <SentimentVeryDissatisfiedIcon sx={{ fontSize: '100px', color: 'gray' }} />
                    <Typography variant='h5' sx={{ marginTop: 2, color: 'red', fontSize: '20px' }}>
                        {`No data found for ${searchType === 'spin' ? 'SPIN' : 'Entity Number'} `}
                        <span style={{ textDecoration: 'underline' }}>{kyList}</span>
                        {` in `}
                        <span style={{ textDecoration: 'underline' }}>{selectedYear}</span>
                    </Typography>
                </Box>
            </Box>
        );
    }

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => {
        // Checking if the value is 0 or "0"
        if (value === 0 || value === '0' || !value) {
            return '$0.00';
        }

        // Ensuring the value is a number
        const numericValue = parseFloat(value);

        // Formatting the number to money
        return `$${numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    function isTableEmpty(fundingDetailsData) {
        if (!Array.isArray(fundingDetailsData) || fundingDetailsData.length === 0) {
            return true;
        }

        return fundingDetailsData.every((row) => {
            const relevantFields = [row.ben, row.f471applicationnumber, row.frn];

            // Return true if all relevant fields are empty
            return relevantFields.every((value) => !value || value === '' || value === null);
        });
    }

    const f471FundingDetailsHeaders = [
        { id: 'f471applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'frnnickname', label: 'FRN Nickname' },
        { id: 'serviceprovidername', label: 'Service Provider' },
        { id: 'spin', label: 'SPIN' },
        { id: 'serviceType', label: 'Service' },
        { id: 'origrannualcost', label: 'Pre-Discount Original' },
        { id: 'cmtdrannualcost', label: 'Pre-Discount Committed' },
        { id: 'discount', label: 'Discount' },
        { id: 'origfundingrequest', label: 'Discounted Original' },
        { id: 'frncommittedamount', label: 'Discounted Committed' },
        { id: 'totalauthorizeddisbursement', label: 'Disbursed' },
        { id: 'percentUtilized', label: 'Util.%' },
        { id: 'f471reviewstatus', label: '471 Status' },
        { id: 'f486filed', label: '486 Filed' },
        { id: 'invoicingmode', label: 'Invoice Mode' },
        { id: 'servicedeliverydeadline', label: 'Service Delivery Deadline' },
        { id: 'lastdatetoinvoice', label: 'Invoice Deadline' },
    ];

    const spinFundingDetailsHeaders = [
        { id: 'f471applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'ben', label: 'BEN' },
        { id: 'billedentityname', label: 'Applicant' },
        { id: 'serviceType', label: 'Service' },
        { id: 'origrannualcost', label: 'Estimated Annual Cost' },
        { id: 'origfundingrequest', label: 'Original Request' },
        { id: 'frncommittedamount', label: 'Current Commitment' },
        { id: 'totalauthorizeddisbursement', label: 'Disbursed' },
        { id: 'percentUtilized', label: 'Util.%' },
        { id: 'discount', label: 'Discount' },
        { id: 'f486servicestartdate', label: '486 Filed (Y/N)' },
        { id: 'spacfiled', label: 'SPAC Certified (Y/N)' },
        { id: 'invoicingmode', label: 'Invoice Mode' },
        { id: 'wavenumber', label: 'Wave' },
    ];

    const serviceMapper = (serviceType) => {
        if (!serviceType || serviceType.trim() === '') {
            return '';
        }

        const upperCaseServiceType = serviceType.toUpperCase();

        if (upperCaseServiceType === 'TELCOMM SERVICES') {
            return 'T';
        } else if (upperCaseServiceType === 'INTERNAL CONNECTIONS') {
            return 'IC';
        } else if (
            upperCaseServiceType === 'INTERNAL CONNECTIONS MNT' ||
            upperCaseServiceType === 'BASIC MAINTENANCE OF INTERNAL CONNECTIONS'
        ) {
            return 'ICM';
        } else if (
            upperCaseServiceType === 'INTERNET ACCESS' ||
            upperCaseServiceType === 'DATA TRANSMISSION AND/OR INTERNET ACCESS'
        ) {
            return 'IA';
        } else if (upperCaseServiceType === 'VOICE SERVICES' || upperCaseServiceType === 'VOICE') {
            return 'VS';
        } else if (
            upperCaseServiceType === 'INTERNAL CONNECTIONS MIBS' ||
            upperCaseServiceType === 'MANAGED INTERNAL BROADBAND SERVICES'
        ) {
            return 'MIB';
        } else {
            return '-';
        }
    };

    const handleFYDRequestSort = (property) => {
        const isAsc = fydOrderBy === property && fydOrder === 'asc';
        setFydOrder(isAsc ? 'desc' : 'asc');
        setFydOrderBy(property);
    };

    const sortFYData = (data, orderBy, order) => {
        return [...data].sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Convert monetary values to numbers for sorting
            if (
                orderBy.startsWith('origrannualcost') ||
                orderBy.startsWith('cmtdrannualcost') ||
                orderBy.startsWith('origfundingrequest') ||
                orderBy.startsWith('frncommittedamount') ||
                orderBy.startsWith('totalauthorizeddisbursement')
            ) {
                aValue = parseFloat(aValue.replace(/[$,]/g, ''));
                bValue = parseFloat(bValue.replace(/[$,]/g, ''));
            }

            // Handle sorting based on the value type
            if (typeof aValue === 'number') {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string') {
                return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            return 0;
        });
    };

    // Performs the required mathematical operations on each year's worth of FRNs, returns an array of objects
    const processEachFYDObj = (ea_year_obj_array) => {
        return ea_year_obj_array.map((ea_obj) => {
            const origrannualcostparsed = parseMoney(ea_obj['origrannualcost']) || 0; // Confirmed $
            const cmtdrannualcostparsed = parseMoney(ea_obj['cmtdrannualcost']) || 0; // Confirmed $
            const origfundingrequestparsed = parseMoney(ea_obj['origfundingrequest']) || 0; // Confirmed $
            const frncommittedamountparsed = parseMoney(ea_obj['frncommittedamount']) || 0; // Confirmed $
            const frnstatusparsed = ea_obj['frnstatus'].toLowerCase();
            const totalauthorizeddisbursementparsed = parseMoney(ea_obj['totalauthorizeddisbursement']) || 0; // Confirmed $
            const cmtddiscountparsed = ea_obj['cmtddiscount'] || 0; // Is NOT $
            const origdiscountparsed = ea_obj['origdiscount'] || 0; // Is NOT $
            const f486servicestartdateparsed = ea_obj['f486servicestartdate'];
            const origfrnservicetypeparsed = ea_obj['origfrnservicetype'];
            const cmtdfrnservicetypeparsed = ea_obj['cmtdfrnservicetype'];

            // Reconstructing each object with just the data we need per row in the table
            let baseObj = {
                frn: ea_obj['frn'] || '',
                frnnickname: ea_obj['frnnickname'] || '',
                frnstatus: ea_obj['frnstatus'] || '',
                f471applicationnumber: ea_obj['f471applicationnumber'] || '',
                applicantstate: ea_obj['applicantstate'] || '',
                ben: ea_obj['ben'] || '',
                billedentityname: ea_obj['billedentityname'] || '',
                serviceprovidername: ea_obj['serviceprovidername'] || '',
                spin: ea_obj['spin'] || '',
                fundingYear: ea_obj['fundingyear'] || '',
                origrannualcost: 0,
                cmtdrannualcost: 0,
                discount: 0,
                serviceType: '',
                origfundingrequest: 0,
                wavenumber: ea_obj['wavenumber'] || '',
                fcdldate: ea_obj['fcdldate'] || '',
                pcwavenumber: ea_obj['pcwavenumber'] || '',
                frncommittedamount: 0,
                invoicingmode: ea_obj['invoicingmode'] || '',
                totalauthorizeddisbursement: 0,
                percentUtilized: '',
                f486OnFile: '',
                f471reviewstatus: ea_obj['f471reviewstatus'] || '',
                lastdatetoinvoice: ea_obj['lastdatetoinvoice'] || '',
                servicedeliverydeadline: ea_obj['servicedeliverydeadline'] || '',
                f486servicestartdate: ea_obj['f486servicestartdate'] || '',
                // ...ea_obj
            };

            if (searchType === 'spin') {
                baseObj['spacfiled'] = ea_obj['spacfiled'] ? 'Y' : 'N';
            }

            // *** Special handling based on fundingHistoryDetail ***

            if (cmtdfrnservicetypeparsed == null || cmtdfrnservicetypeparsed === '') {
                baseObj.serviceType = serviceMapper(origfrnservicetypeparsed);
            } else {
                baseObj.serviceType = serviceMapper(cmtdfrnservicetypeparsed);
            }

            // Total Estimated Annual (strORIG_ESTMTD_ANNUAL_COST) == origrannualcost. Pre-Discount Original
            if (origrannualcostparsed > 0) {
                baseObj.origrannualcost = ea_obj['origrannualcost'];
            } else if (origrannualcostparsed === 0) {
                baseObj.origrannualcost = formatMoney('0');
            } else {
                baseObj.origrannualcost = formatMoney('0');
            }

            // Total Committed Annual (strCOMM_ESTMTD_ANNUAL_COST) == cmtdrannualcost. Pre-Discount Committed
            if (cmtdrannualcostparsed > 0) {
                baseObj.cmtdrannualcost = ea_obj['cmtdrannualcost'];
            } else if (cmtdrannualcostparsed == null || cmtdrannualcostparsed === ' ' || cmtdrannualcostparsed === '') {
                baseObj.cmtdrannualcost = formatMoney('0');
            } else {
                baseObj.cmtdrannualcost = formatMoney('0');
            }

            // Original Requested Amount == origfundingrequest. Discounted Original
            if (origfundingrequestparsed > 0) {
                baseObj.origfundingrequest = ea_obj['origfundingrequest'];
            } else if (origfundingrequestparsed === 0) {
                baseObj.origfundingrequest = formatMoney('0');
            } else {
                baseObj.origfundingrequest = formatMoney('0');
            }

            // Funded Amount == frncommittedamount. Discounted Committed
            if (frncommittedamountparsed > 0) {
                baseObj.frncommittedamount = ea_obj['frncommittedamount'];
            } else if (frncommittedamountparsed === '' || frncommittedamountparsed == null) {
                baseObj.frncommittedamount = formatMoney('0');
            } else if (frncommittedamountparsed === 0) {
                baseObj.frncommittedamount = formatMoney('0');
            } else {
                baseObj.frncommittedamount = formatMoney('0');
            }

            // Display strStatusFCDL (a20) if Funded Amount = 0 == fst_FST.frnstatus (could default to the original frnstatus if funded)
            if (frncommittedamountparsed === 0 || frncommittedamountparsed !== '' || frncommittedamountparsed == null) {
                if (frnstatusparsed === 'funded') {
                    baseObj.frnstatus = '';
                } else if (frnstatusparsed === 'not funded' || frnstatusparsed === 'denied') {
                    baseObj.frnstatus = 'NF';
                } else if (frnstatusparsed == null) {
                    baseObj.frnstatus = '';
                } else {
                    baseObj.frnstatus = '';
                }
            } else {
                baseObj.frnstatus = '';
            }

            // Disbursed == fst_FST.totalauthorizeddisbursement
            if (totalauthorizeddisbursementparsed > 0) {
                baseObj.totalauthorizeddisbursement = ea_obj['totalauthorizeddisbursement'];
            } else if (
                totalauthorizeddisbursementparsed === '' ||
                totalauthorizeddisbursementparsed === 0 ||
                totalauthorizeddisbursementparsed == null
            ) {
                baseObj.totalauthorizeddisbursement = formatMoney('0');
            } else {
                baseObj.totalauthorizeddisbursement = formatMoney('0');
            }

            if (totalauthorizeddisbursementparsed > 0 && frncommittedamountparsed > 0) {
                baseObj.percentUtilized = (totalauthorizeddisbursementparsed / frncommittedamountparsed) * 100;
            } else if (
                totalauthorizeddisbursementparsed == null ||
                totalauthorizeddisbursementparsed === '' ||
                totalauthorizeddisbursementparsed === ' '
            ) {
                baseObj.percentUtilized = 0;
            } else {
                baseObj.percentUtilized = 0;
            }

            if (!cmtddiscountparsed || cmtddiscountparsed == null || cmtddiscountparsed === '') {
                baseObj.discount = parseInt(origdiscountparsed) || 0;
            } else {
                baseObj.discount = parseInt(cmtddiscountparsed) || 0;
            }

            if (
                f486servicestartdateparsed == null ||
                f486servicestartdateparsed === '' ||
                f486servicestartdateparsed === ' '
            ) {
                baseObj.f486OnFile = 'N';
            } else {
                baseObj.f486OnFile = 'Y';
            }
            return baseObj;
        });
    };

    function processAllFYD(fundingDetailsData) {
        if (!fundingDetailsData || fundingDetailsData.length === 0) {
            return [];
        }
        return processEachFYDObj(fundingDetailsData);
    }

    const calculateFRNTotals = (processedData) => {
        if (!processedData || processedData.length === 0) {
            return {
                origrannualcost: 0,
                cmtdrannualcost: 0,
                origfundingrequest: 0,
                frncommittedamount: 0,
                totalauthorizeddisbursement: 0,
                percentUtilized: 0,
            };
        }

        return processedData.reduce(
            (acc, yearData) => {
                acc.origrannualcost += parseMoney(yearData.origrannualcost);
                acc.cmtdrannualcost += parseMoney(yearData.cmtdrannualcost);
                acc.origfundingrequest += parseMoney(yearData.origfundingrequest);
                acc.frncommittedamount += parseMoney(yearData.frncommittedamount);
                acc.totalauthorizeddisbursement += parseMoney(yearData.totalauthorizeddisbursement);
                // acc.percentUtilized += parseFloat(yearData.percentUtilized.replace('%', ''));
                acc.percentUtilized += parseFloat(yearData.percentUtilized);

                return acc;
            },
            {
                origrannualcost: 0, // Pre-Discount Original
                cmtdrannualcost: 0, // Pre-Discount Committed
                origfundingrequest: 0, // Discounted Original
                frncommittedamount: 0, //Discounted Committed
                totalauthorizeddisbursement: 0, // Disbursed
                percentUtilized: 0, // Util. %
            }
        );
    };

    const groupDataByState = (data) => {
        return data.reduce((acc, ea_item) => {
            const state = ea_item.applicantstate;
            if (!acc[state]) {
                acc[state] = [];
            }
            acc[state].push(ea_item);
            return acc;
        }, {});
    };

    const groupDataByFundingYear = (data) => {
        return data.reduce((acc, item) => {
            const year = item.fundingYear;
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(item);
            return acc;
        }, {});
    };

    const getStateName = (abbreviation, allStates) => {
        const stateObj = allStates.find((ea_state_obj) => ea_state_obj.abbreviation === abbreviation);
        return stateObj ? `${stateObj.name} [${stateObj.abbreviation}]` : abbreviation;
    };

    const spinColumnStyles = {
        f471applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        ben: { minWidth: '80px', maxWidth: '150px' },
        billedentityname: { minWidth: '150px', maxWidth: '300px' },
        serviceType: { minWidth: '80px', maxWidth: '150px' },
        origrannualcost: { minWidth: '100px', maxWidth: '200px' },
        origfundingrequest: { minWidth: '100px', maxWidth: '200px' },
        frncommittedamount: { minWidth: '100px', maxWidth: '200px' },
        totalauthorizeddisbursement: { minWidth: '100px', maxWidth: '200px' },
        percentUtilized: { minWidth: '100px', maxWidth: '200px' },
        discount: { minWidth: '100px', maxWidth: '200px' },
        spacfiled: { minWidth: '80px', maxWidth: '150px' },
        f486OnFile: { minWidth: '80px', maxWidth: '150px' },
        invoicingmode: { minWidth: '80px', maxWidth: '150px' },
        wavenumber: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
    };

    const f471ColumnStyles = {
        f471applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        frnnickname: { minWidth: '80px', maxWidth: '150px' },
        spin: { minWidth: '150px', maxWidth: '300px' },
        serviceprovidername: { minWidth: '150px', maxWidth: '300px' },
        serviceType: { minWidth: '80px', maxWidth: '150px' },
        origrannualcost: { minWidth: '100px', maxWidth: '200px' },
        cmtdrannualcost: { minWidth: '100px', maxWidth: '200px' },
        origfundingrequest: { minWidth: '100px', maxWidth: '200px' },
        frncommittedamount: { minWidth: '100px', maxWidth: '200px' },
        totalauthorizeddisbursement: { minWidth: '100px', maxWidth: '200px' },
        percentUtilized: { minWidth: '100px', maxWidth: '200px' },
        f471reviewstatus: { minWidth: '80px', maxWidth: '150px' },
        discount: { minWidth: '100px', maxWidth: '200px' },
        f486OnFile: { minWidth: '80px', maxWidth: '150px' },
        invoicingmode: { minWidth: '80px', maxWidth: '150px' },
        servicedeliverydeadline: { minWidth: '80px', maxWidth: '150px' },
        lastdatetoinvoice: { minWidth: '80px', maxWidth: '150px' },
    };

    const includeAllYears = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

        const startYear = 2016;
        const years = [];

        for (let year = currentYear; year >= startYear; year--) {
            if (year < currentYear || (year === currentYear && currentMonth >= 6)) {
                years.push(year);
            }
        }
        return years;
    };

    const frnTotals =
        newClientFundingYearDetailsData && newClientFundingYearDetailsData.length > 0
            ? calculateFRNTotals(newClientFundingYearDetailsData)
            : {
                  origrannualcost: 0,
                  cmtdrannualcost: 0,
                  origfundingrequest: 0,
                  frncommittedamount: 0,
                  totalauthorizeddisbursement: 0,
                  percentUtilized: 0,
              };

    const yearData = newClientFundingYearDetailsData;
    if (!yearData || yearData.length === 0) {
        return [];
    }

    const sortedYearData = sortFYData(yearData, fydOrderBy, fydOrder);
    const groupedStateData = groupDataByState(sortedYearData);
    const sortedGroupedStateDataKeys = Object.keys(groupedStateData).sort();
    const groupedYearData = groupDataByFundingYear(sortedYearData);

    return searchType === 'entNum' ? (
        <Box>
            {!f471appnum && (
                <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                    <h3 style={{ margin: 0, marginRight: 4 }}>{`Report:`}</h3>
                    <FormControl sx={{ minWidth: 100 }}>
                        <Select
                            id='funding-year-selection'
                            label='Funding Year Selection'
                            value={selectedYear || ''}
                            defaultValue={selectedYear || ''}
                            onChange={handleFundingYearSelectionChange}
                            sx={{ height: '2.2rem', fontSize: '0.875rem' }}
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                <h4 style={{ color: '#19638B', margin: 0 }}>
                    {`E-Rate Funding Search for ${
                        selectedYear === 'All Years Detail' ? 'All Funding Years' : `Funding Year ${trimmedFundingYear}`
                    }`}
                </h4>
                {usedCacheOnLoad && (
                    <>
                        <CircularProgress sx={{ marginLeft: '15px' }} size='0.75rem' />
                        <Typography variant='body2' sx={{ color: 'gray', marginLeft: '10px' }}>
                            <em>Most recent data loading...</em>
                        </Typography>
                    </>
                )}
            </Box>

            {selectedYear === 'All Years Detail' ? (
                <>
                    {includeAllYears().map((year, index) => {
                        const yearData = groupDataByFundingYear(sortedYearData);
                        const yearFRNTotals = calculateFRNTotals(yearData[year]);

                        // Checking if data for this year iteration exists
                        const hasDataForYear = Object.keys(groupedYearData).includes(year.toString());

                        return (
                            <Box id={`year-${year}`} key={`year-${index}`} sx={{ marginBottom: '40px' }}>
                                <Box>
                                    <h5 style={{ margin: 0 }}>
                                        Funding Year: <span style={{ fontWeight: 'normal' }}>{year}</span>
                                    </h5>
                                </Box>

                                {hasDataForYear && yearData[year] && yearData[year].length > 0 ? (
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{
                                                    minWidth: 800,
                                                    width: '100%',
                                                    tableLayout: 'fixed',
                                                }}
                                                size='small'
                                            >
                                                <TableHead>
                                                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                                        {f471FundingDetailsHeaders.map((header) => (
                                                            <TableCell
                                                                key={header.id}
                                                                sortDirection={
                                                                    fydOrderBy === header.id ? fydOrder : false
                                                                }
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    lineHeight: '1.2',
                                                                    flexBasis: 'auto',
                                                                    flexGrow: 1,
                                                                    flexShrink: 1,
                                                                    fontSize: '10px',
                                                                    textAlign: [
                                                                        'origrannualcost',
                                                                        'cmtdrannualcost',
                                                                        'discount',
                                                                        'origfundingrequest',
                                                                        'frncommittedamount',
                                                                        'totalauthorizeddisbursement',
                                                                        'percentUtilized',
                                                                        'f471reviewstatus',
                                                                        'f486filed',
                                                                    ].includes(header.id)
                                                                        ? 'right'
                                                                        : 'start',
                                                                    paddingLeft:
                                                                        header.id === 'percentUtilized'
                                                                            ? '50px'
                                                                            : header.id === 'f471reviewstatus'
                                                                            ? '50px'
                                                                            : [
                                                                                  'origrannualcost',
                                                                                  'cmtdrannualcost',
                                                                                  'discount',
                                                                                  'origfundingrequest',
                                                                                  'frncommittedamount',
                                                                                  'totalauthorizeddisbursement',
                                                                                  'percentUtilized',
                                                                                  'f486filed',
                                                                              ].includes(header.id)
                                                                            ? '25px'
                                                                            : undefined,
                                                                    ...f471ColumnStyles[header.id],
                                                                }}
                                                            >
                                                                <TableSortLabel
                                                                    active={fydOrderBy === header.id}
                                                                    direction={
                                                                        fydOrderBy === header.id ? fydOrder : 'asc'
                                                                    }
                                                                    onClick={() => handleFYDRequestSort(header.id)}
                                                                >
                                                                    {header.label}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {yearData[year].map((rowData, index) => (
                                                        <TableRow
                                                            key={`${rowData.filingwindow}-${index}`}
                                                            sx={{
                                                                backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                                                fontSize: '10px',
                                                            }}
                                                        >
                                                            <TableCell sx={{ padding: '4px 8px' }}>
                                                                <Link
                                                                    href={`/client-list/funding-year-details/${kyList}/${year}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471`}
                                                                    rel='noreferrer'
                                                                    sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                                >
                                                                    {rowData.f471applicationnumber}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}>
                                                                <Link
                                                                    href={`/client-list/form-details/${kyList}/${
                                                                        rowData.frn
                                                                    }/${year}/${
                                                                        searchType === 'entNum' || searchType === 'f471'
                                                                            ? 'frn'
                                                                            : 'entNum'
                                                                    }`}
                                                                    rel='noreferrer'
                                                                    sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                                >
                                                                    {rowData.frn}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}>
                                                                <Link
                                                                    href={`/client-list/form-details/${kyList}/${
                                                                        rowData.frn
                                                                    }/${year}/${
                                                                        searchType === 'entNum' || searchType === 'f471'
                                                                            ? 'frn'
                                                                            : 'entNum'
                                                                    }`}
                                                                    rel='noreferrer'
                                                                    sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                                >
                                                                    {rowData.frnnickname}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                                {rowData.serviceprovidername}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}>
                                                                <Link
                                                                    href={`/client-list/funding-summary/${kyList}/${rowData.spin}/spin`} // CORRECT
                                                                    rel='noreferrer'
                                                                    sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                                >
                                                                    {rowData.spin}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                                {rowData.serviceType}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {rowData.origrannualcost}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {rowData.cmtdrannualcost}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                    paddingRight: '25px',
                                                                }}
                                                            >
                                                                {`${Math.round(rowData.discount)}%`}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {rowData.origfundingrequest}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {rowData.frnstatus === 'NF'
                                                                    ? rowData.frncommittedamount + ' (NF)'
                                                                    : rowData.frncommittedamount}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {rowData.totalauthorizeddisbursement}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {`${rowData.percentUtilized.toFixed(2)}%`}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                    paddingLeft: '35px',
                                                                }}
                                                            >
                                                                {rowData.f471reviewstatus}
                                                                {rowData.fcdldate && (
                                                                    <> ({formatDate(rowData.fcdldate)})</>
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                    paddingRight: '35px',
                                                                }}
                                                            >
                                                                {rowData.f486OnFile}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                                {rowData.invoicingmode}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                                {formatDate(rowData.servicedeliverydeadline)}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                                {formatDate(rowData.lastdatetoinvoice)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {yearData[year].length > 0 && (
                                                        <TableRow
                                                            sx={{
                                                                backgroundColor:
                                                                    yearData[year].length % 2 === 0
                                                                        ? 'white'
                                                                        : '#f5f5f5',
                                                            }}
                                                        >
                                                            <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                                Totals
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {formatMoney(yearFRNTotals.origrannualcost)}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {formatMoney(yearFRNTotals.cmtdrannualcost)}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {formatMoney(yearFRNTotals.origfundingrequest)}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {formatMoney(yearFRNTotals.frncommittedamount)}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {formatMoney(yearFRNTotals.totalauthorizeddisbursement)}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    padding: '4px 8px',
                                                                    fontSize: '10px',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {isNaN(yearFRNTotals.frncommittedamount) ||
                                                                yearFRNTotals.frncommittedamount === 0 ||
                                                                yearFRNTotals.totalauthorizeddisbursement === 0
                                                                    ? '0%'
                                                                    : `${(
                                                                          (yearFRNTotals.totalauthorizeddisbursement /
                                                                              yearFRNTotals.frncommittedamount) *
                                                                          100
                                                                      ).toFixed(2)}%`}
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                            <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <Link
                                                href='#top'
                                                sx={{
                                                    textDecoration: 'none',
                                                    fontWeight: 'normal',
                                                    marginLeft: 3,
                                                    fontSize: '10px',
                                                }}
                                                onClick={() => {
                                                    const section = document.getElementById('top');
                                                    section.scrollIntoView({ behavior: 'smooth' });
                                                }}
                                            >
                                                {'Top of Page'}
                                            </Link>
                                        </Box>
                                    </>
                                ) : (
                                    <Box
                                        sx={{
                                            padding: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{ color: 'red', textAlign: 'center', fontSize: '10px' }}
                                        >
                                            {`No data found for Entity Number `}
                                            <span style={{ textDecoration: 'underline' }}>{kyList}</span>
                                            {` in Funding Year `}
                                            <span style={{ textDecoration: 'underline' }}>{year}</span>
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </>
            ) : (
                <TableContainer component={Paper}>
                    <Table
                        sx={{
                            minWidth: 800,
                            width: '100%', // Ensures the table takes up the full width of the container
                            tableLayout: 'fixed', // Helps in managing how the table handles the available width
                        }}
                        size='small'
                    >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                {f471FundingDetailsHeaders.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        sortDirection={fydOrderBy === header.id ? fydOrder : false}
                                        sx={{
                                            padding: '4px 8px',
                                            lineHeight: '1.2',
                                            flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                            flexGrow: 1, // Allows the columns to grow to fill available space
                                            flexShrink: 1, // Allows the columns to shrink if necessary
                                            fontSize: '10px',
                                            textAlign: [
                                                'origrannualcost',
                                                'cmtdrannualcost',
                                                'discount',
                                                'origfundingrequest',
                                                'frncommittedamount',
                                                'totalauthorizeddisbursement',
                                                'percentUtilized',
                                                'f471reviewstatus',
                                                'f486filed',
                                                'f471reviewstatus',
                                            ].includes(header.id)
                                                ? 'right'
                                                : 'start',
                                            paddingLeft:
                                                header.id === 'percentUtilized'
                                                    ? '50px'
                                                    : header.id === 'f471reviewstatus'
                                                    ? '50px'
                                                    : [
                                                          'origrannualcost',
                                                          'cmtdrannualcost',
                                                          'discount',
                                                          'origfundingrequest',
                                                          'frncommittedamount',
                                                          'totalauthorizeddisbursement',
                                                          'percentUtilized',
                                                          'f471reviewstatus',
                                                          'f486filed',
                                                      ].includes(header.id)
                                                    ? '25px'
                                                    : undefined,
                                            ...f471ColumnStyles[header.id],
                                        }}
                                    >
                                        <TableSortLabel
                                            active={fydOrderBy === header.id}
                                            direction={fydOrderBy === header.id ? fydOrder : 'asc'}
                                            onClick={() => handleFYDRequestSort(header.id)}
                                        >
                                            {header.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedYearData.map((rowData, index) => (
                                <TableRow
                                    key={`${rowData.filingwindow}-${index}`}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                    }}
                                >
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/funding-year-details/${kyList}/${trimmedFundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.f471applicationnumber}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/form-details/${kyList}/${
                                                rowData.frn
                                            }/${trimmedFundingYear}/${
                                                searchType === 'entNum' || searchType === 'f471' ? 'frn' : 'entNum'
                                            }`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.frn}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/form-details/${kyList}/${
                                                rowData.frn
                                            }/${trimmedFundingYear}/${
                                                searchType === 'entNum' || searchType === 'f471' ? 'frn' : 'entNum'
                                            }`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.frnnickname}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.serviceprovidername}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/funding-summary/${kyList}/${rowData.spin}/spin`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.spin}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.serviceType}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {rowData.origrannualcost}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {rowData.cmtdrannualcost}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            padding: '4px 8px',
                                            fontSize: '10px',
                                            textAlign: 'right',
                                            paddingRight: '25px',
                                        }}
                                    >
                                        {`${Math.round(rowData.discount)}%`}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {rowData.origfundingrequest}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {rowData.frnstatus === 'NF'
                                            ? rowData.frncommittedamount + ' (NF)'
                                            : rowData.frncommittedamount}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {rowData.totalauthorizeddisbursement}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {`${rowData.percentUtilized.toFixed(2)}%`}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            padding: '4px 8px',
                                            fontSize: '10px',
                                            textAlign: 'right',
                                            paddingLeft: '35px',
                                        }}
                                    >
                                        {rowData.f471reviewstatus}
                                        {rowData.fcdldate && <> ({formatDate(rowData.fcdldate)})</>}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            padding: '4px 8px',
                                            fontSize: '10px',
                                            textAlign: 'right',
                                            paddingRight: '35px',
                                        }}
                                    >
                                        {rowData.f486OnFile}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.invoicingmode}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatDate(rowData.servicedeliverydeadline)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatDate(rowData.lastdatetoinvoice)}
                                    </TableCell>
                                </TableRow>
                            ))}

                            {sortedYearData.length > 0 && (
                                <TableRow
                                    sx={{ backgroundColor: sortedYearData.length % 2 === 0 ? 'white' : '#f5f5f5' }}
                                >
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>Totals</TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {formatMoney(frnTotals.origrannualcost)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {formatMoney(frnTotals.cmtdrannualcost)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {formatMoney(frnTotals.origfundingrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {formatMoney(frnTotals.frncommittedamount)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {formatMoney(frnTotals.totalauthorizeddisbursement)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                        {isNaN(frnTotals.frncommittedamount) ||
                                        frnTotals.frncommittedamount === 0 ||
                                        frnTotals.totalauthorizeddisbursement === 0
                                            ? '0%'
                                            : `${(
                                                  (frnTotals.totalauthorizeddisbursement /
                                                      frnTotals.frncommittedamount) *
                                                  100
                                              ).toFixed(2)}%`}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    ) : searchType === 'f471' ? (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '25px', marginBottom: '10px' }}>
                <h4 style={{ color: '#19638B', margin: 0, display: 'flex', alignItems: 'center' }}>
                    E-Rate Funding Search for Funding Year:{' '}
                    <span style={{ textDecoration: 'underline', marginLeft: 5 }}>{trimmedFundingYear}</span>, Form471:{' '}
                    <span style={{ textDecoration: 'underline', marginLeft: 5 }}>{f471appnum}</span>
                    {usedCacheOnLoad && (
                        <>
                            <CircularProgress sx={{ marginLeft: '15px' }} size='0.75rem' />
                            <Typography variant='body2' sx={{ color: 'gray', marginLeft: '10px' }}>
                                <em>Most recent data loading...</em>
                            </Typography>
                        </>
                    )}
                </h4>
            </Box>

            <TableContainer component={Paper}>
                <Table
                    sx={{
                        minWidth: 800,
                        width: '100%', // Ensures the table takes up the full width of the container
                        tableLayout: 'fixed', // Helps in managing how the table handles the available width
                    }}
                    size='small'
                >
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {f471FundingDetailsHeaders.map((header) => (
                                <TableCell
                                    key={header.id}
                                    sortDirection={fydOrderBy === header.id ? fydOrder : false}
                                    sx={{
                                        padding: '4px 8px',
                                        lineHeight: '1.2',
                                        flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                        flexGrow: 1, // Allows the columns to grow to fill available space
                                        flexShrink: 1, // Allows the columns to shrink if necessary
                                        fontSize: '10px',
                                        textAlign: [
                                            'origrannualcost',
                                            'cmtdrannualcost',
                                            'discount',
                                            'origfundingrequest',
                                            'frncommittedamount',
                                            'totalauthorizeddisbursement',
                                            'percentUtilized',
                                            'f471reviewstatus',
                                            'f486filed',
                                            'f471reviewstatus',
                                        ].includes(header.id)
                                            ? 'right'
                                            : 'start',
                                        paddingLeft:
                                            header.id === 'percentUtilized'
                                                ? '50px'
                                                : header.id === 'f471reviewstatus'
                                                ? '50px'
                                                : [
                                                      'origrannualcost',
                                                      'cmtdrannualcost',
                                                      'discount',
                                                      'origfundingrequest',
                                                      'frncommittedamount',
                                                      'totalauthorizeddisbursement',
                                                      'percentUtilized',
                                                      'f471reviewstatus',
                                                      'f486filed',
                                                  ].includes(header.id)
                                                ? '25px'
                                                : undefined,
                                        ...f471ColumnStyles[header.id],
                                    }}
                                >
                                    <TableSortLabel
                                        active={fydOrderBy === header.id}
                                        direction={fydOrderBy === header.id ? fydOrder : 'asc'}
                                        onClick={() => handleFYDRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedYearData.map((rowData, index) => (
                            <TableRow
                                key={`${rowData.filingwindow}-${index}`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/funding-year-details/${kyList}/${trimmedFundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471`}
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                    >
                                        {rowData.f471applicationnumber}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/form-details/${kyList}/${
                                            rowData.frn
                                        }/${trimmedFundingYear}/${
                                            searchType === 'entNum' || searchType === 'f471' ? 'frn' : 'entNum'
                                        }`}
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                    >
                                        {rowData.frn}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/form-details/${kyList}/${
                                            rowData.frn
                                        }/${trimmedFundingYear}/${
                                            searchType === 'entNum' || searchType === 'f471' ? 'frn' : 'entNum'
                                        }`}
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                    >
                                        {rowData.frnnickname}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {rowData.serviceprovidername}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    <Link
                                        href={`/client-list/funding-summary/${kyList}/${rowData.spin}/spin`}
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                    >
                                        {rowData.spin}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {rowData.serviceType}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {rowData.origrannualcost}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {rowData.cmtdrannualcost}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: '4px 8px',
                                        fontSize: '10px',
                                        textAlign: 'right',
                                        paddingRight: '25px',
                                    }}
                                >{`${Math.round(rowData.discount)}%`}</TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {rowData.origfundingrequest}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {rowData.frnstatus === 'NF'
                                        ? rowData.frncommittedamount + ' (NF)'
                                        : rowData.frncommittedamount}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {rowData.totalauthorizeddisbursement}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {`${rowData.percentUtilized.toFixed(2)}%`}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: '4px 8px',
                                        fontSize: '10px',
                                        textAlign: 'right',
                                        paddingLeft: '35px',
                                    }}
                                >
                                    {rowData.f471reviewstatus}
                                    {rowData.fcdldate && <> ({formatDate(rowData.fcdldate)})</>}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: '4px 8px',
                                        fontSize: '10px',
                                        textAlign: 'right',
                                        paddingRight: '35px',
                                    }}
                                >
                                    {rowData.f486OnFile}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {rowData.invoicingmode}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatDate(rowData.servicedeliverydeadline)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatDate(rowData.lastdatetoinvoice)}
                                </TableCell>
                            </TableRow>
                        ))}

                        {sortedYearData.length > 0 && (
                            <TableRow sx={{ backgroundColor: sortedYearData.length % 2 === 0 ? 'white' : '#f5f5f5' }}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>Totals</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {formatMoney(frnTotals.origrannualcost)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {formatMoney(frnTotals.cmtdrannualcost)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {formatMoney(frnTotals.origfundingrequest)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {formatMoney(frnTotals.frncommittedamount)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {formatMoney(frnTotals.totalauthorizeddisbursement)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px', textAlign: 'right' }}>
                                    {isNaN(frnTotals.frncommittedamount) ||
                                    frnTotals.frncommittedamount === 0 ||
                                    frnTotals.totalauthorizeddisbursement === 0
                                        ? '0%'
                                        : `${(
                                              (frnTotals.totalauthorizeddisbursement / frnTotals.frncommittedamount) *
                                              100
                                          ).toFixed(2)}%`}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    ) : (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '25px',
                    marginBottom: '25px',
                }}
            >
                <h4 style={{ color: '#19638B', margin: 0 }}>
                    {`Market Funding Search Analysis for Funding Year ${selectedYear}`}
                </h4>

                <Link
                    href={`/client-list/funding-summary/${kyList}/spin`}
                    sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 3, fontSize: '1rem' }}
                >
                    Utilization Summary Chart
                </Link>
            </Box>
            {sortedGroupedStateDataKeys.map((state) => {
                const stateData = groupedStateData[state];
                const stateFRNTotals = calculateFRNTotals(stateData);

                return (
                    <>
                        <Box id={state} key={state} sx={{ marginBottom: '40px' }}>
                            <Box>
                                <h5 style={{ margin: 0 }}>
                                    SPIN:
                                    <Link
                                        href={`/client-list/funding-summary/${kyList}/spin`}
                                        rel='noreferrer'
                                        sx={{ textDecoration: 'none', fontWeight: 'normal' }}
                                    >
                                        {kyList}
                                    </Link>
                                </h5>
                            </Box>
                            <Box>
                                <h5 style={{ margin: 0 }}>
                                    Funding Year:{' '}
                                    <span style={{ fontWeight: 'normal' }}>
                                        {selectedYear === 'All Years Detail' ? selectedYear : trimmedFundingYear}
                                    </span>
                                </h5>
                            </Box>
                            <Box>
                                <h5 style={{ margin: 0 }}>
                                    State:{' '}
                                    <span style={{ fontWeight: 'normal' }}>{getStateName(state, allStates)}</span>
                                </h5>
                            </Box>
                            <Box>
                                <h5 style={{ margin: 0 }}>
                                    Service Provider:{' '}
                                    <span style={{ fontWeight: 'normal' }}>
                                        {groupedStateData[state][0]['serviceprovidername'] || ''}
                                    </span>
                                </h5>
                            </Box>

                            <TableContainer component={Paper}>
                                <Table
                                    sx={{
                                        minWidth: 800,
                                        width: '100%', // Ensures the table takes up the full width of the container
                                        tableLayout: 'fixed', // Helps in managing how the table handles the available width
                                    }}
                                    size='small'
                                >
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                            {spinFundingDetailsHeaders.map((header) => (
                                                <TableCell
                                                    key={header.id}
                                                    sortDirection={fydOrderBy === header.id ? fydOrder : false}
                                                    sx={{
                                                        padding: '4px 8px',
                                                        lineHeight: '1.2',
                                                        flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                                        flexGrow: 1, // Allows the columns to grow to fill available space
                                                        flexShrink: 1, // Allows the columns to shrink if necessary
                                                        fontSize: '10px',
                                                        textAlign: [
                                                            'origrannualcost',
                                                            'origfundingrequest',
                                                            'frncommittedamount',
                                                            'totalauthorizeddisbursement',
                                                            'percentUtilized',
                                                            'discount',
                                                            'f486servicestartdate',
                                                            'spacfiled',
                                                        ].includes(header.id)
                                                            ? 'right'
                                                            : 'start',
                                                        paddingLeft:
                                                            header.id === 'percentUtilized'
                                                                ? '45px'
                                                                : header.id === 'discount'
                                                                ? '30px'
                                                                : header.id === 'frncommittedamount'
                                                                ? '13px'
                                                                : header.id === 'origfundingrequest'
                                                                ? '35px'
                                                                : [
                                                                      'origrannualcost',
                                                                      'totalauthorizeddisbursement',
                                                                      'percentUtilized',
                                                                      'discount',
                                                                      'f486servicestartdate',
                                                                      'spacfiled',
                                                                  ].includes(header.id)
                                                                ? '25px'
                                                                : undefined,
                                                        ...spinColumnStyles[header.id],
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={fydOrderBy === header.id}
                                                        direction={fydOrderBy === header.id ? fydOrder : 'asc'}
                                                        onClick={() => handleFYDRequestSort(header.id)}
                                                    >
                                                        {header.label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupedStateData[state].map((rowData, index) => (
                                            <TableRow
                                                key={`${rowData.filingwindow}-${index}`}
                                                sx={{
                                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                                }}
                                            >
                                                <TableCell>
                                                    <Link
                                                        href={`/client-list/funding-year-details/${rowData.ben}/${rowData.fundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471`}
                                                        rel='noreferrer'
                                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                    >
                                                        {rowData.f471applicationnumber}
                                                    </Link>
                                                </TableCell>

                                                {/* This links you to the funding year details page based on selectedYear (not the 'all' version) */}
                                                <TableCell sx={{ padding: '4px 8px' }}>
                                                    <Link
                                                        href={`/client-list/form-details/${kyList}/${rowData.frn}/${rowData.fundingYear}/frn`}
                                                        rel='noreferrer'
                                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                    >
                                                        {rowData.frn}
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}>
                                                    <Link
                                                        href={`/client-list/funding-year-details/${rowData.ben}/${rowData.fundingYear}/${state}/entNum`}
                                                        rel='noreferrer'
                                                        sx={{ textDecoration: 'none', fontSize: '10px' }}
                                                    >
                                                        {rowData.ben}
                                                    </Link>
                                                </TableCell>
                                                <TableCell
                                                    sx={{ padding: '4px 8px', fontSize: '10px' }}
                                                >{`${rowData.billedentityname} [${rowData.applicantstate}]`}</TableCell>
                                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                    {rowData.serviceType}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {rowData.origrannualcost}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {rowData.origfundingrequest}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {rowData.frnstatus === 'NF'
                                                        ? rowData.frncommittedamount + ' (NF)'
                                                        : rowData.frncommittedamount}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {rowData.totalauthorizeddisbursement}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {`${rowData.percentUtilized.toFixed(2)}%`}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {`${Math.round(rowData.discount)}%`}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '40px',
                                                    }}
                                                >
                                                    {rowData.f486OnFile}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '40px',
                                                    }}
                                                >
                                                    {rowData.spacfiled}
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                    {rowData.invoicingmode}
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                                    {rowData.wavenumber}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {stateData.length > 0 && (
                                            <TableRow
                                                sx={{
                                                    backgroundColor:
                                                        groupedStateData[state].length % 2 === 0 ? 'white' : '#f5f5f5',
                                                }}
                                            >
                                                <TableCell sx={{ fontSize: '10px' }}>Totals</TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {formatMoney(stateFRNTotals.origrannualcost)}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {formatMoney(stateFRNTotals.origfundingrequest)}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {formatMoney(stateFRNTotals.frncommittedamount)}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {formatMoney(stateFRNTotals.totalauthorizeddisbursement)}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        padding: '4px 8px',
                                                        fontSize: '10px',
                                                        textAlign: 'right',
                                                        paddingRight: '28px',
                                                    }}
                                                >
                                                    {isNaN(stateFRNTotals.frncommittedamount) ||
                                                    stateFRNTotals.frncommittedamount === 0 ||
                                                    stateFRNTotals.totalauthorizeddisbursement === 0
                                                        ? '0%'
                                                        : `${(
                                                              (stateFRNTotals.totalauthorizeddisbursement /
                                                                  stateFRNTotals.frncommittedamount) *
                                                              100
                                                          ).toFixed(2)}%`}
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box sx={{ marginTop: '-35px', marginBottom: '40px', textAlign: 'right' }}>
                            <Link
                                href='#top'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 3,
                                    fontSize: '10px',
                                }}
                                onClick={() => {
                                    const section = document.getElementById('top');
                                    section.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                {'Top of Page'}
                            </Link>
                        </Box>
                    </>
                );
            })}
        </Box>
    );
}
