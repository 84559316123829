import React from 'react';
import { useParams } from 'react-router-dom';
import ClientFundingYearDetails from '../components/ClientFundingYearDetails.js';
import ClientAPI from '../api/ClientAPI.js';

export default function ClientFundingYearDetailsPage() {
    const clientAPI = new ClientAPI();
    let params = useParams();
    console.log('params.fundingYear: ' + params.fundingYear);

    // Prop names are defined in App.js.
    return (
        <ClientFundingYearDetails
            clientAPI={clientAPI}
            kyList={params.kyList}
            fundingYear={params.fundingYear}
            state={params.state}
            searchType={params.searchType}
            f471appnum={params.f471appnum}
        />
    );
}
