import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import logo from '../media/ERateCentralLogo.png';

export default function ClientLogoAndHeaders({ searchType, applicantData, kyList, specificClientContactObj }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                    <img height='100px' src={logo} alt='Logo' style={{ cursor: 'pointer', marginTop: '-90px' }} />
                </Link>
                <Link
                    sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%', cursor: 'pointer' }}
                    href='https://e-ratecentral.com'
                    target='_blank'
                    rel='noreferrer'
                >
                    {'https://e-ratecentral.com'}
                </Link>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: 'fit-content', textAlign: 'left' }}>
                    <Box sx={{ fontSize: '0.875rem', marginBottom: 5 }}>
                        <Box sx={{ display: 'flex' }}>
                            <h3 style={{ margin: 0 }}>Applicant:</h3>
                            <Link
                                href={`/client-list/contact-search-report/${applicantData.ben}/${searchType}`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 1,
                                    fontSize: '1rem',
                                }}
                            >
                                {applicantData.billedentityname}
                            </Link>
                        </Box>

                        <h3 style={{ margin: 0 }}>
                            Billed Entity Number{kyList.toString().includes(',') ? 's' : ''}:{' '}
                            <Link
                                href={`/client-list/funding-summary/${applicantData.ben}/entNum`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    // marginLeft: .5,
                                    fontSize: '1rem',
                                }}
                            >
                                {applicantData.ben}
                            </Link>
                        </h3>

                        {/* <Box sx={{ fontSize: '0.875rem' }}>
                            <h3 style={{ margin: 0 }}>
                                Address:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                    {specificClientContactObj.billedentityaddress1 && (
                                        <>
                                            {specificClientContactObj.billedentityaddress1}
                                            <br />
                                        </>
                                    )}
                                    {specificClientContactObj.billedentitycity ||
                                    specificClientContactObj.billedentitystate ||
                                    specificClientContactObj.billedentityzipcode
                                        ? `${specificClientContactObj.billedentitycity || ''}, ${
                                              specificClientContactObj.billedentitystate || ''
                                          } ${specificClientContactObj.billedentityzipcode || ''}`
                                              .trim()
                                              .replace(/^, | , | $/g, '')
                                        : ''}
                                </span>
                            </h3>
                        </Box>

                        <Box>
                            <h3 style={{ margin: 0 }}>
                                Type:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                    {specificClientContactObj.applicanttype || ''}
                                </span>
                            </h3>
                        </Box> */}
                        <Box sx={{ fontSize: '0.875rem' }}>
                            <h3 style={{ margin: 0 }}>
                                Address:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                    {applicantData.applicantstreetaddress1 && (
                                        <>
                                            {applicantData.applicantstreetaddress1}
                                            <br />
                                        </>
                                    )}
                                    {applicantData.applicantcity ||
                                    applicantData.applicantstate ||
                                    applicantData.applicantzipcode
                                        ? `${applicantData.applicantcity || ''}, ${
                                              applicantData.applicantstate || ''
                                          } ${applicantData.applicantzipcode || ''}`
                                              .trim()
                                              .replace(/^, | , | $/g, '')
                                        : ''}
                                </span>
                            </h3>
                        </Box>

                        <Box>
                            <h3 style={{ margin: 0 }}>
                                Type: <span style={{ fontWeight: 'normal' }}>{applicantData.applicanttype || ''}</span>
                            </h3>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {specificClientContactObj !== 'No 470 Contact Data Available' ? (
                <Box sx={{ marginLeft: 'auto', fontSize: '0.875rem', marginBottom: 8 }}>
                    <h3 style={{ margin: 0 }}>470 Contact Person Information</h3>

                    {specificClientContactObj.contactname && (
                        <h3 style={{ margin: 0 }}>
                            <span style={{ fontWeight: 'normal' }}>{specificClientContactObj.contactname}</span>
                        </h3>
                    )}

                    {specificClientContactObj.contactphone && (
                        <h3 style={{ margin: 0 }}>
                            <span style={{ fontWeight: 'normal' }}>Phone: {specificClientContactObj.contactphone}</span>
                        </h3>
                    )}

                    {specificClientContactObj.contactemail && (
                        <h3 style={{ margin: 0 }}>
                            <Link
                                href={`mailto:${specificClientContactObj.contactemail}`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                }}
                            >
                                {specificClientContactObj.contactemail}
                            </Link>
                        </h3>
                    )}
                </Box>
            ) : (
                <Box sx={{ marginLeft: 'auto', fontSize: '0.875rem', marginBottom: 18 }}>
                    <h3 style={{ margin: 0 }}>No 470 Contact Data Available</h3>
                </Box>
            )}
        </Box>
    );
}
