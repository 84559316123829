import React from 'react';
import { useParams } from 'react-router-dom';
import ClientFundingHistoryDetailsECF from '../components/ClientFundingHistoryDetailsECF.js';
import ClientAPI from '../api/ClientAPI.js';

export default function ClientFundingHistoryDetailsECFPage() {
    const clientAPI = new ClientAPI();
    let params = useParams();

    // Prop names are defined in App.js.
    return (
        <ClientFundingHistoryDetailsECF
            clientAPI={clientAPI}
            kyList={params.kyList} // Now represents the original BEN
            ecfNum={params.ecfNum} // Only present AFTER click on an ECFXYZ number link
            program={params.program}
            searchType={params.searchType}
        />
    );
}
