import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function ClientConsultantApplicantList(props) {
    const fundingYear = props.fundingYear;
    const crn = props.crn;
    const clientAPI = props.clientAPI;
    const [consultantData, setConsultantData] = React.useState(null);

    console.log('Funding year is: ', fundingYear, crn);
    React.useEffect(() => {
        const getConsultantData = async () => {
            let consultantDataResponse = [];

            try {
                // Force an update
                const consultantDataResponse = await clientAPI.GetClientConsultingFirmsApplicantData(fundingYear, crn);
                setConsultantData(consultantDataResponse);

                console.log(
                    '[ConsultantReport][useEffect][getConsultantData] consultantDataVar:',
                    consultantDataResponse
                );
            } catch (error) {
                console.error(error);
                toast.error(error);
            }

            return consultantDataResponse;
        };

        getConsultantData();
    }, [clientAPI]);

    // Don't display anything until we have data.
    if (!consultantData) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>Gathering consulting firm's applicant data...</em>
                </Typography>
            </Box>
        );
    }

    // Header section
    return (
        <Box sx={{ flexBasis: '700px' }}>
            <ApplicantGridSection fundingYear={fundingYear} crn={crn} consultantData={consultantData} />
        </Box>
    );
}

function ApplicantGridSection({ fundingYear, crn, consultantData }) {
    const [consultantOrder, setConsultantOrder] = React.useState('asc');
    const [consultantOrderBy, setConsultantOrderBy] = React.useState('');

    // Don't display anything until we have data.
    if (!consultantData) {
        return null;
    }

    const consultantHeaders = [
        { id: 'ben', label: 'BEN' },
        { id: 'applicantsorganizationname', label: 'Applicant' },
        { id: 'billedentitystate', label: 'State' },
        { id: 'contactname', label: 'Contact on Form 471' },
        { id: 'consultantsname', label: 'Consultant on Form 471' },
    ];
    const consultingFirmsName =
        consultantData && Array.isArray(consultantData) && consultantData.length > 0
            ? consultantData[0].consultantsname
            : '[unknown]';

    const handleConsultantRequestSort = (property) => {
        const isAsc = consultantOrderBy === property && consultantOrder === 'asc';
        setConsultantOrder(isAsc ? 'desc' : 'asc');
        setConsultantOrderBy(property);
    };

    return (
        <Box>
            {/* Consulting Firm's Applicant List */}
            <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                FY {fundingYear} Applicant List for {consultingFirmsName}&nbsp;&nbsp;&nbsp;&nbsp;CRN: {crn}
            </h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            {consultantHeaders.map((header) => (
                                <TableCell key={header.id} sortDirection='asc' sx={{ padding: '4px 8px' }}>
                                    <TableSortLabel
                                        active={consultantOrderBy === header.id}
                                        direction={consultantOrderBy === header.id ? consultantOrder : 'asc'}
                                        onClick={() => handleConsultantRequestSort(header.id)}
                                    >
                                        {header.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {consultantData.map((consultant, index) => (
                            <TableRow
                                key={`${consultant.fundingYear}-Applicant List`}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                }}
                            >
                                <TableCell sx={{ padding: '4px 8px' }}>{consultant.ben}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {consultant.applicantsorganizationname}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{consultant.billedentitystate}</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>
                                    {consultant.contactname}
                                    <br /> {consultant.contactemail}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}>{consultant.consultantsname}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='body2' sx={{ marginTop: 5 }}>
                <strong>Service Legend:</strong> Category 1 includes Telecomm, Voice, Data Transmission and/or Internet
                Access; Category 2 includes Internal Connections, Internal Connections Maintenance and Managed Internal
                Broadband Services
            </Typography>
        </Box>
    );
}
