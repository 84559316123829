import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../useAuthContext';
import { styled } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import ClientAPI from '../api/ClientAPI.js';
import UserAPI from '../api/UserAPI.js';
import logo from '../media/ERateCentralLogo.png';

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function Navbar(props) {
    const auth = useAuthContext();
    const userAPI = new UserAPI();
    const navigate = useNavigate();

    const open = props.open;
    const handleDrawerOpen = props.handleOpen;
    const handleDrawerClose = props.handleClose;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNoti, setAnchorElNoti] = React.useState(null);
    const [notificationCount, setNotificationCount] = React.useState(0);
    const [fiveMostRecentUnread, setFiveMostRecentUnread] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);

    React.useEffect(() => {
        const getAdminStatus = async () => {
            try {
                let adminResult = await userAPI.IsAdmin(auth.cognitoID);
                setIsAdmin(adminResult);
            } catch (error) {
                console.log('Error admin status');
                console.log(error);
                setIsAdmin(false);
            }
        };

        const getNotificationCountAndTop5Notifications = async () => {
            try {
                let notification_update = await userAPI.GetNotificationCountForUser(auth.cognitoID); // returns <= 5 notifications in a list + count
                // console.log('notification_update:', notification_update);
                if (notification_update !== false) {
                    // Handle error from the API
                    setNotificationCount(notification_update.notification_count);
                    setFiveMostRecentUnread(notification_update.top_five_notifications);
                }
            } catch (error) {
                console.log('Error loading notification count');
                console.log(error);
            }
        };

        getNotificationCountAndTop5Notifications(); // Call the function initially on page load, then set it on a timer to update every 30 seconds
        setInterval(async () => {
            await getNotificationCountAndTop5Notifications();
        }, 30000);
        getAdminStatus();
    }, []);

    const signOut = async () => {
        handleDrawerClose();
        await auth.signOut();
        navigate('/login');
    };

    const goToProfile = async () => {
        navigate('profile/');
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuNoti = (event) => {
        setAnchorElNoti(event.currentTarget);
    };

    const handleCloseNoti = () => {
        setAnchorElNoti(null);
    };

    const navigateHome = () => {
        navigate('/');
    };

    const seeAllNotifications = () => {
        navigate('/notifications');
        setAnchorElNoti(null); // close the notifications menu
    };

    const openSelectedNotification = (notificationID) => {
        navigate('/notifications', {
            state: {
                selectedNotificationID: notificationID,
            },
        });
    };

    const markNotificationAsReadUnreadOrDeleted = async (notification, readOrDeleted) => {
        // console.log('the notification to read:', notification);

        let notiInfo = {
            id: notification.id,
            recipient_id: notification.recipient_id,
            read_or_deleted: readOrDeleted,
        };

        // Mark the notification
        try {
            let response = await userAPI.MarkNotificationAsReadUnreadOrDeleted(notiInfo);
            console.log(response);
        } catch (error) {
            console.log('Error updating notification.');
        }

        // Update the dropdown list
        try {
            let notification_update = await userAPI.GetNotificationCountForUser(auth.cognitoID); // returns <= 5 notifications in a list + count
            console.log('notification_update:', notification_update);
            if (notification_update !== false) {
                // Handle error from the API
                setNotificationCount(notification_update.notification_count);
                setFiveMostRecentUnread(notification_update.top_five_notifications);
            }
        } catch (error) {
            console.log('Error loading notification count');
            console.log(error);
        }
    };

    let buildEnvText = '';
    if (process.env.REACT_APP_BUILD_ENV === 'development') {
        buildEnvText = '*** DEVELOPMENT ***';
    } else if (process.env.REACT_APP_BUILD_ENV === 'staging') {
        buildEnvText = '*** STAGING ***';
    }

    return (
        <>
            <AppBar position='fixed' open={open}>
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <img src={logo} height='60px' alt='Logo' onClick={navigateHome} style={{ cursor: 'pointer' }} />

                    <Box sx={{ marginLeft: '25px', marginRight: '25px' }}>
                        <FundingDataSearch />
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                        {buildEnvText !== '' && (
                            <Box
                                sx={{
                                    width: '340px',
                                    margin: 'auto',
                                    color: '#ff0000',
                                    fontWeight: '700',
                                    fontSize: '1.5rem',
                                    fontFamily: 'Times New Roman',
                                    letterSpacing: '.08rem',
                                    '-webkit-text-fill-color': 'red',
                                    '-webkit-text-stroke': '1px #ffb2b2',
                                }}
                            >
                                {buildEnvText}
                            </Box>
                        )}
                    </Box>

                    <Box>
                        <IconButton
                            size='large'
                            aria-label='notifications for current user'
                            aria-controls='menu-appbar-noti'
                            aria-haspopup='true'
                            onClick={handleMenuNoti}
                            color='inherit'
                        >
                            <Badge badgeContent={notificationCount} color='error'>
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <Menu
                            id='menu-appbar-noti'
                            anchorEl={anchorElNoti}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNoti)}
                            onClose={handleCloseNoti}
                        >
                            {fiveMostRecentUnread.map((notification) => {
                                return (
                                    <div
                                        key={notification.created_on}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        <div
                                            onClick={() => openSelectedNotification(notification.id)}
                                            style={{
                                                flexGrow: 1,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {notification.notification_subject}
                                        </div>
                                        <Tooltip title='Mark as Read'>
                                            <IconButton
                                                onClick={() =>
                                                    markNotificationAsReadUnreadOrDeleted(notification, 'read')
                                                }
                                                size='small'
                                                style={{ marginLeft: '8px' }}
                                            >
                                                <VisibilityIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Delete'>
                                            <IconButton
                                                onClick={() =>
                                                    markNotificationAsReadUnreadOrDeleted(notification, 'deleted')
                                                }
                                                size='small'
                                                style={{ marginLeft: '8px' }}
                                            >
                                                <DeleteIcon fontSize='inherit' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                );
                            })}

                            <Divider />
                            <MenuItem onClick={seeAllNotifications}>See All Notifications</MenuItem>
                        </Menu>

                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleMenu}
                            color='inherit'
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem disabled={true}>{auth.userEmail}</MenuItem>
                            <Divider />
                            <MenuItem onClick={goToProfile}>
                                <IconButton style={{ padding: '6px' }}>
                                    <PersonIcon style={{ fontSize: '18px', marginLeft: '-10px' }} />
                                </IconButton>
                                Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={signOut}>
                                <IconButton style={{ padding: '6px' }}>
                                    <LogoutIcon style={{ fontSize: '18px', marginLeft: '-10px' }} />
                                </IconButton>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant='persistent'
                anchor='left'
                open={open}
                sx={{
                    width: 240,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton component={Link} to='/'>
                        <ListItemText primary='Home' />
                    </ListItemButton>

                    <ListItemButton component={Link} to='/f470'>
                        <ListItemText primary='Form 470 Tracker' />
                    </ListItemButton>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/f470/f470-applications'>
                            <ListItemText primary='470 Applications' />
                        </ListItemButton>
                    </List>

                    <ListItemButton component={Link} to='/f471'>
                        {/* QuickSight 'Counts by Account Manager' dashboard */}
                        <ListItemText primary='Form 471 Tracker' />
                    </ListItemButton>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/f471/f471-applications'>
                            {/* Main report */}
                            <ListItemText primary='471 Applications' />
                        </ListItemButton>
                    </List>

                    <ListItemButton component={Link} to='/reports'>
                        <ListItemText primary='Reports' />
                    </ListItemButton>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/reports/deadline-report'>
                            <ListItemText primary='Deadline Report' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to='/reports/applicants'>
                            <ListItemText primary='Applicants' />
                        </ListItemButton>
                    </List>

                    <ListItemButton component={Link} to='/client-list'>
                        <ListItemText primary='Client List' />
                    </ListItemButton>

                    {isAdmin && (
                        <>
                            <ListItemButton component={Link} to='/manage-users'>
                                <ListItemText primary='User Management' />
                            </ListItemButton>

                            <ListItemButton component={Link} to='/system-settings'>
                                <ListItemText primary='System Settings' />
                            </ListItemButton>
                        </>
                    )}
                </List>
            </Drawer>
        </>
    );
}

function FundingDataSearch() {
    const clientAPI = new ClientAPI();
    const navigate = useNavigate();

    const [fundingDataSearchLabel, setFundingDataSearchLabel] = React.useState('Funding Search Value');
    const [fundingDataSearchText, setFundingDataSearchText] = React.useState('');
    const [fundingDataSearchType, setFundingDataSearchType] = React.useState('');
    const [fundingDataSearchState, setFundingDataSearchState] = React.useState('');
    const [searchStates, setSearchStates] = React.useState([]);

    React.useEffect(() => {
        const getStatesList = async () => {
            try {
                let stateResult = await clientAPI.GetStates();
                setSearchStates(stateResult);
            } catch (error) {
                console.log('Error loading states');
                console.log(error);
            }
        };

        getStatesList();
    }, []);

    const handleFundingDataSearchTypeChange = (event) => {
        setFundingDataSearchType(event.target.value);

        if (event.target.value === 'F471ID' || event.target.value === 'FRN') {
            setFundingDataSearchLabel('12345 or ECF12345');
        } else {
            setFundingDataSearchLabel('Funding Search Value');
        }
    };

    const globalSearch = () => {
        if (fundingDataSearchType !== '' && (fundingDataSearchText !== '' || fundingDataSearchState !== '')) {
            // Navigate to embed page, with passed in parameters
            navigate('/global-search', {
                state: {
                    searchType: fundingDataSearchType,
                    searchText: fundingDataSearchText,
                    searchState: fundingDataSearchState,
                },
            });
        }
    };

    return (
        <Box width={600}>
            <Typography component='div' variant='h5' color='inherit' noWrap>
                Funding Data Search
            </Typography>
            <FormControl sx={{ minWidth: '125px', marginLeft: '8px', marginRight: '8px' }} size='small'>
                <InputLabel id='funding-data-select-label' sx={{ color: 'white !important' }}>
                    Data Type
                </InputLabel>
                <Select
                    labelId='funding-data-select-label'
                    label='Data Type'
                    id='funding-data-select'
                    value={fundingDataSearchType}
                    onChange={(event) => handleFundingDataSearchTypeChange(event)}
                    sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white !important',
                        },
                        '.MuiFormLabel-root': {
                            color: 'white',
                        },
                        '.MuiInputBase-input': {
                            color: 'white',
                        },
                        '.MuiSvgIcon-root': {
                            color: 'white !important',
                        },
                    }}
                >
                    <MenuItem value={'SPIN'}>SPIN</MenuItem>
                    <MenuItem value={'BEN'}>BEN</MenuItem>
                    <MenuItem value={'F471ID'}>471</MenuItem>
                    <MenuItem value={'FRN'}>FRN</MenuItem>
                    <MenuItem value={'CRNSingle'}>CRN</MenuItem>
                </Select>
            </FormControl>
            <TextField
                onChange={(event) => setFundingDataSearchText(event.target.value)}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        globalSearch();
                    }
                }}
                value={fundingDataSearchText}
                label={fundingDataSearchLabel}
                variant='outlined'
                size='small'
                sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white !important',
                    },
                    '.MuiFormLabel-root': {
                        color: 'white !important',
                    },
                    '.MuiInputBase-input': {
                        color: 'white',
                    },
                    marginRight: '8px',
                }}
            />

            <FormControl sx={{ minWidth: '125px', marginRight: '8px' }} size='small'>
                <InputLabel id='funding-data-select-label' sx={{ color: 'white !important' }}>
                    State
                </InputLabel>
                <Select
                    labelId='state-search-label'
                    id='state-search'
                    value={fundingDataSearchState}
                    label='State'
                    onChange={(event) => setFundingDataSearchState(event.target.value)}
                    sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white !important',
                        },
                        '.MuiFormLabel-root': {
                            color: 'white',
                        },
                        '.MuiInputBase-input': {
                            color: 'white',
                        },
                        '.MuiSvgIcon-root': {
                            color: 'white !important',
                        },
                    }}
                >
                    <MenuItem value='ALL_STATES'>All States</MenuItem>
                    {searchStates?.length > 0
                        ? searchStates.map((row, i) => (
                              <MenuItem value={row.abbreviation} key={row.abbreviation}>
                                  {row.name}
                              </MenuItem>
                          ))
                        : null}
                </Select>
            </FormControl>
            <Button variant='contained' onClick={globalSearch}>
                Search
            </Button>
        </Box>
    );
}

export default Navbar;
