import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { TextField } from '@mui/material';

export const BulkEditDropdown = ({ initialValue, value, setValue, dropDownOptions, label, multipleValues }) => {
    const [selected, setSelected] = React.useState(false);

    React.useEffect(() => {
        if (value === '') {
            setSelected(false);
        } else {
            setSelected(true);
        }
    }, [value]);

    const handleChange = (event) => {
        setValue(event.target.value);
        setSelected(true);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                '& .MuiOutlinedInput-root':
                    selected && initialValue !== value
                        ? {
                              '& fieldset': {
                                  borderColor: 'orange',
                                  borderWidth: '2px',
                                  borderStyle: 'solid',
                              },
                          }
                        : {},
            }}
        >
            {!dropDownOptions ? (
                <TextField
                    label={label}
                    type='number'
                    value={value || value === 0 ? value : ''}
                    onChange={handleChange}
                    fullWidth
                    variant='outlined'
                    sx={{
                        backgroundColor: multipleValues ? '#f0f0f0' : '',
                    }}
                />
            ) : (
                <Select
                    // label={label}
                    value={value ? value : ''}
                    onChange={handleChange}
                    fullWidth
                    variant='outlined'
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 200,
                            },
                        },
                    }}
                    sx={{
                        backgroundColor: multipleValues ? '#f0f0f0' : '',
                    }}
                >
                    {dropDownOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.text}
                        </MenuItem>
                    ))}
                </Select>
            )}

            {value !== '' && value !== initialValue && (
                <Tooltip title='Reset Field'>
                    <IconButton
                        onClick={() => {
                            setValue(initialValue);
                            setSelected(false);
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};
