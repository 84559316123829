import { API, Storage } from 'aws-amplify';

function TrackersAPI() {
    //************************************************************
    //*     Form 471 Tracker Main Report
    //************************************************************
    async function customForm471Search(searchParameters) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/CustomForm471Search';
            const data = {
                body: searchParameters,
            };
            const searchResponse = await API.post(apiName, path, data);

            if ('status' in searchResponse && searchResponse['status'] === 'ResultTooLarge') {
                let s3Result = await Storage.get(searchResponse['keyName'], { download: true });
                let s3Body = await s3Result.Body.text();
                return JSON.parse(s3Body);
            } else {
                return searchResponse;
            }
        } catch (error) {
            console.error('Error executing main report data search: ', error);
            return false;
        }
    }

    async function getFRNsForApplication(appNum) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/GetFRNsForApplication';
            const queryStringParameters = {
                queryStringParameters: {
                    application_number: appNum,
                },
            };
            const frnResponse = await API.get(apiName, path, queryStringParameters);
            return frnResponse;
        } catch (error) {
            console.error('Error getting FRN data: ', error);
        }
    }

    async function get_Form471Tracker_MainReport_SearchOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetForm471SearchOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting main report search options: ', error);
        }
    }

    async function get_Form471Tracker_MainReport_FilterOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetForm471FilterOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting main report filter options: ', error);
        }
    }

    async function get_Form471Tracker_MainReport_EditOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/GenerateEditingOptions';
            const response = await API.get(apiName, path);
            return response;
        } catch (error) {
            console.error('Error getting main report edit options: ', error);
        }
    }

    async function save_Form471Tracker_MainReport_UED(uedata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/SaveMRRowUED';
            const data = {
                body: uedata,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response);
            return response;
        } catch (error) {
            console.error('Error saving main report uedata: ', error);
            return false;
        }
    }

    async function save_Form471Tracker_Notes(uedata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/saveNotes';
            const data = {
                body: uedata,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response);
            return response;
        } catch (error) {
            console.error('Error saving main report uedata: ', error);
            return false;
        }
    }

    async function save_Form471Tracker_BulkV2(updatedappdata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/SaveBulkV2';
            const data = {
                body: updatedappdata,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error bulk-saving data: ', error);
            return false;
        }
    }

    //************************************************************
    //*     Form 471 Tracker Details and Edit pages
    //************************************************************
    async function get_Form471Tracker_OneAppData(form471AppNum) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/GetOneAppData';
            const queryStringParameters = {
                queryStringParameters: { form471appnum: form471AppNum },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting one data: ', error);
        }
    }

    async function save_Form471Tracker_OneAppData(updatedappdata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/SaveOneAppData';
            const data = {
                body: updatedappdata,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response)
            return response;
        } catch (error) {
            console.error('Error saving uedata: ', error);
            return false;
        }
    }

    //************************************************************
    //*     Form 471 Tracker Applicants report
    //************************************************************

    async function form471Tracker_ApplicantsReport_Search(searchParameters) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/ApplicantForm471Search';
            const data = {
                body: searchParameters,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error executing search: ', error);
        }
    }

    async function get_Form471Tracker_ApplicantsReport_FilterOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/GetApplicantsReportFilterOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting filter options: ', error);
        }
    }

    async function get_Form471Tracker_ApplicantsReport_SearchOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/GetApplicantsReportSearchOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting search options: ', error);
        }
    }

    async function save_Form471Tracker_ApplicantsReport_UED(uedata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/SaveApplicantsReportUED';
            const data = {
                body: uedata,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response);
            return response;
        } catch (error) {
            console.error('Error saving uedata: ', error);
            return false;
        }
    }

    async function save_Form471Tracker_ApplicantsReport_UED_Bulk(uedata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/SaveApplicantsReportUEDBulk';
            const data = {
                body: uedata,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response);
            return response;
        } catch (error) {
            console.error('Error saving bulk uedata: ', error);
            return false;
        }
    }

    async function save_Form471Tracker_ApplicantsReport_Notes(uedata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form471Tracker/SaveApplicantsReportNotes';
            const data = {
                body: uedata,
            };
            const response = await API.post(apiName, path, data);
            //console.log('response: ', response);
            return response;
        } catch (error) {
            console.error('Error saving uedata: ', error);
            return false;
        }
    }

    //************************************************************
    //*    Form 470 Tracker Main Report
    //************************************************************
    async function get_Form470Tracker_MainReport_SearchOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetForm470SearchOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting main report search options: ', error);
        }
    }

    async function get_Form470Tracker_MainReport_FilterOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetForm470FilterOptions';
            const searchOptionResponse = await API.get(apiName, path);
            return searchOptionResponse;
        } catch (error) {
            console.error('Error getting main report filter options: ', error);
        }
    }

    async function form470TrackerMainReportSearch(searchParameters) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/app/search';
            const data = {
                body: searchParameters,
            };
            let searchResponse = await API.post(apiName, path, data);
            searchResponse = JSON.parse(searchResponse);
            if ('status' in searchResponse && searchResponse['status'] === 'ResultTooLarge') {
                let s3Result = await Storage.get(searchResponse['keyName'], { download: true });
                let s3Body = await s3Result.Body.text();
                return JSON.parse(s3Body);
            } else {
                return searchResponse;
            }
        } catch (error) {
            console.error('Error executing form 470 data search: ', error);
            return false;
        }
    }

    //************************************************************
    //*    Form 470 Details and Edit pages
    //************************************************************
    async function get_Form470Tracker_AppData(form470AppNum, form470AppIncrement) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/app/' + form470AppNum + '/' + form470AppIncrement;
            const queryStringParameters = {
                queryStringParameters: { form470appnum: form470AppNum, form470appincrement: form470AppIncrement },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting data: ', error);
        }
    }

    async function save_Form470Tracker_AppData_Bulk(updatedappdata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/bulkSave';
            const data = {
                body: updatedappdata,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error bulk-saving data: ', error);
            return false;
        }
    }

    async function save_Form470Tracker_AppData_Bulk_V2(updatedappdata) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/bulkSaveV2';
            const data = {
                body: updatedappdata,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error bulk-saving data: ', error);
            return false;
        }
    }

    async function save_Form470Tracker_AppData(updatedappdata, form470AppNum, form470AppIncrement) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/save/' + form470AppNum + '/' + form470AppIncrement;
            const data = {
                body: updatedappdata,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error saving data: ', error);
            return false;
        }
    }

    async function save_Form470Tracker_AppData_Inline(updatedappdata, form470AppNum, form470AppIncrement) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/saveInline/' + form470AppNum + '/' + form470AppIncrement;
            const data = {
                body: updatedappdata,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error saving data inline: ', error);
            return false;
        }
    }

    async function save_Form470Tracker_Notes(updatedNotes, form470AppNum, form470AppIncrement) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/saveNotes/' + form470AppNum + '/' + form470AppIncrement;
            const data = {
                body: updatedNotes,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error saving data inline: ', error);
            return false;
        }
    }

    async function get_Form470Tracker_MainReport_EditOptions() {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/GenerateEditingOptions';
            const response = await API.get(apiName, path);
            return response;
        } catch (error) {
            console.error('Error getting main report edit options: ', error);
        }
    }

    //************************************************************
    //*     Form 470 Tracker Minibid
    //************************************************************
    async function create_Form470Minibid(minibidInfo) {
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/Form470Tracker/CreateMinibid';
            const data = {
                body: minibidInfo,
            };
            const response = await API.post(apiName, path, data);
            return response;
        } catch (error) {
            console.error('Error creating Minibid: ', error);
        }
    }

    //************************************************************
    //*     General/misc
    //************************************************************
    async function get_UsacDataRetrievalDatetimes(metadataRequested) {
        // Retrieves timestamp data from the Form471TrackerMetadata table (and, in the future, from other metadata tables we create).
        // IOW, retrieves the retrieval/scrape datetimes of the data that was upserted into tables like CompiledForm471UsacData.
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetUsacDataRetrievalDatetimes';
            const queryStringParameters = {
                queryStringParameters: { metadataRequested: metadataRequested },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting data (from GetUsacDataRetrievalDatetimes): ', error);
        }
    }

    async function get_LastUsacDataRetrievalDatetimes(metadataRequested) {
        // Retrieves the last timestamp data from the Form471TrackerMetadata table (and, in the future, from other metadata tables we create).
        // IOW, retrieves the last retrieval/scrape datetime per data source of the data that was upserted into tables like CompiledForm471UsacData.
        try {
            const apiName = 'ERateCentralPortalAPI';
            const path = '/GetLastUsacDataRetrievalDatetimes';
            const queryStringParameters = {
                queryStringParameters: { metadataRequested: metadataRequested },
            };
            const response = await API.get(apiName, path, queryStringParameters);
            return response;
        } catch (error) {
            console.error('Error getting data (from GetLastUsacDataRetrievalDatetimes): ', error);
        }
    }

    return {
        //=====  Form 471 Tracker Main Report  =====
        CustomForm471Search: customForm471Search,
        GetFRNsForApplication: getFRNsForApplication,
        GetForm471TrackerMainReportSearchOptions: get_Form471Tracker_MainReport_SearchOptions,
        GetForm471TrackerMainReportFilterOptions: get_Form471Tracker_MainReport_FilterOptions,
        GetForm471TrackerMainReportEditOptions: get_Form471Tracker_MainReport_EditOptions,
        SaveForm471TrackerMainReportUED: save_Form471Tracker_MainReport_UED,
        SaveForm471TrackerNotes: save_Form471Tracker_Notes,
        //=====  Form 471 Tracker Details and Edit pages  =====
        GetForm471TrackerOneAppData: get_Form471Tracker_OneAppData,
        SaveForm471TrackerOneAppData: save_Form471Tracker_OneAppData,
        Form471TrackerSaveBulkV2: save_Form471Tracker_BulkV2,
        //=====  Form 471 Tracker Applicants report  =====
        Form471TrackerApplicantsReportSearch: form471Tracker_ApplicantsReport_Search,
        GetForm471TrackerApplicantsReportFilterOptions: get_Form471Tracker_ApplicantsReport_FilterOptions,
        GetForm471TrackerApplicantsReportSearchOptions: get_Form471Tracker_ApplicantsReport_SearchOptions,
        SaveForm471TrackerApplicantsReportUED: save_Form471Tracker_ApplicantsReport_UED,
        SaveForm471TrackerApplicantsReportUEDBulk: save_Form471Tracker_ApplicantsReport_UED_Bulk,
        SaveForm471TrackerApplicantsReportNotes: save_Form471Tracker_ApplicantsReport_Notes,
        //=====  Form 470 Tracker Main Report  =====
        GetForm470TrackerMainReportSearchOptions: get_Form470Tracker_MainReport_SearchOptions,
        GetForm470TrackerMainReportFilterOptions: get_Form470Tracker_MainReport_FilterOptions,
        Form470TrackerMainReportSearch: form470TrackerMainReportSearch,
        GetForm470TrackerAppData: get_Form470Tracker_AppData,
        SaveForm470TrackerAppDataBulk: save_Form470Tracker_AppData_Bulk,
        SaveForm470TrackerAppDataBulkV2: save_Form470Tracker_AppData_Bulk_V2,
        SaveForm470TrackerAppData: save_Form470Tracker_AppData,
        GetForm470TrackerMainReportEditOptions: get_Form470Tracker_MainReport_EditOptions,
        SaveForm470TrackerAppDataInline: save_Form470Tracker_AppData_Inline,
        SaveForm470TrackerNotes: save_Form470Tracker_Notes,
        //=====  Form 470 Tracker Minibid  =====
        CreateForm470Minibid: create_Form470Minibid,
        //=====  General/misc  ====
        GetUsacDataRetrievalDatetimes: get_UsacDataRetrievalDatetimes,
        GetLastUsacDataRetrievalDatetimes: get_LastUsacDataRetrievalDatetimes,
    };
}

export default TrackersAPI;
