import React from 'react';
import Container from '@mui/material/Container';
import Embed from '../components/Embed.js';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import ClientAPI from '../api/ClientAPI.js';

function GlobalSearchResult() {
    const { state } = useLocation();
    const { searchType, searchText, searchState } = state;
    console.log('searchType, searchText, searchState', searchType, searchText, searchState);

    const [parameterObject, setParameterObject] = React.useState({});
    const [dashboardName, setDashboardName] = React.useState('');
    const [sheetID, setSheetID] = React.useState('');
    const [ben, setBen] = React.useState(0);
    const [fundingYear, setFundingYear] = React.useState(0);
    const [applicantState, setApplicantState] = React.useState('');
    const [isApiResponseDone, setIsApiResponseDone] = React.useState(false);
    const clientAPI = new ClientAPI();

    React.useEffect(() => {
        setParameterObject({});
        setIsApiResponseDone(false);

        if (searchType === 'FRN' || searchType === 'F471ID') {
            const getFundingYearDetailsData = async (searchType, ky) => {
                const fundingYearDetailsResponse = await clientAPI.GetFSTDataForGlobalSearch(searchType, ky);
                console.log(
                    '[GlobalSearchResult][useEffect][getFundingYearDetailsData] fundingYearDetailsResponse:',
                    fundingYearDetailsResponse
                );

                if (fundingYearDetailsResponse) {
                    setBen(fundingYearDetailsResponse.ben);
                    setFundingYear(fundingYearDetailsResponse.fundingyear);
                    setApplicantState(fundingYearDetailsResponse.applicantstate);
                    setIsApiResponseDone(true);
                }
            };

            const getECFData = async (searchType, kyList, state, program, global_search) => {
                const ecfDataResponse = await clientAPI.GetClientECFData(
                    searchType,
                    kyList,
                    state,
                    program,
                    global_search
                );
                console.log('[GlobalSearchResult][useEffect][getECFData] ecfDataResponse:', ecfDataResponse);

                if (ecfDataResponse) {
                    setBen(ecfDataResponse[0].billedentitynumber);
                    setIsApiResponseDone(true);
                }
            };

            if ((searchType === 'F471ID' || searchType === 'FRN') && searchText.slice(0, 3).toUpperCase() === 'ECF') {
                getECFData(searchType, searchText, null, null, Boolean(true));
            } else {
                getFundingYearDetailsData(searchType, searchText);
            }
        }

        let tempParamObject = {};
        if (searchText !== '') {
            tempParamObject[searchType] = searchText;
            setParameterObject(tempParamObject);
        }

        if (searchType === 'BEN' || searchType === 'SPIN') {
            setDashboardName('ApplicantsSummaryDashboard');

            if (searchState !== '' && searchState !== 'ALL_STATES') {
                tempParamObject['State'] = searchState;
                setParameterObject(tempParamObject);
            }
        } else if (searchType === 'F471ID') {
            if (searchText.slice(0, 3).toUpperCase() === 'ECF') {
                tempParamObject['F471'] = searchText; //Handling that parameters are different for ECF dashboard vs Regular Dashboard
                setParameterObject(tempParamObject);
                setDashboardName('ApplicantsSummaryDashboard');
                setSheetID('177d3fcb-58cd-439d-a4db-a75b93bfb0dc_0fd2f367-81d6-4c8b-b8c6-192f34408128');
            } else {
                setDashboardName('ApplicationSummaryDashboard');
                setSheetID('b669d4e3-deb6-4aff-9ebb-c67bfe2f1ef8_6a0b87a0-6559-4b74-8ab2-f214fd289a78');
            }
        } else if (searchType === 'FRN') {
            if (searchText.slice(0, 3).toUpperCase() === 'ECF') {
                tempParamObject['FRNECF'] = searchText;
                setParameterObject(tempParamObject);
                setDashboardName('ApplicantsSummaryDashboard');
                setSheetID('177d3fcb-58cd-439d-a4db-a75b93bfb0dc_7467e410-c33c-4f7e-8e4e-f8e7118f1e07');
            } else {
                setDashboardName('ApplicationSummaryDashboard');
                setSheetID('b669d4e3-deb6-4aff-9ebb-c67bfe2f1ef8_dee02a4a-f1a8-4a79-8f07-e6e705dc31de');
            }
        } else if (searchType === 'CRNSingle') {
            setDashboardName('ApplicantsSummaryDashboard');
            setSheetID('177d3fcb-58cd-439d-a4db-a75b93bfb0dc_402ef36e-c37c-429a-a682-2b821420eaff');

            if (searchState !== '' && searchState !== 'ALL_STATES') {
                tempParamObject['State'] = searchState;
                setParameterObject(tempParamObject);
            }
        }
    }, [searchType, searchText, searchState]);

    const routeHelper = {
        BEN: `/client-list/funding-summary/${searchText}/entNum`,
        SPIN: `/client-list/funding-summary/${searchText}/spin`,
        FRN: `/client-list/form-details/${ben}/${searchText}/${fundingYear}/frn`,
        ECFFRN: `/client-list/form-details-all-ecf/${ben}/${searchText}/frn`,
        F471ID: `/client-list/funding-year-details/${ben}/${fundingYear}/${searchText}/${applicantState}/f471`,
        ECFF471ID: `/client-list/funding-history-details/${ben}/${searchText}/ECF/f471`,
    };

    return (
        <>
            {(searchType === 'BEN' ||
                searchType === 'SPIN' ||
                ((searchType === 'FRN' || searchType === 'F471ID') && isApiResponseDone)) && (
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    // href={routeHelper[searchType]}
                    // href={routeHelper[
                    //     // Use ternary operator to determine correct route
                    //     searchType === 'F471ID' && searchText.slice(0, 3).toUpperCase() === 'ECF'
                    //         ? 'ECFF471ID'
                    //         : searchType
                    // ]}
                    href={
                        routeHelper[
                            // Use ternary operators to determine correct route for both FRN and F471ID
                            searchType === 'F471ID' && searchText.slice(0, 3).toUpperCase() === 'ECF'
                                ? 'ECFF471ID'
                                : searchType === 'FRN' && searchText.slice(0, 3).toUpperCase() === 'ECF'
                                ? 'ECFFRN'
                                : searchType
                        ]
                    }
                    target='_blank'
                    rel='noreferrer'
                    sx={{ marginLeft: '25px', marginBottom: '10px' }}
                >
                    Print Friendly Summary
                </Button>
            )}
            <Container
                maxWidth={false}
                style={{
                    width: '100%',
                }}
            >
                <Embed
                    dashboardName={dashboardName}
                    sheetID={sheetID}
                    defaultToCurrentUser={false}
                    passedParameters={parameterObject}
                />
            </Container>
        </>
    );
}

export default GlobalSearchResult;
