import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { gridColumnFieldsSelector, useGridApiRef } from '@mui/x-data-grid-pro';
import MultiAutoComplete from './MultiAutoComplete.js';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import UserAPI from '../api/UserAPI.js';

const defaultFilterPrefs = '{"items": []}';
const defaultSortPrefs = '[]';
const defaultHiddenColumns =
    '{"FilingWindowID": false, "fundingProgram": false, "state": false, "QAReviewStatusID": false, "QASubmitterUserID": false, "QADateSubmitted": false, "QADateNeeded": false, "QAEstimatedFundingRequest": false, "QAReviewerUserID": false, "_qaNotes": false, "_appStatusCombined": false}';
const defaultPinnedColumns = '{"left": [], "right": []}';

const LS_INCREMENT = 1;
const FIXED_ROW_HEIGHT = 38;
const F470_DENSITY_LS_NAME = 'cedClientListDensityPref' + LS_INCREMENT;
const F470_ROWHEIGHT_LS_NAME = 'cedF470TRowHeight' + LS_INCREMENT;
const F470_FILTER_LS_NAME = 'cedF470TFilterPrefs' + LS_INCREMENT;
const F470_SORT_LS_NAME = 'cedF470TSortPrefs' + LS_INCREMENT;
const F470_HIDDEN_LS_NAME = 'cedF470THiddenColumns' + LS_INCREMENT;
const F470_COLUMNORDER_LS_NAME = 'cedF470TColumnOrder' + LS_INCREMENT;
const F470_PINNED_LS_NAME = 'cedF470TPinnedColumns' + LS_INCREMENT;

export default function Form470TrackerAppEdit({
    form470AppNum,
    form470AppIncrement,
    trackersAPI,
    handleReturnToSourcePage,
}) {
    const apiRef = useGridApiRef();
    let navigate = useNavigate();
    const userAPI = new UserAPI();

    //~~~  State  ~~~
    const [appData, setAppData] = React.useState(null);
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const [username, setUsername] = React.useState(null);

    // Editable app data
    const [ercAppStatusId, setERCAppStatusId] = React.useState(null);
    const [applicationOwnerUserId, setApplicationOwnerUserId] = React.useState(null);
    const [taskOwnerUserId, setTaskOwnerUserId] = React.useState(null);
    const [primaryContactUserId, setPrimaryContactUserId] = React.useState(null);
    const [dateSentToClientToCertify, setDateSentToClientToCertify] = React.useState(null);
    const [issueDate, setIssueDate] = React.useState(null);
    const [form470AppNotes, setForm470AppNotes] = React.useState('');
    const [qaReviewStatusId, setQAReviewStatusId] = React.useState(null);
    const [qaReviewerUserId, setQAReviewerUserId] = React.useState(null);
    const [qaIssues, setQAIssues] = React.useState([]);
    const [qaNotes, setQANotes] = React.useState('');
    const [qaSubmitterUserId, setQASubmitterUserId] = React.useState(null);
    const [qaDateSubmitted, setQADateSubmitted] = React.useState(null);
    const [qaDateNeeded, setQADateNeeded] = React.useState(null);
    const [qaDateAssigned, setQADateAssigned] = React.useState(null);
    const [qaDateCompleted, setQADateCompleted] = React.useState(null);
    const [qaDFSP, setQADFSP] = React.useState(null);
    const [preBidDate, setPreBidDate] = React.useState(null);
    const [questionsDeadlineDate, setQuestionsDeadlineDate] = React.useState(null);
    const [answersDeadlineDate, setAnswersDeadlineDate] = React.useState(null);
    const [answersPostedDate, setAnswersPostedDate] = React.useState(null);
    const [bidClosingDate, setBidClosingDate] = React.useState(null);
    const [bidEvaluationDate, setBidEvaluationDate] = React.useState(null);
    const [awardDate, setAwardDate] = React.useState(null);
    const [emailSentDate, setEmailSentDate] = React.useState(null);

    const [bidEvalQAStatusId, setBidEvalQAStatusId] = React.useState(null);
    const [bidEvalQAReviewerUserId, setBidEvalQAReviewerUserId] = React.useState(null);
    const [bidEvalQASubmitterUserId, setBidEvalQASubmitterUserId] = React.useState(null);
    const [bidEvalQADateSubmitted, setBidEvalQADateSubmitted] = React.useState(null);
    const [bidEvalQADateNeeded, setBidEvalQADateNeeded] = React.useState(null);
    const [bidEvalQADateCompleted, setBidEvalQADateCompleted] = React.useState(null);

    const [pageSize, setPageSize] = React.useState(2000);
    const [densityPref, setDensityPref] = useLocalStorage(F470_DENSITY_LS_NAME, 'standard');
    const [rowHeightPref, setRowHeightPref] = useLocalStorage(F470_ROWHEIGHT_LS_NAME, 'fixed'); // Storing 'fixed' or 'auto'
    const [filterPrefs, setFilterPrefs] = useLocalStorage(F470_FILTER_LS_NAME, defaultFilterPrefs);
    const [sortPrefs, setSortPrefs] = useLocalStorage(F470_SORT_LS_NAME, defaultSortPrefs);
    const [hiddenColumnPrefs, setHiddenColumnPrefs] = useLocalStorage(F470_HIDDEN_LS_NAME, defaultHiddenColumns);
    const [pinnedColumnPrefs, setPinnedColumnPrefs] = useLocalStorage(F470_PINNED_LS_NAME, defaultPinnedColumns);
    // const [rows, setRows] = React.useState(columns);

    const handleERCAppStatusChange = (ercAppStatusId) => {
        setERCAppStatusId(ercAppStatusId);

        // (1)  When an AM changes ERC App Status to "Ready for QA":
        //          If QA Status is blank then change QA Status to "QA Ready".
        // (2)  When an AM changes ERC App Status to "Ready for QA":
        //          If "QA Submitter", "QA Date Submitted", and "QA Date Needed" are empty
        //          then set "QA Submitter", "QA Date Submitted", and "QA Date Needed" to defaults.
        const ERCForm470AppStatusId_ReadyForQA = 50; // "Ready for QA"
        const QAReviewStatusId_QAReady = 2; // "QA Ready"
        if (ercAppStatusId === ERCForm470AppStatusId_ReadyForQA) {
            if (qaReviewStatusId == null || qaReviewStatusId === 0) {
                setQAReviewStatusId(QAReviewStatusId_QAReady);
            }

            if (qaSubmitterUserId === null && qaDateSubmitted === null && qaDateNeeded === null) {
                let qadefaultdatestrings = calcQADefaultDateStrings();
                let resolved_qa_submitter_id =
                    applicationOwnerUserId !== null && applicationOwnerUserId !== 0
                        ? applicationOwnerUserId
                        : primaryContactUserId;

                setQASubmitterUserId(resolved_qa_submitter_id);
                setQADateSubmitted(qadefaultdatestrings.todayString);
                setQADateNeeded(qadefaultdatestrings.defaultDateNeededString);
            }
        }
    };

    const calcQADefaultDateStrings = () => {
        let today1 = new Date();
        let offset = today1.getTimezoneOffset();
        let today2 = new Date(today1.getTime() - offset * 60 * 1000); // remove offset
        let defaultDateNeeded = new Date(today2);
        defaultDateNeeded.setDate(defaultDateNeeded.getDate() + 2); // TODO: Add 2 weekday/business days not calendar days.

        let todayString = today2.toISOString().split('T')[0];
        let defaultDateNeededString = defaultDateNeeded.toISOString().split('T')[0];

        return { todayString, defaultDateNeededString };
    };

    const getRowHeight = React.useCallback(
        ({ densityFactor }) => {
            if (rowHeightPref === 'auto') {
                return 'auto';
            } else {
                return FIXED_ROW_HEIGHT * densityFactor; // 0.7, 1, or 1.3
            }
        },
        [rowHeightPref]
    );

    const getDetailPanelHeight = React.useCallback(() => 'auto', []);

    const handleDensityChange = (newDensity) => {
        setDensityPref(newDensity);
    };

    async function handleOnFilterModelChange(newFilterModel) {
        setFilterPrefs(JSON.stringify(newFilterModel));
    }

    function handleOnSortModelChange(newSortModel) {
        setSortPrefs(JSON.stringify(newSortModel));
    }

    function handleOnColumnVisibilityModelChange(newVizModel) {
        setHiddenColumnPrefs(JSON.stringify(newVizModel));
    }

    function handlePinnedPrefs(newPinModel) {
        setPinnedColumnPrefs(JSON.stringify(newPinModel));
    }

    const setColumnOrderPref = (newColOrder) => {
        localStorage.setItem(F470_COLUMNORDER_LS_NAME, JSON.stringify(newColOrder));
    };

    const handleOnColumnOrderChange = React.useCallback(() => {
        setColumnOrderPref(gridColumnFieldsSelector(apiRef.current.state));
    }, [apiRef]);

    const handleQAReviewStatusChange = (qaReviewStatusId) => {
        setQAReviewStatusId(qaReviewStatusId);

        //  When a reviewer changes QA Status to "QA Complete No Issues" or "QA Complete With Issues" or "Will Not Be Reviewed":
        //      If ERC App Status is "Ready for QA" then change ERC App Status to "Out of QA".
        // const QAReviewStatusId_QAReturned = 4; // "QA Returned --> 4) QA Returned"
        const QAReviewStatusId_QACompleteNoIssues = 6; // "QA Passed --> 6) 470 QA Complete No Issues"
        const QAReviewStatusId_QACompleteWithIssues = 7; // "QA Passed with Issues --> 7) 470 QA Complete with Issues"
        const QAReviewStatusId_WillNotBeReviewed = 8; // "Will Not Be Reviewed --> 8) Will Not Be Reviewed"
        const ERCForm470AppStatusId_ReadyForQA = 50; // "Ready for QA"
        const ERCForm470AppStatusId_OutOfQA = 60; // "Out of QA"
        if (
            qaReviewStatusId === QAReviewStatusId_QACompleteNoIssues ||
            qaReviewStatusId === QAReviewStatusId_QACompleteWithIssues ||
            qaReviewStatusId === QAReviewStatusId_WillNotBeReviewed
        ) {
            if (ercAppStatusId === ERCForm470AppStatusId_ReadyForQA) {
                setERCAppStatusId(ERCForm470AppStatusId_OutOfQA);
            }
        }
    };

    const handleBidEvalQAReviewStatusChange = (qaReviewStatusId) => {
        console.log('TODO: handleBidEvalQAReviewStatusChange');
        // setQAReviewStatusId(qaReviewStatusId);

        // //  When a reviewer changes QA Status to "QA Complete No Issues" or "QA Complete With Issues" or "Will Not Be Reviewed":
        // //      If ERC App Status is "Ready for QA" then change ERC App Status to "Out of QA".
        // // const QAReviewStatusId_QAReturned = 4; // "QA Returned --> 4) QA Returned"
        // const QAReviewStatusId_QACompleteNoIssues = 6; // "QA Passed --> 6) 470 QA Complete No Issues"
        // const QAReviewStatusId_QACompleteWithIssues = 7; // "QA Passed with Issues --> 7) 470 QA Complete with Issues"
        // const QAReviewStatusId_WillNotBeReviewed = 8; // "Will Not Be Reviewed --> 8) Will Not Be Reviewed"
        // const ERCForm470AppStatusId_ReadyForQA = 50; // "Ready for QA"
        // const ERCForm470AppStatusId_OutOfQA = 60; // "Out of QA"
        // if (
        //     qaReviewStatusId === QAReviewStatusId_QACompleteNoIssues ||
        //     qaReviewStatusId === QAReviewStatusId_QACompleteWithIssues ||
        //     qaReviewStatusId === QAReviewStatusId_WillNotBeReviewed
        // ) {
        //     if (ercAppStatusId === ERCForm470AppStatusId_ReadyForQA) {
        //         setERCAppStatusId(ERCForm470AppStatusId_OutOfQA);
        //     }
        // }
    };

    // Edit dropdowns options
    const [applicationOwnerEditOptions, setApplicationOwnerEditOptions] = React.useState([]);
    const [taskOwnerEditOptions, setTaskOwnerEditOptions] = React.useState([]);
    const [ercAppStatusEditOptions, setERCAppStatusEditOptions] = React.useState([]);
    const [qaReviewStatusEditOptions, setQAReviewStatusEditOptions] = React.useState([]);
    const [qaReviewerEditOptions, setQAReviewerEditOptions] = React.useState([]);
    const [qaIssuesEditOptions, setQAIssuesEditOptions] = React.useState([]);
    const [qaSubmitterEditOptions, setQASubmitterEditOptions] = React.useState([]);

    const [bidEvalQAStatusEditOptions, setBidEvalQAStatusEditOptions] = React.useState([]);
    const [bidEvalQAReviewerEditOptions, setBidEvalQAReviewerEditOptions] = React.useState([]);
    const [bidEvalQASubmitterEditOptions, setBidEvalQASubmitterEditOptions] = React.useState([]);

    //~~~  Get data  ~~~
    React.useEffect(() => {
        const fetch470EditOptions = async () => {
            try {
                let result = await trackersAPI.GetForm470TrackerMainReportEditOptions();
                console.log('Dropdowns-470 (from trackersAPI.GetForm470TrackerMainReportEditOptions)', result);

                setApplicationOwnerEditOptions(result.applicationOwners);
                setTaskOwnerEditOptions(result.taskOwners);
                setERCAppStatusEditOptions(result.ercAppStatuses);
                setQAReviewStatusEditOptions(result.qaReviewStatuses);
                setQAReviewerEditOptions(result.qaReviewers);
                setQAIssuesEditOptions(result.qaIssues);
                setQASubmitterEditOptions(result.qaSubmitters);

                setBidEvalQAStatusEditOptions(result.qaBidEvalStatuses);
                setBidEvalQAReviewerEditOptions(result.qaBidEvalReviewers);
                setBidEvalQASubmitterEditOptions(result.qaBidEvalSubmitters);
                // console.log('applicationOwnerUserId = ', applicationOwnerUserId)
            } catch (error) {
                //console.error(error);
                toast.error(error);
            }
        };

        const fetchForm470AppData = async (form470AppNum, form470AppIncrement) => {
            try {
                let appdata = await trackersAPI.GetForm470TrackerAppData(form470AppNum, form470AppIncrement);
                let parsedObject = JSON.parse(appdata.body);
                setAppData(parsedObject);
                console.log('parsedObject = ', parsedObject);

                //--- form470app ---
                setERCAppStatusId(parsedObject.erc_form470_app_status_id);
                setPrimaryContactUserId(parsedObject.client_primary_contact.user_id);
                setApplicationOwnerUserId(parsedObject.application_owner_user_id); // application_owner_user_id can be null.
                if (parsedObject.task_owners && parsedObject.task_owners.length > 0) {
                    setTaskOwnerUserId(parsedObject.task_owners[0].user_id);
                    // console.log('parsedObject.task_owners[0].user_id = ', parsedObject.task_owners[0].user_id)
                }

                setDateSentToClientToCertify(parsedObject.date_sent_to_client_to_certify);

                setIssueDate(parsedObject.issue_date);

                if (parsedObject.form470_app_notes === null) {
                    setForm470AppNotes('');
                } else {
                    setForm470AppNotes(parsedObject.form470_app_notes);
                }

                if (parsedObject.qa_issues && parsedObject.qa_issues.length > 0) {
                    let issues = parsedObject.qa_issues.map((issue) => {
                        return issue.issueId;
                    });
                    // console.log("the issues:", issues);
                    setQAIssues(issues);
                }
                // --- qa_form470app ---
                if (parsedObject.hasQAData) {
                    setQAReviewStatusId(parsedObject.qa_review_status_id); // qa_review_status_id can be null .
                    setQAReviewerUserId(parsedObject.qa_reviewer_user_id); // qa_review_user_id can be null.

                    if (parsedObject.qa_notes === null) {
                        setQANotes('');
                    } else {
                        setQANotes(parsedObject.qa_notes);
                    }
                    setQASubmitterUserId(parsedObject.qa_submitter_user_id);
                    setQADateSubmitted(parsedObject.qa_date_submitted);
                    setQADateNeeded(parsedObject.qa_date_needed);
                    setQADateAssigned(parsedObject.qa_date_assigned);
                    setQADateCompleted(parsedObject.qa_date_completed);
                    setQADFSP(parsedObject.qa_darkfiberselfprovisioned_name);

                    setBidEvalQAStatusId(parsedObject.qa_bid_eval_status_id);
                    setBidEvalQAReviewerUserId(parsedObject.qa_bid_eval_reviewer_user_id);
                    setBidEvalQASubmitterUserId(parsedObject.qa_bid_eval_submitter_user_id);
                    setBidEvalQADateSubmitted(parsedObject.qa_bid_eval_date_submitted);
                    setBidEvalQADateNeeded(parsedObject.qa_bid_eval_date_needed);
                    setBidEvalQADateCompleted(parsedObject.qa_bid_eval_date_completed);
                }

                // --- appdates ---
                if (parsedObject.appdates_WTPreBidMtg && parsedObject.appdates_WTPreBidMtg.length > 0) {
                    setPreBidDate(parsedObject.appdates_WTPreBidMtg[0]['date']);
                }
                if (parsedObject.appdates_QuestionsDeadline && parsedObject.appdates_QuestionsDeadline.length > 0) {
                    setQuestionsDeadlineDate(parsedObject.appdates_QuestionsDeadline[0]['date']);
                }
                if (parsedObject.appdates_AnswersDeadline && parsedObject.appdates_AnswersDeadline.length > 0) {
                    setAnswersDeadlineDate(parsedObject.appdates_AnswersDeadline[0]['date']);
                }
                if (parsedObject.appdates_AnswersPosted && parsedObject.appdates_AnswersPosted.length > 0) {
                    setAnswersPostedDate(parsedObject.appdates_AnswersPosted[0]['date']);
                }
                if (parsedObject.appdates_BidClosing && parsedObject.appdates_BidClosing.length > 0) {
                    setBidClosingDate(parsedObject.appdates_BidClosing[0]['date']);
                }
                if (parsedObject.appdates_BidEvalCompVendSel && parsedObject.appdates_BidEvalCompVendSel.length > 0) {
                    setBidEvaluationDate(parsedObject.appdates_BidEvalCompVendSel[0]['date']);
                }
                if (parsedObject.appdates_Award && parsedObject.appdates_Award.length > 0) {
                    setAwardDate(parsedObject.appdates_Award[0]['date']);
                }
                if (parsedObject.appdates_EmailSentToVendors && parsedObject.appdates_EmailSentToVendors.length > 0) {
                    setEmailSentDate(parsedObject.appdates_EmailSentToVendors[0]['date']);
                }
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        };

        const getUserData = async () => {
            let data = await userAPI.GetUser();
            const parsedObject = JSON.parse(data.body);
            setUsername(parsedObject.email.split('@')[0]);
        };

        fetch470EditOptions();
        fetchForm470AppData(form470AppNum, form470AppIncrement);
        getUserData();
    }, []);

    async function saveAppData() {
        try {
            setFormSubmitted(true);
            console.log('formSubmitted = ', formSubmitted);

            // Validation
            // Check for valid dates.
            if (isNotEmptyString(dateSentToClientToCertify) && !isValidInputDate(dateSentToClientToCertify)) {
                toast.error('Invalid "Date Sent to Client to Certify".');
                return false;
            }
            if (isNotEmptyString(issueDate) && !isValidInputDate(issueDate)) {
                toast.error('Invalid "Issue Date".');
                return false;
            }
            if (isNotEmptyString(qaDateSubmitted) && !isValidInputDate(qaDateSubmitted)) {
                toast.error('Invalid "QA Date Submitted".');
                return false;
            }
            if (isNotEmptyString(qaDateNeeded) && !isValidInputDate(qaDateNeeded)) {
                toast.error('Invalid "QA Date Needed".');
                return false;
            }
            if (isNotEmptyString(qaDateAssigned) && !isValidInputDate(qaDateAssigned)) {
                toast.error('Invalid "QA Date Needed".');
                return false;
            }
            if (isNotEmptyString(qaDateCompleted) && !isValidInputDate(qaDateCompleted)) {
                toast.error('Invalid "QA Date Needed".');
                return false;
            }

            // Null out empty date strings (needs to be addressed b/c of date input field)
            let parsedDSTCTC = dateSentToClientToCertify === '' ? null : dateSentToClientToCertify;
            let parsedID = issueDate === '' ? null : issueDate;
            let parsedQADS = qaDateSubmitted === '' ? null : qaDateSubmitted;
            let parsedQADN = qaDateNeeded === '' ? null : qaDateNeeded;
            let parsedQADA = qaDateAssigned === '' ? null : qaDateAssigned;
            let parsedQADC = qaDateCompleted === '' ? null : qaDateCompleted;
            let parsedWTPreBidMtg = preBidDate === '' ? null : preBidDate;
            let parsedQuestionsDeadline = questionsDeadlineDate === '' ? null : questionsDeadlineDate;
            let parsedAnswersDeadline = answersDeadlineDate === '' ? null : answersDeadlineDate;
            let parsedAnswersPosted = answersPostedDate === '' ? null : answersPostedDate;
            let parsedBidClosing = bidClosingDate === '' ? null : bidClosingDate;
            let parsedBidEvalCompVendSel = bidEvaluationDate === '' ? null : bidEvaluationDate;
            let parsedAward = awardDate === '' ? null : awardDate;
            let parsedEmailSentToVendors = emailSentDate === '' ? null : emailSentDate;

            // Save to database
            let appdatatosave = {
                form470AppNum: form470AppNum,
                form470AppIncrement: form470AppIncrement,
                ercForm470AppStatusId: ercAppStatusId,
                applicationOwnerUserId: applicationOwnerUserId,
                taskOwnerUserId: taskOwnerUserId,
                dateSentToClientToCertify: parsedDSTCTC,
                issueDate: parsedID,
                form470AppNotes: form470AppNotes,
                qaReviewStatusId: qaReviewStatusId,
                qaReviewerUserId: qaReviewerUserId,
                qaIssues: qaIssues,
                qaNotes: qaNotes,
                qaSubmitterUserId: qaSubmitterUserId,
                qaDateSubmitted: parsedQADS,
                qaDateNeeded: parsedQADN,
                qaDateAssigned: parsedQADA,
                qaDateCompleted: parsedQADC,
                qaBidEvalStatusId: bidEvalQAStatusId,
                qaBidEvalReviewerUserId: bidEvalQAReviewerUserId,
                qaBidEvalSubmitterUserId: bidEvalQASubmitterUserId,
                qaBidEvalDateSubmitted: bidEvalQADateSubmitted,
                qaBidEvalDateNeeded: bidEvalQADateNeeded,
                qaBidEvalDateCompleted: bidEvalQADateCompleted,
                qaDarkFiberSelfProvisioned: qaDFSP,
                appDatePrebidMeeting: parsedWTPreBidMtg,
                appDateQuestionsDeadline: parsedQuestionsDeadline,
                appDateAnswersDeadline: parsedAnswersDeadline,
                appDateAnswersPosted: parsedAnswersPosted,
                appDateBidClosing: parsedBidClosing,
                appDateBidEvaluationComplete: parsedBidEvalCompVendSel,
                appDateAward: parsedAward,
                appDateEmailSentToVendors: parsedEmailSentToVendors,
            };
            console.log('appdatatosave:', appdatatosave);
            // console.log('form470AppNum:', form470AppNum);

            const saveresponse = await trackersAPI.SaveForm470TrackerAppData(
                appdatatosave,
                form470AppNum,
                form470AppIncrement
            );
            console.log('saveresponse:', saveresponse);

            if (saveresponse === false) {
                toast.error('Error saving app data.');
                return false;
            }

            toast.success('Successfully updated app data.');

            // Cleanup
            setFormSubmitted(false);

            // Return to source (list or details) page
            returnToSourcePage(saveresponse);
        } catch (error) {
            //console.error('Error saving app data: ', error);
            toast.error('Error saving app data.');
        }
        return false;
    }

    //~~~  Display functions  ~~~
    const renderDateTime = (isoDateTimeString, displayTime) => {
        // This should only be used with an ISO 8601 datetime string. `Date` does not handle "YYYY-MM-DD" correctly.
        if (isoDateTimeString) {
            try {
                let datedate = new Date(isoDateTimeString);
                let text;
                if (!displayTime) {
                    text = new Intl.DateTimeFormat('en-US').format(datedate);
                } else {
                    let dtfoptions = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    };
                    text = new Intl.DateTimeFormat('en-US', dtfoptions).format(datedate);
                }
                return <span title={datedate}>{text}</span>;
            } catch (err) {
                console.error('renderDateTime error:', err);
            }
        }
        return isoDateTimeString;
    };

    const renderMultilineHeader = (params, line1, line2, line3) => {
        return (
            <Box sx={{ lineHeight: 'initial !important', fontWeight: '500' }}>
                {line1}
                <br />
                {line2}
                {line3 && (
                    <>
                        <br />
                        {line3}
                    </>
                )}
            </Box>
        );
    };

    const displayCategory12 = (category) => {
        return category === null || category === 0 || category === '0' ? '' : category;
    };

    // Gets a "yyyy-mm-dd" string from a javascript date.
    const get_YMDString_from_JavascriptDate = (jsdate) => {
        //console.log('jsdate = ', jsdate);
        if (!jsdate) {
            return null;
        }
        if (!(jsdate instanceof Date)) {
            return null;
        }
        let offset = jsdate.getTimezoneOffset();
        let jsdate2 = new Date(jsdate.getTime() - offset * 60 * 1000); // remove offset
        let yyyymmddstring = jsdate2.toISOString().substring(0, 10); // or jsdate2.toISOString().split('T')[0];
        //console.log('yyyymmddstring = ', yyyymmddstring);
        return yyyymmddstring;
    };

    const local_t_and_tz_iso8601string = new Date().toISOString().substring(10);

    const isValidInputDate = (datestring) => {
        // console.log('datestring = ', datestring)
        if (datestring === undefined || datestring === null || datestring === '') {
            return false;
        }
        var luxondatetime = DateTime.fromFormat(datestring, 'yyyy-MM-dd');
        // console.log('luxondatetime = ', luxondatetime)
        return luxondatetime.isValid;
    };

    const isNotEmptyString = (str) => {
        return str !== null && str.length > 0;
    };

    //~~~  Navigation  ~~~
    // use after saving edited data to return back to details page
    const returnToSourcePage = (data) => {
        if (handleReturnToSourcePage) {
            handleReturnToSourcePage();
        } else {
            // Go to the Main Report page by default.
            navigate('/f470/f470-applications', {
                state: {
                    updatedRow: data,
                },
            });

            // Go to the Details page by default.
            //navigate('/apply-for-discounts/f470-applications/details/' + form470AppNum + '/' + form470AppIncrement);
        }
    };

    const cancel = () => {
        returnToSourcePage();
    };

    // const getUserDisplayName = (user) => {
    //     return user ? user.first_name + ' ' + user.last_name : '';
    // };

    const getUsersDisplayNames = (users) => {
        if (!users || users.length === 0) {
            return '';
        }
        let ary = users.map((user) => {
            return getUserDisplayName(user);
        });
        return ary.join(', ');
    };

    const getUserDisplayName = (user) => {
        return user ? user.first_name + ' ' + user.last_name : '';
    };

    const getApplicantCohortNames = (applicantCohorts) => {
        if (!applicantCohorts || applicantCohorts.length === 0) {
            return '';
        }
        return applicantCohorts
            .map((ac) => {
                return ac.applicantcohort.applicantcohort_name;
            })
            .join(', ');
    };

    const qaDFSPEditOptions = [
        <MenuItem value={''} key={''}>
            N/A
        </MenuItem>,
        <MenuItem value={'DarkFiber'} key={'DarkFiber'}>
            Dark Fiber
        </MenuItem>,
        <MenuItem value={'SelfProvisioned'} key={'SelfProvisioned'}>
            Self Provisioned
        </MenuItem>,
    ];

    const handleIssuesChange = (event, selectedValues) => {
        setQAIssues(selectedValues);
    };

    const handleAddNoteButtonClick = (setter) => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        setter((prevNotes) => `${currentDate} (${username}) - \r\n\r\n${prevNotes}`);
    };

    //~~~  Display  ~~~
    if (!form470AppNum) {
        return (
            <Box>
                <h3>Edit Form 470 Application</h3>
                <h4 sx={{ color: 'red' }}>Missing or empty Form 470 AppNum provided.</h4>
            </Box>
        );
    }

    if (!form470AppIncrement) {
        return (
            <Box>
                <h3>Edit Form 470 Application</h3>
                <h4 sx={{ color: 'red' }}>Missing or empty Form 470 app increment.</h4>
            </Box>
        );
    }

    if (!appData) {
        return null;
    }

    return (
        <Box>
            <h2 style={{ marginTop: 0, marginBottom: '1em' }}>Edit Form 470 Application</h2>
            {/* <h4>
                AppNum {form470AppNum} - {appData.nickname}
                {appData.isMinibid && <span style={{ color: 'green', marginLeft: '1em' }}>(Mini-Bid)</span>}
            </h4> */}

            <h4>
                AppNum {form470AppNum} - {appData.nickname}
                {appData.isMinibid && (
                    <span style={{ marginLeft: '1em' }}>
                        <span style={{ color: 'black' }}>(</span>
                        <span style={{ color: 'green' }}>Mini-Bid</span>
                        <span style={{ color: 'black' }}>)</span>
                    </span>
                )}
            </h4>

            <Box sx={{ width: '98%', margin: '0 auto' }}>
                <Grid container spacing={0}>
                    <Grid item xs={9}>
                        <Grid container spacing={0}>
                            {/* LEFT */}
                            <Grid item xs={6}>
                                <Box display='flex' width='100%'>
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        Client Name:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {appData.client_name}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    {appData.isMinibid === true && (
                                        <>
                                            <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                                Client BEN:
                                            </Grid>
                                            <Grid item flexGrow={1}>
                                                {appData.minibidClientBEN ? appData.minibidClientBEN : appData.ben}
                                            </Grid>
                                        </>
                                    )}
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        State:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {appData.client_state}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        Is a Client:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {appData.isAClient === true ? 'True' : 'False'}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        Primary Contact:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {getUserDisplayName(appData.client_primary_contact)}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        Secondary Contact:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {getUsersDisplayNames(appData.client_secondary_contacts)}{' '}
                                        {/* can be more than one  */}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        Applicant Cohorts:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {getApplicantCohortNames(appData.client_applicantcohorts)}{' '}
                                        {/* can be more than one  */}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        AFY Form 470 Status:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {appData.cfy_form470_afystatus_text}
                                    </Grid>
                                </Box>

                                <Box display='flex' width='100%'>
                                    {/* LEFT */} {/* END OF LEFT-SIDE DATA */}
                                    <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                        AFY Form 470 Notes:
                                    </Grid>
                                    <Grid item flexGrow={1}>
                                        {appData.cfy_form470_tracker_notes}
                                    </Grid>
                                </Box>
                            </Grid>

                            {/* RIGHT */}
                            <Grid item xs={6}>
                                <Grid container alignItems='center'>
                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Application Number:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.form470AppNum}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    {appData.isMinibid === true && (
                                        <Box display='flex' width='100%'>
                                            <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                                Mini-bid Funding Year
                                            </Grid>
                                            <Grid item flexGrow={1}>
                                                {appData.minibidFundingYear}
                                            </Grid>
                                        </Box>
                                    )}

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Application Funding Year:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.fundingYear}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Application BEN:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.ben}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Application Billed Entity Name:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.billed_entity_name}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Nickname:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.nickname}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Service Types:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.form470_sr_service_types
                                                ? appData.form470_sr_service_types.split('|').join(' | ')
                                                : ''}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Categories of Service:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {displayCategory12(appData.form470_sr_categories_of_service)}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            USAC App Status:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {appData.usac_app_status_text}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Certified Date:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {renderDateTime(appData.certified_date_time, true)}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Allowable Contract Date:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {renderDateTime(appData.allowable_contract_date, true)}
                                        </Grid>
                                    </Box>

                                    {/* RIGHT */}
                                    <Box display='flex' width='100%'>
                                        <Grid item flexShrink={0} sx={{ fontWeight: 'bold', width: '230px' }}>
                                            Last Modified Date:
                                        </Grid>
                                        <Grid item flexGrow={1}>
                                            {renderDateTime(appData.last_modified_date_time, true)}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* ------------------------------------------------------------------ Editable Fields ------------------------------------------------------------------------- */}
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='ercAppStatus-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                ERC App Status
                            </InputLabel>
                            <Select
                                id='ercAppStatus-select'
                                labelId='ercAppStatus-label'
                                label='ERC App Status'
                                // labelWidth={800}
                                value={ercAppStatusId}
                                onChange={(event) => handleERCAppStatusChange(event.target.value)}
                            >
                                {ercAppStatusEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='applicationOwner-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Application Owner
                            </InputLabel>
                            <Select
                                id='applicationOwner-select'
                                labelId='applicationOwner-label'
                                label='Application Owner'
                                // labelWidth={800}
                                value={applicationOwnerUserId}
                                onChange={(event) => setApplicationOwnerUserId(event.target.value)}
                            >
                                {applicationOwnerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='taskOwner-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                Task Owner
                            </InputLabel>
                            <Select
                                id='taskOwner-select'
                                labelId='taskOwner-label'
                                label='Task Owner'
                                // labelWidth={800}
                                value={taskOwnerUserId}
                                onChange={(event) => setTaskOwnerUserId(event.target.value)}
                            >
                                {taskOwnerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Date Sent to Client to Certify'
                                type='date'
                                value={dateSentToClientToCertify}
                                fullWidth
                                onChange={(e) => setDateSentToClientToCertify(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(dateSentToClientToCertify) &&
                                    !isValidInputDate(dateSentToClientToCertify)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(dateSentToClientToCertify) &&
                                    !isValidInputDate(dateSentToClientToCertify)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Issue Date'
                                type='date'
                                value={issueDate}
                                fullWidth
                                onChange={(e) => setIssueDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={formSubmitted && isNotEmptyString(issueDate) && !isValidInputDate(issueDate)}
                                helperText={
                                    formSubmitted && isNotEmptyString(issueDate) && !isValidInputDate(issueDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='qaDFSP-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                QA Dark Fiber / Self Provisioned
                            </InputLabel>
                            <Select
                                id='qaDFSP-select'
                                labelId='qaDFSP-label'
                                label='QA Dark Fiber / Self Provisioned'
                                // labelWidth={800}
                                value={qaDFSP}
                                onChange={(event) => setQADFSP(event.target.value)}
                            >
                                {qaDFSPEditOptions}
                            </Select>
                        </FormControl>

                        <Button
                            variant='contained'
                            size='small'
                            sx={{ backgroundColor: '#4CAF50', marginTop: 2 }}
                            onClick={() => handleAddNoteButtonClick(setForm470AppNotes)}
                        >
                            Add Note
                        </Button>
                        <FormControl fullWidth={true} margin='normal'>
                            {/* <Button
                                variant='contained'
                                size='small'
                                sx={{ backgroundColor: '#4CAF50', marginBottom: 2 }}
                                onClick={() => handleAddNoteButtonClick(setForm470AppNotes)}
                            >
                                Add Note
                            </Button> */}
                            <TextField
                                label='Form 470 App Notes'
                                value={form470AppNotes}
                                id='form470AppNotes-textfield'
                                onChange={(event) => setForm470AppNotes(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={17.45}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Pre Bid Date'
                                fullWidth
                                type='date'
                                value={preBidDate}
                                onChange={(e) => setPreBidDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={formSubmitted && isNotEmptyString(preBidDate) && !isValidInputDate(preBidDate)}
                                helperText={
                                    formSubmitted && isNotEmptyString(preBidDate) && !isValidInputDate(preBidDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Questions Deadline Date'
                                fullWidth
                                type='date'
                                value={questionsDeadlineDate}
                                onChange={(e) => setQuestionsDeadlineDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(questionsDeadlineDate) &&
                                    !isValidInputDate(questionsDeadlineDate)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(questionsDeadlineDate) &&
                                    !isValidInputDate(questionsDeadlineDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Answers Deadline Date'
                                fullWidth
                                type='date'
                                value={answersDeadlineDate}
                                onChange={(e) => setAnswersDeadlineDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(answersDeadlineDate) &&
                                    !isValidInputDate(answersDeadlineDate)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(answersDeadlineDate) &&
                                    !isValidInputDate(answersDeadlineDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Answers Posted Date'
                                fullWidth
                                type='date'
                                value={answersPostedDate}
                                onChange={(e) => setAnswersPostedDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(answersPostedDate) &&
                                    !isValidInputDate(answersPostedDate)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(answersPostedDate) &&
                                    !isValidInputDate(answersPostedDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Bid Closing Date'
                                fullWidth
                                type='date'
                                value={bidClosingDate}
                                onChange={(e) => setBidClosingDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(bidClosingDate) &&
                                    !isValidInputDate(bidClosingDate)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(bidClosingDate) &&
                                    !isValidInputDate(bidClosingDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Bid Evaluation Date'
                                fullWidth
                                type='date'
                                value={bidEvaluationDate}
                                onChange={(e) => setBidEvaluationDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvaluationDate) &&
                                    !isValidInputDate(bidEvaluationDate)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvaluationDate) &&
                                    !isValidInputDate(bidEvaluationDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Award Date'
                                fullWidth
                                type='date'
                                value={awardDate}
                                onChange={(e) => setAwardDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={formSubmitted && isNotEmptyString(awardDate) && !isValidInputDate(awardDate)}
                                helperText={
                                    formSubmitted && isNotEmptyString(awardDate) && !isValidInputDate(awardDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Email Sent Date'
                                fullWidth
                                type='date'
                                value={emailSentDate}
                                onChange={(e) => setEmailSentDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted && isNotEmptyString(emailSentDate) && !isValidInputDate(emailSentDate)
                                }
                                helperText={
                                    formSubmitted && isNotEmptyString(emailSentDate) && !isValidInputDate(emailSentDate)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='qaReviewStatus-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                QA Review Status
                            </InputLabel>
                            <Select
                                id='qaReviewStatus-select'
                                labelId='qaReviewStatus-label'
                                label='QA Review Status'
                                // labelWidth={800}
                                value={qaReviewStatusId}
                                onChange={(event) => handleQAReviewStatusChange(event.target.value)}
                            >
                                {qaReviewStatusEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='qaSubmitter-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                QA Submitter
                            </InputLabel>
                            <Select
                                id='qaSubmitter-select'
                                labelId='qaSubmitter-label'
                                label='QA Submitter'
                                // labelWidth={800}
                                value={qaSubmitterUserId}
                                onChange={(event) => setQASubmitterUserId(event.target.value)}
                            >
                                {qaSubmitterEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='QA Date Submitted'
                                fullWidth
                                type='date'
                                value={qaDateSubmitted}
                                onChange={(e) => setQADateSubmitted(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateSubmitted) &&
                                    !isValidInputDate(qaDateSubmitted)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateSubmitted) &&
                                    !isValidInputDate(qaDateSubmitted)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='QA Date Needed'
                                fullWidth
                                type='date'
                                value={qaDateNeeded}
                                onChange={(e) => setQADateNeeded(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted && isNotEmptyString(qaDateNeeded) && !isValidInputDate(qaDateNeeded)
                                }
                                helperText={
                                    formSubmitted && isNotEmptyString(qaDateNeeded) && !isValidInputDate(qaDateNeeded)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='QA Date Assigned'
                                fullWidth
                                type='date'
                                value={qaDateAssigned}
                                onChange={(e) => setQADateAssigned(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateAssigned) &&
                                    !isValidInputDate(qaDateAssigned)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateAssigned) &&
                                    !isValidInputDate(qaDateAssigned)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel id='qaReviewer-label' shrink={true} sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}>
                                QA Reviewer
                            </InputLabel>
                            <Select
                                id='qaReviewer-select'
                                labelId='qaReviewer-label'
                                label='QA Reviewer'
                                // labelWidth={800}
                                value={qaReviewerUserId}
                                onChange={(event) => setQAReviewerUserId(event.target.value)}
                            >
                                {qaReviewerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <Button
                            variant='contained'
                            size='small'
                            sx={{ backgroundColor: '#4CAF50', marginTop: 2 }}
                            onClick={() => handleAddNoteButtonClick(setQANotes)}
                        >
                            Add Note
                        </Button>
                        <FormControl fullWidth={true} margin='normal'>
                            {/* <Button
                                variant='contained'
                                size='small'
                                sx={{ backgroundColor: '#4CAF50', marginBottom: 2 }}
                                onClick={() => handleAddNoteButtonClick(setQANotes)}
                            >
                                Add Note
                            </Button> */}
                            <TextField
                                label='QA Reviewer Notes'
                                value={qaNotes}
                                id='qaNotes-textfield'
                                onChange={(event) => setQANotes(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={10}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='QA Date Completed'
                                fullWidth
                                type='date'
                                value={qaDateCompleted}
                                onChange={(e) => setQADateCompleted(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateCompleted) &&
                                    !isValidInputDate(qaDateCompleted)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(qaDateCompleted) &&
                                    !isValidInputDate(qaDateCompleted)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth margin='dense' size='small'>
                            <MultiAutoComplete
                                id='qa-issues'
                                selectedOptions={qaIssues}
                                options={qaIssuesEditOptions}
                                handleSelectChange={handleIssuesChange}
                                label='QA Issues'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        {/* Bid Eval QA Columns */}
                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='bidEvalQAStatus-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Bid Eval QA Status
                            </InputLabel>
                            <Select
                                id='bidEvalQAStatus-select'
                                labelId='bidEvalQAStatus-label'
                                label='Bid Eval QA Status'
                                // labelWidth={800}
                                value={bidEvalQAStatusId}
                                onChange={(event) => setBidEvalQAStatusId(event.target.value)}
                            >
                                {bidEvalQAStatusEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='bidEvalQASubmitter-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Bid Eval QA Submitter
                            </InputLabel>
                            <Select
                                id='bidEvalQASubmitter-select'
                                labelId='bidEvalQASubmitter-label'
                                label='Bid Eval QA Submitter'
                                // labelWidth={800}
                                value={bidEvalQASubmitterUserId}
                                onChange={(event) => setBidEvalQASubmitterUserId(event.target.value)}
                            >
                                {bidEvalQASubmitterEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Bid Eval QA Date Submitted'
                                fullWidth
                                type='date'
                                value={bidEvalQADateSubmitted}
                                onChange={(e) => setBidEvalQADateSubmitted(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvalQADateSubmitted) &&
                                    !isValidInputDate(bidEvalQADateSubmitted)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvalQADateSubmitted) &&
                                    !isValidInputDate(bidEvalQADateSubmitted)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Bid Eval QA Date Needed'
                                fullWidth
                                type='date'
                                value={bidEvalQADateNeeded}
                                onChange={(e) => setBidEvalQADateNeeded(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvalQADateNeeded) &&
                                    !isValidInputDate(bidEvalQADateNeeded)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvalQADateNeeded) &&
                                    !isValidInputDate(bidEvalQADateNeeded)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <InputLabel
                                id='bidEvalQAReviewer-label'
                                shrink={true}
                                sx={{ bgcolor: '#FFFFFF', pl: 1, pr: 1 }}
                            >
                                Bid Eval QA Reviewer
                            </InputLabel>
                            <Select
                                id='bidEvalQAReviewer-select'
                                labelId='bidEvalQAReviewer-label'
                                label='Bid Eval QA Reviewer'
                                // labelWidth={800}
                                value={bidEvalQAReviewerUserId}
                                onChange={(event) => setBidEvalQAReviewerUserId(event.target.value)}
                            >
                                {bidEvalQAReviewerEditOptions.map((row) => {
                                    return (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.text}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} margin='normal'>
                            <TextField
                                label='Bid Eval QA Date Completed'
                                fullWidth
                                type='date'
                                value={bidEvalQADateCompleted}
                                onChange={(e) => setBidEvalQADateCompleted(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvalQADateCompleted) &&
                                    !isValidInputDate(bidEvalQADateCompleted)
                                }
                                helperText={
                                    formSubmitted &&
                                    isNotEmptyString(bidEvalQADateCompleted) &&
                                    !isValidInputDate(bidEvalQADateCompleted)
                                        ? 'Invalid date'
                                        : ''
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{ m: 4, textAlign: 'center' }}>
                    <Button
                        sx={{ m: 1 }}
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => saveAppData()}
                    >
                        Save
                    </Button>
                    <Button sx={{ m: 1 }} variant='contained' color='secondary' size='small' onClick={() => cancel()}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
