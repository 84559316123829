import * as React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';

// MUI
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

// Employee Portal
import { useAuthContext } from './useAuthContext.js';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import UserManagement from './pages/UserManagement';
import ProfilePage from './pages/ProfilePage';
import UserSettings from './pages/UserSettings';
import ClientList from './pages/ClientList';
import ClientDetailsPage from './pages/ClientDetailsPage';
import ClientContactSearchReportPage from './pages/ClientContactSearchReportPage.js';
import ClientConsultantApplicantListPage from './pages/ClientConsultantApplicantListPage.js';
import ClientFundingSummaryPage from './pages/ClientFundingSummaryPage';
import ClientFundingYearDetailsPage from './pages/ClientFundingYearDetailsPage';
import ClientFundingYearFormDetailsPage from './pages/ClientFundingYearFormDetailsPage.js';
import ClientFundingYearFormDetailsAllECFPage from './pages/ClientFundingYearFormDetailsAllECFPage.js';
import ClientFundingHistoryDetailsECFPage from './pages/ClientFundingHistoryDetailsECFPage.js';
import ClientEditPage from './pages/ClientEditPage';
import Services from './pages/Services';
import ClientServices from './pages/ClientServices';
import F470ApplyForDiscounts from './pages/F470ApplyForDiscounts';
import F471ApplyForDiscounts from './pages/F471ApplyForDiscounts';
// import CompetitiveBidding from './pages/CompetitiveBidding';
// import PostCommitment from './pages/PostCommitment';
// import PreparedReporting from './pages/PreparedReporting';
// import DynamicReporting from './pages/DynamicReporting';
import LandingPage from './pages/LandingPage';
import Cohorts from './pages/Cohorts';
import Form470Applications from './pages/F470Applications';
import F471Applications from './pages/F471Applications';
import F470TAppDetailsPage from './pages/F470TAppDetails';
import F471TAppDetailsPage from './pages/F471TAppDetails';
import F470TAppEditPage from './pages/F470TAppEdit';
import F471TAppEditPage from './pages/F471TAppEdit';
import F471Stats from './pages/F471Stats';
import F471Applicants from './pages/F471Applicants';
import UsacDataRetrievalDatetimes from './pages/UsacDataRetrievalDatetimes';
import SystemSettings from './pages/SystemSettings';
import UsacDataStats from './pages/UsacDataStats';
import GlobalSearchResult from './pages/GlobalSearchResult';
import Notifications from './pages/Notifications';
import GeneralEmbeddedDashboard from './pages/GeneralEmbeddedDashboard';
import DeadlineReport from './pages/DeadlineReport';

const drawerWidth = 240;
const standardtheme = createTheme({
    palette: {
        primary: {
            main: '#19638B',
        },
        focus: {
            main: '#248EC7',
        },
        secondary: {
            main: '#F6F6F6',
        },
        dark: {
            main: '#707070',
            contrastText: '#FFFFFF',
        },
        accent: {
            main: '#93CD40',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        color: '#413c40',
        h1: {
            color: '#19638B',
            fontSize: '2rem',
            fontWeight: 600,
        },
        h2: {
            color: '#011340',
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        h3: {
            color: '#011340',
            fontSize: '1.375rem',
            fontWeight: 300,
        },
        h4: {
            color: '#333333',
            fontSize: '1.125rem',
            fontWeight: 500,
        },
        h5: {
            color: '#333333',
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        h6: {
            color: '#333333',
            fontSize: '0.625rem',
            fontWeight: 500,
        },
        p: {
            color: '#413C40',
            fontSize: '1rem',
            fontWeight: 400,
        },
        a: {
            color: '#028BFF',
            fontSize: '1rem',
            fontWeight: 400,
        },
    },
});

function App() {
    LicenseInfo.setLicenseKey(
        'e8a9b99da68b790a2534e3d1ed17563bTz04MTU2OCxFPTE3MzYwMDkxNTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
    );

    return (
        <>
            <ThemeProvider theme={standardtheme}>
                <CssBaseline />

                <BrowserRouter>
                    <Routes>
                        <Route path='/login' element={<LoginLayout />} />

                        <Route element={<NormalLayout />}>
                            <Route exact path='/' element={<LandingPage />} />
                            <Route path='/profile' element={<ProfilePage />} />
                            <Route path='/user-settings' element={<UserSettings />} />
                            <Route path='/notifications' element={<Notifications />} />
                            <Route path='/global-search' element={<GlobalSearchResult />} />
                            <Route path='/embedded-dashboard' element={<GeneralEmbeddedDashboard />} />

                            {/* "Client List" */}
                            <Route path='/client-list' element={<ClientList />} />
                            <Route path='/client-list/details/:clientId' element={<ClientDetailsPage />} />
                            <Route path='/client-list/edit/:clientId' element={<ClientEditPage />} />
                            <Route path='/client-list/client-services/:clientId' element={<ClientServices />} />
                            <Route path='/client-list/services' element={<Services />} />
                            <Route path='/client-list/cohorts' element={<Cohorts />} />

                            {/* Form 470 Tracker */}
                            <Route path='/f470' element={<F470ApplyForDiscounts />} />
                            <Route path='/f470/f470-applications' element={<Form470Applications />} />
                            <Route
                                path='/f470/f470-applications/details/:form470AppNum/:form470AppIncrement'
                                element={<F470TAppDetailsPage />}
                            />
                            <Route
                                path='/f470/f470-applications/edit/:form470AppNum/:form470AppIncrement'
                                element={<F470TAppEditPage />}
                            />

                            {/* Form 471 Tracker */}
                            <Route path='/f471' element={<F471ApplyForDiscounts />} />
                            <Route path='/f471/f471-applications' element={<F471Applications />} />
                            <Route path='/f471/f471-applications/:ben' element={<F471Applications />} />
                            <Route
                                path='/f471/f471-applications/details/:form471AppNum'
                                element={<F471TAppDetailsPage />}
                            />
                            <Route path='/f471/f471-applications/edit/:form471AppNum' element={<F471TAppEditPage />} />
                            <Route path='/f471/f471-stats' element={<F471Stats />} />
                            <Route path='/usacdataretrievaldatetimes' element={<UsacDataRetrievalDatetimes />} />

                            {/* Post-Commitment Tracker */}

                            {/* Reports */}
                            <Route path='/reports' element={<LandingPage />} />
                            <Route path='/reports/deadline-report' element={<DeadlineReport />} />
                            <Route path='/reports/applicants' element={<F471Applicants />} />

                            {/* admin pages */}
                            <Route path='/manage-users' element={<UserManagement />} />
                            <Route path='/system-settings' element={<SystemSettings />} />
                            <Route path='/system-settings/usac-data-stats' element={<UsacDataStats />} />
                        </Route>

                        <Route element={<PlainLayout />}>
                            <Route
                                path='/client-list/funding-summary/:kyList/:searchType'
                                element={<ClientFundingSummaryPage />}
                            />
                            <Route
                                path='/client-list/funding-summary/:kyList/:spin/:searchType'
                                element={<ClientFundingSummaryPage />}
                            />
                            <Route
                                path='/client-list/contact-search-report/:kyList/:searchType'
                                element={<ClientContactSearchReportPage />}
                            />
                            <Route
                                path='/client-list/consulting-firms-applicant-report/:fundingYear/:crn'
                                element={<ClientConsultantApplicantListPage />}
                            />
                            <Route
                                path='/client-list/form-details/:kyList/:frnNumber/:fundingYear/:searchType'
                                element={<ClientFundingYearFormDetailsPage />}
                            />
                            <Route
                                path='/client-list/form-details-all-ecf/:kyList/:frnNumber/:searchType' // Working on this one
                                element={<ClientFundingYearFormDetailsAllECFPage />}
                            />
                            <Route
                                path='/client-list/funding-year-details/:kyList/:fundingYear/:searchType' // Base FYD
                                element={<ClientFundingYearDetailsPage />}
                            />
                            <Route
                                path='/client-list/funding-year-details/:kyList/:fundingYear/:state/:searchType'
                                element={<ClientFundingYearDetailsPage />}
                            />
                            <Route
                                path='/client-list/funding-year-details/:kyList/:fundingYear/:f471appnum/:state/:searchType'
                                element={<ClientFundingYearDetailsPage />}
                            />
                            <Route
                                path='/client-list/funding-history-details/:kyList/:program/:searchType'
                                element={<ClientFundingHistoryDetailsECFPage />}
                            />
                            <Route
                                path='/client-list/funding-history-details/:kyList/:ecfNum/:program/:searchType'
                                element={<ClientFundingHistoryDetailsECFPage />}
                            />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawer' })(
    ({ theme, open, drawer }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawer && { marginLeft: `-${drawerWidth}px` }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function LoginLayout() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1, padding: '4em' }}>
                {/* Note that there is no '<main>' created here because Login component is creating it. */}
                <Login />
            </Box>
        </Box>
    );
}

function NormalLayout() {
    const [open, setOpen] = React.useState(false);
    const auth = useAuthContext();
    const location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    if (auth.loading) {
        // For intial page load - if auth is still loading, return an empty container
        return <div></div>;
    }

    if (!auth.isSignedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    //  <div>
    //      <header>  (from Navbar)
    //      <main>    (from Main)
    //  </div>
    return (
        <Box sx={{ display: 'flex' }}>
            <Navbar open={open} handleOpen={handleDrawerOpen} handleClose={handleDrawerClose} />
            <Main open={open} drawer={auth.isSignedIn}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={auth.loading}>
                    <CircularProgress />
                </Backdrop>
                <DrawerHeader />
                <Outlet />
            </Main>
        </Box>
    );
}

function PlainLayout() {
    const auth = useAuthContext();
    const location = useLocation();

    if (auth.loading) {
        // For intial page load - if auth is still loading, return an empty container
        return <div></div>;
    }

    if (!auth.isSignedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return (
        <Box component='main' sx={{ padding: '18px' }}>
            <Outlet />
        </Box>
    );
}

export default App;
