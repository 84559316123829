import React from 'react';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import EngineeringIcon from '@mui/icons-material/Engineering';

// get_ECF_data_for_funding_analysis
export default function ClientContactSearchReport(props) {
    const clientAPI = props.clientAPI;
    const clientId = props.clientId;
    const kyList = props.kyList;
    const searchType = props.searchType;
    const [clientContactData, setClientContactData] = React.useState(null);
    const [specificClientContactObj, setSpecificClientContactObj] = React.useState(null);
    const [allClientContactObj, setAllClientContactObj] = React.useState(null);

    console.log('[ClientContactSearchReport] clientId, kyList, searchType:', clientId, kyList, searchType);

    React.useEffect(() => {
        const getClientContactData = async (kyList) => {
            const clientContactDataVar = await clientAPI.GetClientContactData(kyList);
            setClientContactData(clientContactDataVar);
            console.log(
                '[ClientContactSearchReport][useEffect][getClientContactData] clientContactDataVar:',
                clientContactDataVar
            );
            return clientContactDataVar;
        };

        // Trying to get the first available contact info
        const getClientContactDataObj = (allClientContactData, getAllFlag = false) => {
            // Get all the keys of the object
            const keys = Object.keys(allClientContactData);

            if (keys.length === 0) {
                return null;
            }

            const allDataArray = [];
            // Loop through the keys in their current order (most recent first)
            for (let i = 0; i < keys.length; i++) {
                const dataArray = allClientContactData[keys[i]];

                // If the array is not empty, return the first object
                if (dataArray && dataArray.length > 0) {
                    console.log(
                        '[ClientContactSearchReport][useEffect][getClientContactDataObj] specificClientContactDataObj:',
                        dataArray[0]
                    );
                    if (getAllFlag === true) {
                        allDataArray.push(dataArray[0]);
                    } else {
                        return dataArray[0]; // Return the first non-empty object of the get_contact_data() query. clientContactDataTemp/allClientContactData has every year with the same infornmation, we're just limiting it
                    }
                }
            }
            return allDataArray; // return null;
        };

        const getAllData = async () => {
            const clientContactDataTemp = await getClientContactData(kyList);
            if (!clientContactDataTemp) return;
            setSpecificClientContactObj(getClientContactDataObj(clientContactDataTemp, false));
            setAllClientContactObj(getClientContactDataObj(clientContactDataTemp, true));
            console.log('TEST:', allClientContactObj); // null because state isn't updated immediately
        };

        getAllData();
    }, [clientAPI, kyList, searchType]);

    // Use an effect to
    // React.useEffect(() => {
    //     if (allClientContactObj) {
    //         console.log('Updated allClientContactObj:', allClientContactObj); // log the updated state after it changes; state changes aren't immediate
    //     }
    // }, [allClientContactObj]);

    if (!clientContactData || !clientContactData || !specificClientContactObj) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>{`Gathering data for E-Rate Contact Search Results...`}</em>
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            {/* Header above the column names */}
            <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                {`E-Rate Contact Search Results `}
            </h4>

            {/* Column Names - Contact Person, Contact Person Info, Applicant Info */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#f5f5f5',
                    padding: '4px 8px',
                    marginBottom: '10px',
                }}
            >
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>Contact Person</Box>
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>Contact Person Information</Box>
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>Applicant Information</Box>
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}></Box>
            </Box>

            {/* Box that serves as the *wrapper* of the first row underneath the column headers (Contact Person, Contact Person Info, Applicant Info) */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Vertical stacking for rows
                    padding: '4px 8px',
                }}
            >
                {/* Defining the first ROW of Data underneath the column headers - Contact Person */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '10px',
                    }}
                >
                    {/* Cell 1 - Contact Person */}
                    <Box sx={{ flex: 1, padding: '4px 2px', fontSize: '13px' }}>
                        {specificClientContactObj.contactname}
                    </Box>

                    {/* Cell 2 - Contact Person Information */}
                    <Box sx={{ flex: 1, padding: '4px 8px', fontSize: '13px' }}>
                        <div>{specificClientContactObj.contactaddress1}</div>
                        <div>{`${specificClientContactObj.contactcity}, ${specificClientContactObj.contactstate} ${specificClientContactObj.contactzip}`}</div>
                        <div>{`Phone: ${specificClientContactObj.contactphone}`}</div>
                        <div>
                            Email:
                            <Link
                                href={`mailto:${specificClientContactObj.contactemail}`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 0.5,
                                }}
                            >
                                {specificClientContactObj.contactemail}
                            </Link>
                        </div>
                    </Box>

                    {/* Cell 3 - Applicant Info */}
                    <Box sx={{ flex: 1, padding: '4px 8px', fontSize: '13px' }}>
                        <div>{specificClientContactObj.billedentityname}</div>
                        <div>{specificClientContactObj.billedentityaddress1}</div>
                        <div>{`${specificClientContactObj.billedentitycity}, ${specificClientContactObj.billedentitystate} ${specificClientContactObj.billedentityzipcode}`}</div>

                        <div
                            style={{
                                marginTop: 10,
                            }}
                        >
                            BEN:
                            <Link
                                href={`/client-list/funding-summary/${clientId}/${kyList}/entNum`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'underline',
                                    fontWeight: 'normal',
                                    marginLeft: 0.5,
                                }}
                            >
                                {kyList}
                            </Link>
                        </div>
                        <br />
                        <div>
                            {allClientContactObj &&
                            Array.isArray(allClientContactObj) &&
                            allClientContactObj.length > 0 ? (
                                allClientContactObj.map((client, index) => (
                                    <p skey={index} style={{ marginTop: 0, marginBottom: 0 }}>
                                        Consultant {client.fundingyear}:{' '}
                                        <Link
                                            href={`/client-list/consulting-firms-applicant-report/${client.fundingyear}/${client.crn}`}
                                            rel='noreferrer'
                                            sx={{
                                                textDecoration: 'none',
                                                fontWeight: 'normal',
                                                marginLeft: 1,
                                                fontSize: '11',
                                                margin: '0',
                                            }}
                                        >
                                            {client.consultingfirmsname}
                                        </Link>
                                    </p>
                                ))
                            ) : (
                                <p>No data available</p> // Optionally, display a fallback message if the list is empty or null
                            )}
                        </div>
                    </Box>
                    <Box sx={{ flex: 1, padding: '4px 8px', fontSize: '13px' }}>
                        <div>Funding Year: {specificClientContactObj.fundingyear}</div>
                    </Box>
                </Box>
                {/* Here is where you would start another row */}
            </Box>
        </Box>
    );
}
