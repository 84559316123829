import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import ClientListTableWithSearch from '../components/ClientListTableWithSearch.js';
import ClientCreateEdit from '../components/ClientCreateEdit.js';
import ClientDetails from '../components/ClientDetails.js';
import ClientFundingSummary from '../components/ClientFundingSummary.js';
import ClientFundingYearDetails from '../components/ClientFundingYearDetails.js';
import ClientAPI from '../api/ClientAPI.js';
import UserAPI from '../api/UserAPI.js';

export default function ClientList() {
    const clientAPI = new ClientAPI();
    const userAPI = new UserAPI();

    let navigate = useNavigate();

    const [displayMode, setDisplayMode] = React.useState('list');
    const [clientId, setClientId] = React.useState(null);

    const handleCreateButtonClick = () => {
        setClientId(null);
        setDisplayMode('create');
    };

    const handleDetailsButtonClick = (clientId) => {
        navigate('details/' + clientId);
    };

    const handleFundingSummaryButtonClick = (kyList) => {
        // navigate('funding-summary/' + clientId + '/' + bensList);

        const url = `/client-list/funding-summary/${kyList}/entNum`; // TODO: figure out the typ management
        window.open(url, '_blank');
    };

    const handleEditButtonClick = (clientId) => {
        navigate('edit/' + clientId);
    };

    const handleEditClientServicesButtonClick = (clientId) => {
        navigate('client-services/' + clientId);
    };

    if (displayMode === 'create') {
        return <ClientCreateEdit mode='create' clientAPI={clientAPI} userAPI={userAPI} sourcePageName='Client List' />;
    } else if (displayMode === 'edit') {
        return (
            <ClientCreateEdit
                mode='edit'
                clientId={clientId}
                clientAPI={clientAPI}
                userAPI={userAPI}
                sourcePageName='Client List'
            />
        );
    } else if (displayMode === 'details') {
        return (
            <ClientDetails clientId={clientId} clientAPI={clientAPI} userAPI={userAPI} sourcePageName='Client List' />
        );
    } else if (displayMode === 'funding-summary') {
        return (
            <ClientFundingSummary
                clientId={clientId}
                clientAPI={clientAPI}
                userAPI={userAPI}
                sourcePageName='Funding Summary'
            />
        );
    } else if (displayMode === 'funding-year-details') {
        return (
            <ClientFundingYearDetails
                clientId={clientId}
                clientAPI={clientAPI}
                userAPI={userAPI}
                sourcePageName='Funding Year Details'
            />
        );
    } else {
        return (
            <>
                <Typography variant='h2' sx={{ marginTop: '0px' }}>
                    Client List
                </Typography>

                <ClientListTableWithSearch
                    clientAPI={clientAPI}
                    userAPI={userAPI}
                    handleDetailsButtonClick={handleDetailsButtonClick}
                    handleFundingSummaryButtonClick={handleFundingSummaryButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleEditClientServicesButtonClick={handleEditClientServicesButtonClick}
                    handleCreateButtonClick={handleCreateButtonClick}
                />
            </>
        );
    }
}
