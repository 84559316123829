import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import logo from '../media/ERateCentralLogo.png';
import ClientLogoAndHeaders from './ClientLogoAndHeaders.js';

const CACHE_INCREMENT = 1;
const CLIENT_CACHE_NAME = 'ced-client-funding-history-details-' + CACHE_INCREMENT;

// This is the page where the link to the ECFXXXXX FRN number is on
export default function ClientFundingHistoryDetailsECF(props) {
    const clientAPI = props.clientAPI;
    const kyList = props.kyList;
    const ecfNum = props.ecfNum;
    const program = props.program;
    const searchType = props.searchType;
    const applicantDataKey = `/ClientList/funding-history-details/client-data/${kyList}/${searchType}`;
    const contactDataKey = `/ClientList/funding-history-details/contact-data/${kyList}/${searchType}`;
    const fundingDataKey = `/ClientList/funding-history-details/${kyList}/${searchType}`;
    const [applicantData, setApplicantData] = React.useState([]);
    const [clientContactData, setClientContactData] = React.useState(null);
    const [specificClientContactObj, setSpecificClientContactObj] = React.useState(null);
    const [serviceProviderDisplayName, setServiceProviderDisplayName] = React.useState('');
    const [ecfData, setECFData] = React.useState(null);

    React.useEffect(() => {
        const cacheData = async (key, data) => {
            try {
                if (data) {
                    let APICache = await caches.open(CLIENT_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    await APICache.put(key, new Response(JSON.stringify(data), headersOptions));
                    console.log(`[ClientFundingHistoryDetailsECF][useEffect][cacheData] Data cached under key: ${key}`);
                }
            } catch (error) {
                console.error(
                    `[ClientFundingHistoryDetailsECF][useEffect][cacheData] Error caching data under key: ${key}`,
                    error
                );
            }
        };

        const checkCacheForPreviousData = async (applicantKey, contactKey, formKey) => {
            let APICache = await caches.open(CLIENT_CACHE_NAME);

            try {
                // Check for cached Client data
                const applicantResponse = await APICache.match(applicantKey);
                console.log(
                    '1) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] applicantResponse:',
                    applicantResponse
                );

                if (applicantResponse !== undefined) {
                    const cachedApplicantData = await applicantResponse.json();
                    setApplicantData(cachedApplicantData);
                    console.log(
                        '2) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] cachedApplicantData (cache found):',
                        cachedApplicantData
                    );
                }

                // Check for cached Contact data
                const contactResponse = await APICache.match(contactKey);
                console.log(
                    '3) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] contactResponse:',
                    contactResponse
                );

                if (contactResponse !== undefined) {
                    const cachedContactData = await contactResponse.json();
                    setClientContactData(cachedContactData);
                    setSpecificClientContactObj(getClientContactDataObj(cachedContactData));

                    console.log(
                        '4) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] cachedContactData (cache found):',
                        cachedContactData
                    );
                }

                // Check for cached ecf data
                const ecfResponse = await APICache.match(formKey);
                console.log(
                    '5) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] ecfResponse:',
                    ecfResponse
                );

                if (ecfResponse !== undefined) {
                    const cachedECFData = await ecfResponse.json();
                    setECFData(cachedECFData);
                    console.log(
                        '6) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] cachedECFData (cache found):',
                        cachedECFData
                    );
                }

                // applicantResponse !== undefined ||
                if (contactResponse !== undefined || ecfResponse !== undefined) {
                    console.log(
                        '7) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] didWeUseCache:',
                        true
                    );
                } else {
                    console.log(
                        '7) [ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] didWeUseCache:',
                        false
                    );
                }
            } catch (error) {
                console.error(
                    `[ClientFundingHistoryDetailsECF][useEffect][checkCacheForPreviousData] Error checking cache for key: ${formKey}`,
                    error
                );
            }
        };

        const getApplicantBasicInfo = async (searchType, kyList) => {
            try {
                const applicantdata = await clientAPI.GetApplicantBasicInfo(searchType, kyList);
                if (applicantdata) {
                    setApplicantData(applicantdata[0]);
                    console.log(
                        '8) [ClientFundingHistoryDetailsECF][useEffect][getApplicantBasicInfo] applicantdata:',
                        applicantdata
                    );
                    return applicantdata;
                }
            } catch (error) {
                console.error(
                    '8) [ClientFundingHistoryDetailsECF][useEffect][getApplicantBasicInfo] Error fetching applicantdata:',
                    error
                );
            }
            return null;
        };

        const getClientContactData = async (kyList) => {
            try {
                const clientContactDataVar = await clientAPI.GetClientContactDataOriginal(kyList);
                setClientContactData(clientContactDataVar);
                console.log(
                    '9) [ClientFundingHistoryDetailsECF][useEffect][getClientContactData] clientContactDataVar:',
                    clientContactDataVar
                );
                return clientContactDataVar;
            } catch (error) {
                console.error(
                    '9) [ClientFundingHistoryDetailsECF][useEffect][getClientData] Error fetching contactdata:',
                    error
                );
            }
            return null;
        };

        // UPDATED
        const getECFData = async (searchType, kyList, state, program) => {
            try {
                const ecfdataVar = await clientAPI.GetClientECFData(searchType, kyList, state, program);
                setECFData(ecfdataVar);
                console.log('10) [ClientFundingHistoryDetailsECF][useEffect][getECFData] ecfdataVar:', ecfdataVar);
                return ecfdataVar;
            } catch (error) {
                console.error(
                    '10) [ClientFundingHistoryDetailsECF][useEffect][getECFData] Error fetching form data:',
                    error
                );
            }
            return null;
        };

        const getServiceProviderName = (allECFData) => {
            const extractFromECF = (data) => {
                if (!data || Object.keys(data).length === 0) return null;

                let dataArray = Object.values(data);
                dataArray.sort((a, b) => b.filingwindow - a.filingwindow);

                for (const obj of dataArray) {
                    if (obj && obj.serviceprovidername) {
                        return obj.serviceprovidername;
                    }
                }

                return null;
            };

            // Try to get serviceprovidername from ECF data first
            const serviceProviderNameECF = extractFromECF(allECFData);
            return serviceProviderNameECF || 'N/A';
        };

        // Get the first available contact info
        const getClientContactDataObj = (allClientContactData) => {
            // Get all the keys of the object
            const keys = Object.keys(allClientContactData);

            if (keys.length === 0) {
                return null;
            }

            // Loop through the keys in their current order (most recent first)
            for (let i = 0; i < keys.length; i++) {
                const dataArray = allClientContactData[keys[i]];

                // If the array is not empty, return the first object
                if (dataArray && dataArray.length > 0) {
                    console.log(
                        '[ClientFundingHistoryDetailsECF][useEffect][getClientContactDataObj] specificClientContactDataObj:',
                        dataArray[0]
                    );
                    return dataArray[0]; // Return the first non-empty object
                }
            }

            return [];
        };

        const fetchAndCacheData = async () => {
            try {
                // // Fetch and cache applicant data
                // const applicantDataTemp = await getApplicantBasicInfo(searchType, kyList);
                // if (applicantDataTemp) {
                //     await cacheData(applicantDataKey, applicantDataTemp[0]);
                // }

                // const contactDataTemp = await getClientContactData(kyList);
                // if (contactDataTemp) {
                //     await cacheData(contactDataKey, contactDataTemp);
                //     setSpecificClientContactObj(getClientContactDataObj(contactDataTemp));
                // }

                // UPDATED
                // Fetch and cache ECF data
                const ecfDataTemp = await getECFData(searchType, ecfNum ? ecfNum : kyList, null, program);
                if (ecfDataTemp) {
                    await cacheData(fundingDataKey, ecfDataTemp);
                    setServiceProviderDisplayName(getServiceProviderName(ecfDataTemp));

                    const contactDataTemp = await getClientContactData(ecfDataTemp[0].billedentitynumber);
                    if (contactDataTemp) {
                        await cacheData(contactDataKey, contactDataTemp);
                        setSpecificClientContactObj(getClientContactDataObj(contactDataTemp));
                    }

                    // Fetch and cache applicant data
                    const applicantDataTemp = await getApplicantBasicInfo(
                        searchType,
                        ecfDataTemp[0].billedentitynumber
                    );
                    if (applicantDataTemp) {
                        await cacheData(applicantDataKey, applicantDataTemp[0]);
                    }
                }
            } catch (error) {
                console.error('[ClientFundingHistoryDetailsECF][fetchAndCacheData] Error:', error);
            }
        };

        const getAllData = async () => {
            try {
                await checkCacheForPreviousData(applicantDataKey, contactDataKey, fundingDataKey);
                await fetchAndCacheData();
            } catch (error) {
                console.error(
                    '[ClientFundingHistoryDetailsECF][useEffect][getAllData] Error fetching ecf data:',
                    error
                );
            }
        };

        getAllData();
    }, [clientAPI, applicantDataKey, contactDataKey, fundingDataKey, kyList, program, searchType, ecfNum]);

    // Don't display anything until we have data.
    if (!applicantData || !clientContactData || !specificClientContactObj || !ecfData) {
        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                            <img
                                height='100px'
                                src={logo}
                                alt='Logo'
                                style={{ cursor: 'pointer', marginTop: '-18.5px' }}
                            />
                        </Link>
                        <Link
                            sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%' }}
                            href='https://e-ratecentral.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {'https://e-ratecentral.com'}
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                    <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                        <em>Gathering data for Emergency Connectivity Fund Summary Chart...</em>
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            {searchType === 'spin' ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                            <img
                                height='100px'
                                src={logo}
                                alt='Logo'
                                style={{ cursor: 'pointer', marginTop: '-18.5px' }}
                            />
                        </Link>
                        <Link
                            sx={{ fontSize: '0.875rem', marginRight: '38%', marginTop: '-5%' }}
                            href='https://e-ratecentral.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {'https://e-ratecentral.com'}
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '0.875rem',
                            flex: 1,
                            marginRight: '10%',
                        }}
                    >
                        <Box sx={{ width: 'fit-content', textAlign: 'left' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ margin: 0 }}>
                                    Service Provider:{' '}
                                    <span style={{ fontWeight: 'normal' }}>{serviceProviderDisplayName}</span>
                                </h3>
                            </Box>
                            <Box sx={{ marginTop: 0 }}>
                                <h3 style={{ margin: 0 }}>
                                    SPIN{kyList.includes(',') ? 's' : ''}:{' '}
                                    <span style={{ fontWeight: 'normal' }}>{kyList}</span>
                                </h3>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <ClientLogoAndHeaders
                    searchType={searchType}
                    applicantData={applicantData}
                    kyList={kyList}
                    specificClientContactObj={specificClientContactObj}
                />
            )}
            <Box sx={{ flexBasis: '700px' }}>
                <ClientFundingHistoryDetailsECFSection
                    kyList={kyList}
                    searchType={searchType}
                    applicantData={applicantData}
                    ecfData={ecfData}
                />
            </Box>
        </Box>
    );
}

function ClientFundingHistoryDetailsECFSection({ kyList, searchType, applicantData, ecfData }) {
    const [ecfOrder, setECFOrder] = React.useState('asc');
    const [ecfOrderBy, setECFOrderBy] = React.useState('');

    const ecfHeaders = [
        { id: 'applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'fundingrequestnickname', label: 'FRN Nickname' },
        { id: 'serviceprovidername', label: 'Service Provider' },
        { id: 'spin', label: 'SPIN' },
        { id: 'servicetype', label: 'Service' },
        { id: 'originalrequest', label: 'Original Request' },
        { id: 'committedrequest', label: 'Current Commitment' },
        { id: 'disbursement', label: 'Disbursed' },
        { id: 'ecfUtilized', label: 'Util %' }, // If committed > 0 and disbursed > 0 = disbursed / committed. if disbursed < 0, just 0%. If committed < 0, just 0%
        { id: 'invoicingmethod', label: 'Invoice Mode' },
        { id: 'fcdldate', label: 'FCDL Date' },
        { id: 'filingwindow', label: 'ECF Window' },
    ];

    const ecfColumnStyles = {
        applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        fundingrequestnickname: { minWidth: '150px', maxWidth: '300px' },
        billedentitynumber: { minWidth: '150px', maxWidth: '300px' },
        // serviceprovidername: { minWidth: '150px', maxWidth: '300px' },
        serviceprovidername: { minWidth: '200px', maxWidth: '200px' },
        servicetype: { minWidth: '80px', maxWidth: '150px' },
        originalrequest: { minWidth: '100px', maxWidth: '200px' },
        committedrequest: { minWidth: '100px', maxWidth: '200px' },
        disbursement: { minWidth: '100px', maxWidth: '200px' },
        ecfUtilized: { minWidth: '80px', maxWidth: '150px' },
        invoicingmethod: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
        filingwindow: { minWidth: '80px', maxWidth: '150px' },
    };

    const f471FundingDetailsHeaders = [
        { id: 'f471applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'fundingrequestnickname', label: 'FRN Nickname' },
        { id: 'serviceprovidername', label: 'Service Provider' },
        { id: 'spin', label: 'SPIN' },
        { id: 'service', label: 'Service' },
        { id: 'originalrequest', label: 'Original Request' },
        { id: 'committedrequest', label: 'Current Commitment' },
        { id: 'disbursement', label: 'Disbursed' },
        { id: 'percentUtilized', label: 'Util.%' },
        { id: 'invoicingmode', label: 'Invoice Mode' },
        { id: 'fcdldate', label: 'FCDL Date' },
        { id: 'filingwindow', label: 'ECF Window' },
    ];

    const f471NumColumnStyles = {
        f471applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        fundingrequestnickname: { minWidth: '80px', maxWidth: '150px' },
        spin: { minWidth: '150px', maxWidth: '300px' },
        serviceprovidername: { minWidth: '150px', maxWidth: '300px' },
        serviceType: { minWidth: '80px', maxWidth: '150px' },
        origrannualcost: { minWidth: '100px', maxWidth: '200px' },
        cmtdrannualcost: { minWidth: '100px', maxWidth: '200px' },
        origfundingrequest: { minWidth: '100px', maxWidth: '200px' },
        totalauthorizeddisbursement: { minWidth: '100px', maxWidth: '200px' },
        percentUtilized: { minWidth: '80px', maxWidth: '150px' },
        invoicingmode: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
        filingwindow: { minWidth: '80px', maxWidth: '150px' },
    };

    const handleECFDataRequestSort = (property) => {
        const isAsc = ecfOrderBy === property && ecfOrder === 'asc';
        setECFOrder(isAsc ? 'desc' : 'asc');
        setECFOrderBy(property);
    };

    const sortECFData = (data, orderBy, order) => {
        return [...data].sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Convert monetary values to numbers for sorting
            if (
                orderBy.startsWith('originalrequest') ||
                orderBy.startsWith('committedrequest') ||
                orderBy.startsWith('disbursement')
            ) {
                aValue = aValue ? parseFloat(aValue.replace(/[$,]/g, '')) : 0;
                bValue = bValue ? parseFloat(bValue.replace(/[$,]/g, '')) : 0;
            }

            // Occasionally there are null fcdl dates that break sorting
            if (orderBy === 'fcdldate') {
                aValue = aValue ? new Date(aValue) : order === 'asc' ? new Date(0) : new Date(8640000000000000);
                bValue = bValue ? new Date(bValue) : order === 'asc' ? new Date(0) : new Date(8640000000000000);
            }

            // Handle sorting based on the value type
            if (aValue instanceof Date) {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'number') {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string') {
                return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            return 0;
        });
    };

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => {
        // Checking if the value is 0 or "0"
        if (value === 0 || value === '0' || !value) {
            return '$0.00';
        }

        // Ensuring the value is a number
        const numericValue = parseFloat(value);

        // Formatting the number to money
        return `$${numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    const calculateUtilPercentage = (ecfData) => {
        return ecfData.map((row) => {
            const committed = parseMoney(row.committedrequest);
            const disbursed = parseMoney(row.disbursement);

            let utilization = 0;
            if (committed > 0) {
                utilization = disbursed > 0 ? disbursed / committed : 0;
            }

            return {
                ...row,
                ecfUtilized: utilization,
            };
        });
    };

    const calculateECFTotals = (processedData) => {
        const totals = processedData.reduce(
            (acc, rowData) => {
                acc.originalrequest += parseMoney(rowData.originalrequest);
                acc.committedrequest += parseMoney(rowData.committedrequest);
                acc.disbursement += parseMoney(rowData.disbursement);

                return acc;
            },
            {
                originalrequest: 0,
                committedrequest: 0,
                disbursement: 0,
            }
        );

        totals.ecfUtilized = totals.committedrequest > 0 ? (totals.disbursement / totals.committedrequest) * 100 : 0;

        return totals;
    };

    const convertInvoicingMode = (invoice) => {
        if (invoice) {
            if (invoice === 'Applicant - FCC Form 472 (BEAR Form)') {
                return 'BEAR';
            } else if (invoice === 'Service Provider - FCC Form 474 (SPI Form)') {
                return 'SPI';
            } else {
                return 'NOT SET';
            }
        } else {
            return 'NOT SET';
        }
    };

    const ecfDataWithUtil = calculateUtilPercentage(ecfData);
    const sortedECFData = sortECFData(ecfDataWithUtil, ecfOrderBy, ecfOrder);
    const ecfTotals = calculateECFTotals(ecfDataWithUtil);

    return (
        <Box>
            <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                Emergency Connectivity Fund Summary Chart
            </h4>

            {searchType === 'entNum' ? (
                <TableContainer component={Paper}>
                    <Table
                        sx={{
                            minWidth: 800,
                            width: '100%', // Ensures the table takes up the full width of the container
                            tableLayout: 'fixed', // Helps in managing how the table handles the available width
                        }}
                        size='small'
                    >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                {ecfHeaders.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        sortDirection={ecfOrderBy === header.id ? ecfOrder : false}
                                        sx={{
                                            padding: '4px 8px',
                                            lineHeight: '1.2',
                                            flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                            flexGrow: 1, // Allows the columns to grow to fill available space
                                            flexShrink: 1, // Allows the columns to shrink if necessary
                                            fontSize: '10px',
                                            ...ecfColumnStyles[header.id],
                                        }}
                                    >
                                        <TableSortLabel
                                            active={ecfOrderBy === header.id}
                                            direction={ecfOrderBy === header.id ? ecfOrder : 'asc'}
                                            onClick={() => handleECFDataRequestSort(header.id)}
                                        >
                                            {header.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {sortedECFData.map((rowData, index) => (
                                <TableRow
                                    key={`${rowData.year}-${index}`}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                    }}
                                >
                                    {/* path='/client-list/funding-history-details/:kyList/:program/:searchType' */}
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            // href={`/client-list/funding-year-details/${kyList}/${fundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471/`}
                                            href={`/client-list/funding-history-details/${kyList}/${rowData.applicationnumber}/ECF/f471`} // state = all, program = ECF, type = f471
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.applicationnumber}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/form-details-all-ecf/${kyList}/${rowData.frn}/frn`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.frn}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.fundingrequestnickname}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.serviceprovidername}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/funding-summary/${kyList}/${rowData.spin}/spin`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.spin}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.servicetype}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.originalrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {/* {formatMoney(rowData.committedrequest)} */}
                                        {formatDate(rowData.fcdldate) !== '' && rowData.committedrequest <= 0
                                            ? formatMoney(rowData.committedrequest) + ' (NF)'
                                            : formatMoney(rowData.committedrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.disbursement)}
                                    </TableCell>

                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {parseInt(rowData.committedrequest) > 0
                                            ? parseInt(rowData.disbursement) > 0
                                                ? `${(
                                                      (parseInt(rowData.disbursement) /
                                                          parseInt(rowData.committedrequest)) *
                                                      100
                                                  ).toFixed(2)}%`
                                                : '0%'
                                            : '0%'}
                                    </TableCell>

                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {convertInvoicingMode(rowData.invoicingmethod)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatDate(rowData.fcdldate)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.filingwindow}
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow sx={{ backgroundColor: ecfData.length % 2 === 0 ? 'white' : '#f5f5f5' }}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>Totals</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatMoney(ecfTotals.originalrequest)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatMoney(ecfTotals.committedrequest)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatMoney(ecfTotals.disbursement)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {`${ecfTotals.ecfUtilized.toFixed(2)}%`}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer component={Paper}>
                    <Table
                        sx={{
                            minWidth: 800,
                            width: '100%', // Ensures the table takes up the full width of the container
                            tableLayout: 'fixed', // Helps in managing how the table handles the available width
                        }}
                        size='small'
                    >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                {f471FundingDetailsHeaders.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        sortDirection={ecfOrderBy === header.id ? ecfOrder : false}
                                        sx={{
                                            padding: '4px 8px',
                                            lineHeight: '1.2',
                                            flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                            flexGrow: 1, // Allows the columns to grow to fill available space
                                            flexShrink: 1, // Allows the columns to shrink if necessary
                                            fontSize: '10px',
                                            ...f471NumColumnStyles[header.id],
                                        }}
                                    >
                                        <TableSortLabel
                                            active={ecfOrderBy === header.id}
                                            direction={ecfOrderBy === header.id ? ecfOrder : 'asc'}
                                            onClick={() => handleECFDataRequestSort(header.id)}
                                        >
                                            {header.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedECFData.map((rowData, index) => (
                                <TableRow
                                    key={`${rowData.filingwindow}-${index}`}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                    }}
                                >
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        <Link
                                            // href={`/client-list/funding-year-details/${kyList}/${fundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471/`}
                                            // href={`/client-list/funding-year-details/${kyList}/${rowData.applicationnumber}/all/ECF/f471/`} // state = all, program = ECF, type = f471
                                            href={`/client-list/funding-history-details/${kyList}/${rowData.applicationnumber}/ECF/f471`} // state = all, program = ECF, type = f471
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {rowData.applicationnumber}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        <Link
                                            // href={`/client-list/form-details/${
                                            //     rowData.frn
                                            // }/${rowData.fundingyear}/${
                                            //     searchType === 'entNum' || searchType === 'f471' ? 'frn' : 'entNum'
                                            // }`}
                                            // href={`/client-list/form-details-all-ecf/${kyList}/${rowData.frn}/frn`}
                                            href={`/client-list/form-details-all-ecf/${applicantData.ben}/${rowData.frn}/frn`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {rowData.frn}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.fundingrequestnickname}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.serviceprovidername}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        <Link
                                            href={`/client-list/funding-summary/${rowData.spin}/spin`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {rowData.spin}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.servicetype}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.originalrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {/* {formatMoney(rowData.committedrequest)} */}
                                        {formatDate(rowData.fcdldate) !== '' && rowData.committedrequest <= 0
                                            ? formatMoney(rowData.committedrequest) + ' (NF)'
                                            : formatMoney(rowData.committedrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.disbursement)}
                                    </TableCell>

                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {parseInt(rowData.committedrequest) > 0
                                            ? parseInt(rowData.disbursement) > 0
                                                ? `${Math.round(
                                                      (parseInt(rowData.disbursement) /
                                                          parseInt(rowData.committedrequest)) *
                                                          100
                                                  )}%`
                                                : '0%'
                                            : '0%'}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {convertInvoicingMode(rowData.invoicingmethod)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatDate(rowData.fcdldate)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.filingwindow}
                                    </TableCell>
                                </TableRow>
                            ))}

                            {sortedECFData.length > 0 && (
                                <TableRow
                                    sx={{ backgroundColor: sortedECFData.length % 2 === 0 ? 'white' : '#f5f5f5' }}
                                >
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>Totals</TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(ecfTotals.originalrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(ecfTotals.committedrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(ecfTotals.disbursement)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {`${Math.round(ecfTotals.ecfUtilized)}%`}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}
