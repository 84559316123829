import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';
import Fade from '@mui/material/Fade';
import {
    GridRowModes,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import CustomDatagridToolbar from './CustomDatagridToolbar.js';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ToastContainer, toast } from 'react-toastify';
import useLocalStorage from 'react-use-localstorage';
import LedgerRecordsTable from './LedgerRecordsTable.js';
import SecondaryContactsDisplay from './SecondaryContactsDisplay.js';
import Embed from './Embed.js';

import { ClientFundingYearDetails } from './ClientFundingYearEdit.js';

//********************************************************************************
//  Sections:
//      1) 'ApplicantsSummaryDashboard' QuickSight dashboard
//      2) Basic info (from Client Management tables).
//      3) Current client services (from ClientServices and Services tables).
//      4) Per Funding Year Data (mostly from ClientFundingYear table)
//      5) TODO: Summary USAC data and tracker data by applicant BEN(s).
//********************************************************************************

export default function ClientDetails(props) {
    const clientId = props.clientId;
    const clientAPI = props.clientAPI;
    //const userAPI = props.userAPI;
    const sourcePageName = props.sourcePageName ? props.sourcePageName : 'Back';
    const handleReturnToSourcePage =
        props.handleReturnToSourcePage instanceof Function ? props.handleReturnToSourcePage : false;
    const handleEditButtonClick = props.handleEditButtonClick instanceof Function ? props.handleEditButtonClick : false;

    let navigate = useNavigate();

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [clientInfo, setClientInfo] = React.useState(null);

    React.useEffect(() => {
        const getClient = async (clientId) => {
            let clientdata = await clientAPI.GetClient(clientId);
            console.log('clientdata: \r\n', clientdata);
            setClientInfo(clientdata);
        };

        getClient(clientId);
    }, []);

    if (!clientInfo) {
        // Don't display anything until we have data.
        return null;
    }

    const createBENsListText = (bens) => {
        if (!bens) {
            return '';
        }
        return bens.join(', ');
    };

    const getBENsForEmbed = () => {
        //console.log(urlSearchParams.get('ben'));
        if (urlSearchParams.get('ben')) {
            return [urlSearchParams.get('ben')];
        } else {
            return clientInfo.bensList;
        }
    };

    const directToSummaryPage = (ben) => {
        navigate(`/client-list/funding-summary/${clientId}/${ben}/entNum`);
    };

    function createEditClientButton() {
        if (handleEditButtonClick instanceof Function) {
            return (
                <Box>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => handleEditButtonClick(clientInfo.client_id)}
                    >
                        Edit
                    </Button>

                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => directToSummaryPage(clientInfo.bensList)}
                        sx={{ marginLeft: 2 }}
                    >
                        Funding Summary
                    </Button>
                </Box>
            );
        } else {
            return (
                <>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => navigate('/client-list/edit/' + clientInfo.client_id)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => directToSummaryPage(clientInfo.bensList)}
                        sx={{ marginLeft: 2 }}
                    >
                        Funding Summary
                    </Button>
                </>
            );
        }
    }

    return (
        <Box>
            <h3>
                Details - {clientInfo.name} ({createBENsListText(clientInfo.bensList)}) - {clientInfo.state_abbrev}{' '}
                <span style={{ marginLeft: '1em' }}>{createEditClientButton()}</span>
            </h3>

            <Box>
                <Embed
                    dashboardName='ApplicantsSummaryDashboard'
                    defaultToCurrentUser={false}
                    passedParameters={{ BEN: getBENsForEmbed() }}
                />
            </Box>

            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexBasis: '700px' }}>
                    <ClientDetailsBasicInfoSection
                        clientInfo={clientInfo}
                        sourcePageName={sourcePageName}
                        handleReturnToSourcePage={handleReturnToSourcePage}
                        handleEditButtonClick={handleEditButtonClick}
                    />
                </Box>
                <Box sx={{ flexGrow: 1, marginLeft: 2 }}>
                    <h4 style={{ color: '#19638B' }}>Current Services</h4>
                    <CurrentClientServicesTable clientAPI={clientAPI} clientId={clientId} />
                    <Box sx={{ mx: 'auto', my: 1, width: 120 }}>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            onClick={() => navigate('/client-list/client-services/' + clientId)}
                        >
                            Edit Services
                        </Button>
                    </Box>
                    {/* <ClientFundingYearData clientAPI={clientAPI} clientId={clientId} /> */}
                </Box>
            </Box>

            {/* <Box>
                <h4 style={{ color: '#19638B' }}>Per Funding Year Data</h4>
                <ClientFundingYearDetails clientAPI={clientAPI} clientId={clientId} />
            </Box> */}

            <Grid container alignItems='flex-end' rowSpacing={0.5}>
                <Grid item xs={6}>
                    {' '}
                    <Box sx={{ marginTop: 5 }}>
                        <h4 style={{ color: '#19638B' }}>Per Funding Year Data</h4>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box display='flex' justifyContent='flex-end'>
                        <Tooltip
                            title={
                                <span style={{ whiteSpace: 'pre-line' }}>
                                    <ul>
                                        <li>
                                            'Draft' and 'Certified' application counts exclude applications marked as
                                            'Discarded Application' or 'ERC not Responsible for Filing'
                                        </li>
                                    </ul>
                                    <br />
                                </span>
                            }
                            // title={<span style={{ whiteSpace: 'pre-line' }}>- 'Draft' and 'Certified' application counts exclude applications marked as 'Discarded Application' or 'ERC not Responsible for Filing'<br /></span>}
                            sx={{ marginLeft: '0.5em' }}
                            placement='top-start'
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 900 }}
                        >
                            <Chip
                                label='Legend'
                                variant='outlined'
                                color='info'
                                sx={{ float: 'right' }}
                                icon={<InfoIcon />}
                            />
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ClientFundingYearDetails clientAPI={clientAPI} clientId={clientId} />
                </Grid>
            </Grid>

            <Box sx={{ marginTop: 5 }}>
                <LedgerRecordsTable api={clientAPI} entityID={clientId} entityType='Client' />
            </Box>

            {/*  
            <Box>
                <h4 style={{ color: '#19638B' }}>USAC and tracker summary data</h4>
                <div>BENs: {createBENsListText(clientInfo.bensList)}</div>
                <ClientUsacAndTrackerSummaryData clientId={clientId} clientName={clientInfo.name} bensList={clientInfo.bensList} />
            </Box>
            */}
        </Box>
    );
}

function ClientDetailsBasicInfoSection({
    clientInfo,
    sourcePageName,
    handleReturnToSourcePage,
    handleEditButtonClick,
}) {
    let navigate = useNavigate();

    if (!clientInfo) {
        // Don't display anything until we have data.
        return null;
    }

    const displayBENsListHTML = (bens) => {
        if (!bens) {
            return '';
        }
        let ary = [];
        for (const ben of bens) {
            ary.push(<div key={ben}>{ben}</div>);
        }
        return <div>{ary}</div>;
    };

    function displayApplicantCohorts(client_applicantcohorts) {
        if (!client_applicantcohorts) {
            return '';
        }
        let ary = [];
        for (const cac of client_applicantcohorts) {
            ary.push(
                <div key={cac.client_id + '_' + cac.applicantcohort_id}>{cac.applicantcohort.applicantcohort_name}</div>
            );
        }
        return <div>{ary}</div>;
    }

    function getPrimaryContactName(primary_contact) {
        if (primary_contact) {
            return primary_contact.first_name + ' ' + primary_contact.last_name;
        } else {
            return '<NONE>';
        }
    }

    const cipaComplianceTextArray = [
        {
            val: 1,
            text: 'No Record',
        },
        {
            val: 2,
            text: 'Good',
        },
        {
            val: 3,
            text: 'Needs Improvement',
        },
        {
            val: 4,
            text: 'Needs Public Notice',
        },
        {
            val: 5,
            text: 'N/A',
        },
        {
            val: 6,
            text: 'Questionable',
        },
    ];

    function getCIPAComplianceText(id_val) {
        let cipaObj = cipaComplianceTextArray.find((obj) => obj.val === id_val);
        //console.log(id_val);
        if (cipaObj != null) {
            return cipaObj.text;
        } else {
            return '';
        }
    }

    function createNavButtons() {
        const EditButton_toeditpage = (
            <Button
                sx={{ m: 1 }}
                variant='contained'
                color='primary'
                size='small'
                onClick={() => navigate('/client-list/edit/' + clientInfo.client_id)}
            >
                Edit
            </Button>
        );

        const ClientListButton_toclientlistpage = (
            <Button
                sx={{ m: 1 }}
                variant='contained'
                color='secondary'
                size='small'
                onClick={() =>
                    navigate('/client-list', {
                        state: {
                            updatedRow: clientInfo,
                        },
                    })
                }
            >
                Go to Client List
            </Button>
        );

        let EditButton_usinghandler = '';
        if (handleEditButtonClick) {
            EditButton_usinghandler = (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={() => handleEditButtonClick(clientInfo.client_id)}
                >
                    Edit
                </Button>
            );
        }

        let ReturnToSourceButton = '';
        if (handleReturnToSourcePage) {
            ReturnToSourceButton = (
                <Button
                    sx={{ m: 1 }}
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={() => handleReturnToSourcePage()}
                >
                    {sourcePageName}
                </Button>
            );
        }

        return (
            <Box sx={{ mx: 'auto', my: 1, width: 240 }}>
                {EditButton_usinghandler ? EditButton_usinghandler : EditButton_toeditpage}
                {ReturnToSourceButton ? ReturnToSourceButton : ClientListButton_toclientlistpage}
            </Box>
        );
    }

    return (
        <Box>
            <h4 style={{ color: '#19638B' }}>Basic Info</h4>

            <Table sx={{ width: 800, marginRight: 2, marginBottom: 1 }} size='small'>
                <TableBody>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold', width: 250 }}>
                            Name
                        </TableCell>
                        <TableCell>{clientInfo.name}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            BENs
                        </TableCell>
                        <TableCell>{displayBENsListHTML(clientInfo.bensList)}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                            Applicant Cohorts
                        </TableCell>
                        <TableCell>{displayApplicantCohorts(clientInfo.client_applicantcohorts)}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Client State
                        </TableCell>
                        <TableCell>{clientInfo.state_abbrev}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                            Primary Contact
                        </TableCell>
                        <TableCell>{getPrimaryContactName(clientInfo.primary_contact)}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold', minWidth: 230 }}>
                            Secondary Contacts
                        </TableCell>
                        <TableCell>
                            {<SecondaryContactsDisplay secondaryContacts={clientInfo.secondary_contacts} />}
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Is a Client
                        </TableCell>
                        <TableCell>
                            {clientInfo.isAClient === true ? 'Yes' : clientInfo.isAClient === false ? 'No' : ''}
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Date Became Client
                        </TableCell>
                        <TableCell>{clientInfo.start_date != null ? clientInfo.start_date : ''}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Date Ended as Client
                        </TableCell>
                        <TableCell>{clientInfo.end_date != null ? clientInfo.end_date : ''}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            # of Current Services
                        </TableCell>
                        <TableCell>
                            <Tooltip title='Add/Remove Services'>
                                <Link href={'/client-list/client-services/' + clientInfo.client_id}>
                                    {clientInfo.currentClientServicesCount}
                                </Link>
                            </Tooltip>
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Is a Business Organization
                        </TableCell>
                        <TableCell>
                            {clientInfo.is_business_org === true
                                ? 'Yes'
                                : clientInfo.is_business_org === false
                                ? 'No'
                                : ''}
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Notes
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{clientInfo.notes}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            ECF 1
                        </TableCell>
                        <TableCell>{clientInfo.filingForECF1}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            ECF 2
                        </TableCell>
                        <TableCell>{clientInfo.filingForECF2}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            ECF 3
                        </TableCell>
                        <TableCell>{clientInfo.filingForECF3}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Cyber Pilot
                        </TableCell>
                        <TableCell>{clientInfo.cybersecuritypilot_participation}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            CIPA Compliance
                        </TableCell>
                        <TableCell>{getCIPAComplianceText(clientInfo.cipa_compliance)}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell component='th' sx={{ fontWeight: 'bold' }}>
                            Primary BEN (legacy)
                        </TableCell>
                        <TableCell>{clientInfo.legacy_primary_ben}</TableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            {createNavButtons()}
        </Box>
    );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const determineServiceDatesTemporal = (start_date, end_date) => {
    // Returns 'future', 'current', 'past', or '?'.
    // Service start and end dates are in/for the Eastern timezone.
    // This is assuming that start_date and end_date were entered correctly and start_date <= end_date.
    let startDate = new Date(start_date + 'T00:00:00.000-04:00'); // Input as Eastern timezone and make it start of day.
    let endDate = null;
    if (end_date !== null) {
        endDate = new Date(end_date + 'T23:59:59.999-04:00'); // Input as Eastern timezone and make it end of day.
    }
    //console.log('start_date = ' + start_date + ' | startDate = ' + startDate);
    //console.log('end_date = ' + end_date + ' | endDate = ' + endDate);
    const today = new Date();
    if (today < startDate) {
        return 'future';
    }
    if (endDate === null || today < endDate) {
        return 'current';
    }
    if (endDate !== null && today > endDate) {
        return 'past';
    }
    return '?';
};

function CurrentClientServicesTable({ clientAPI, clientId }) {
    const [currentClientServices, setCurrentClientServices] = React.useState(null);
    const [pageSize, setPageSize] = React.useState(25);

    React.useEffect(() => {
        const getCurrentClientServices = async (clientId) => {
            // TODO: Only retrieve the current services.
            //   Option #1. Create new lambda and api.   -    let clientservices = await clientAPI.GetCurrentClientServices(clientId);
            //   Option #2. Add a `temporal` parameter ('all' (default), 'current', 'future', 'past').   -  let clientservices = await clientAPI.GetClientServices(clientId, 'current');

            let clientservices = await clientAPI.GetClientServices(clientId);
            clientservices = clientservices.filter(
                (cs) => determineServiceDatesTemporal(cs.start_date, cs.end_date) === 'current'
            );
            //console.log('clientservices: \r\n', clientservices);
            setCurrentClientServices(clientservices);
        };

        getCurrentClientServices(clientId);
    }, []);

    if (!currentClientServices) {
        return null;
    }

    const columns = [
        {
            headerName: 'Service Name',
            field: 'service_name',
            valueGetter: (params) => params.row.service.service_name,
            width: 300,
        },
        {
            headerName: 'Group',
            field: 'service_grouping',
            valueGetter: (params) => params.row.service.service_grouping,
            width: 120,
        },
        {
            headerName: 'Start Date',
            field: 'start_date',
            width: 130,
        },
        {
            headerName: 'End Date',
            field: 'end_date',
            width: 130,
        },
        /*
        {
            headerName: 'temporal',
            field: 'temporal',
            width: 130,
            valueGetter: (params) => {
                //console.log(params.row.client_id + ' ' + params.row.service_id + ' -  start_date = ' + params.row.start_date + ' | end_date = ' + params.row.end_date);
                return determineServiceDatesTemporal(params.row.start_date, params.row.end_date);
            },
        },
        */
        {
            headerName: 'Value',
            field: 'service_action',
            valueGetter: (params) => params.row.service_action.service_text,
            width: 130,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            flex: 1,
        },
    ];

    return (
        <DataGridPro
            columns={columns}
            rows={currentClientServices}
            getRowId={(row) => row.client_id + '_' + row.service_id + '_' + row.start_date}
            loading={currentClientServices === null}
            components={{ Toolbar: CustomDatagridToolbar }}
            disableSelectionOnClick
            rowHeight={35}
            pagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            initialState={{
                sorting: {
                    sortModel: [
                        { field: 'service_grouping', sort: 'asc' },
                        { field: 'service_name', sort: 'asc' },
                        { field: 'start_date', sort: 'asc' },
                    ],
                },
            }}
            sx={{
                height: '80vh',
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell': {
                    border: '1px solid rgba(224, 224, 224, 1)',
                },
                '& .MuiDataGrid-row:nth-of-type(2n)': {
                    backgroundColor: '#f0f2f4',
                },
                '& .MuiDataGrid-row:nth-of-type(2n+1)': {
                    backgroundColor: '#fdfffe',
                },
                '& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer': {
                    backgroundColor: '#fdfffe',
                },
            }}
        />
    );
}

/*
TODO:  This is a stub..
function ClientUsacAndTrackerSummaryData({ clientId, clientName, bensList }) {
    const [summaryData, setSummaryData] = React.useState(null);

    React.useEffect(() => {
        const getSummaryData = async (clientId, bens) => {
            maybe something like..  
            let summarydata = await trackerAPI.GetSummaryDataByBENs(bens, -1);    // GetSummaryDataByBENs(bens, fundingYears)
            console.log('summarydata: \r\n', summarydata);
            setSummaryData(summarydata);
        };

        if (clientId && bensList) {
            getSummaryData(clientId, bensList);
        }
    }, [clientName]);

    return (
        <Box>
            TODO
            {clientName}
        <Box/>
    );
}
*/
