import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// MUI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Custom components
import { BulkEditDropdown } from '../customcomponents/BulkEditDropdown.js';
import { BulkEditDatePicker } from '../customcomponents/BulkEditDatePicker.js';

export const F471TrackerApplicantsBulkEdit = ({
    afyStatusEditOptions,
    bulkEditButtonClicked,
    pruUpdatedRow,
    reportData,
    selectionModel,
    setBulkEditButtonClicked,
    setReportData,
    trackersAPI,
    username,
}) => {
    // ################################### Bulk Editing #############################################
    const [openBulkEditDialog, setOpenBulkEditDialog] = React.useState(false);
    const [openBulkEditConfirmChangesTable, setOpenBulkEditConfirmChangesTable] = React.useState(false);
    const [nextButtonClicked, setNextButtonClicked] = React.useState(false);
    const [oldBulkEditRows, setOldBulkEditRows] = React.useState([]);
    const [newBulkEditRows, setNewBulkEditRows] = React.useState([]);

    const [bulkEditExpectedNumberOfMinibids, setBulkEditExpectedNumberOfMinibids] = React.useState(null);
    const [bulkEditExpectedNumberOfForm470s, setBulkEditExpectedNumberOfForm470s] = React.useState(null);
    const [bulkEditForm470AFYstatus, setBulkEditForm470AFYstatus] = React.useState('');
    const [bulkEditForm470InitialOutreachDate, setBulkEditForm470InitialOutreachDate] = React.useState('');
    const [bulkEditForm470TrackerNotes, setBulkEditForm470TrackerNotes] = React.useState('');
    const [bulkEditExpectedNumberOfForm471s, setBulkEditExpectedNumberOfForm471s] = React.useState(null);
    const [bulkEditForm471TrackerNotes, setBulkEditForm471TrackerNotes] = React.useState('');

    // ################################### Initial Values #############################################
    const [bulkEditExpectedNumberOfMinibidsIV, setBulkEditExpectedNumberOfMinibidsIV] = React.useState(null);
    const [bulkEditExpectedNumberOfForm470sIV, setBulkEditExpectedNumberOfForm470sIV] = React.useState(null);
    const [bulkEditForm470AFYstatusIV, setBulkEditForm470AFYstatusIV] = React.useState('');
    const [bulkEditForm470InitialOutreachDateIV, setBulkEditForm470InitialOutreachDateIV] = React.useState('');
    const [bulkEditExpectedNumberOfForm471sIV, setBulkEditExpectedNumberOfForm471sIV] = React.useState(null);

    const [allChanges, setAllChanges] = React.useState([]);
    const [changedAttributes, setChangedAttributes] = React.useState([]);

    // ################################### Multiple Values #############################################
    const [bulkEditExpectedNumberOfMinibidsHMV, setBulkEditExpectedNumberOfMinibidsHMV] = React.useState(false);
    const [bulkEditExpectedNumberOfForm470sHMV, setBulkEditExpectedNumberOfForm470sHMV] = React.useState(false);
    const [bulkEditForm470AFYstatusHMV, setBulkEditForm470AFYstatusHMV] = React.useState(false);
    const [bulkEditForm470InitialOutreachDateHMV, setBulkEditForm470InitialOutreachDateHMV] = React.useState(false);
    const [bulkEditExpectedNumberOfForm471sHMV, setBulkEditExpectedNumberOfForm471sHMV] = React.useState(false);

    // ################################### Disabled & Progress State #############################################
    const [confirmButtonIsDisabled, setConfirmButtonIsDisabled] = React.useState(false);
    const [cancelButtonIsDisabled, setCancelButtonIsDisabled] = React.useState(false);
    const [backButtonIsDisabled, setBackButtonIsDisabled] = React.useState(false);
    const [bulkEditProgressIsOpen, setBulkEditProgressIsOpen] = React.useState(false);

    // Adds newly-selected existing data to a state
    React.useEffect(() => {
        setOldBulkEditSelectedRows();
    }, [selectionModel]);

    // Creates the displayed changes on the "confirmation page"
    React.useEffect(() => {
        if (oldBulkEditRows.length > 0) {
            const all_changes = findChangesBetweenRows(oldBulkEditRows, newBulkEditRows);
            if (all_changes.length !== 0) {
                // console.log(
                //     '[F471TrackerApplicantsBulkEdit][promptBulkEditChangesConfirmation] all_changes:',
                //     all_changes
                // );
                setAllChanges(all_changes);
            }

            // Determine the unique set of changed attributes across all selected records
            const changedAttributes = [...new Set(all_changes.flatMap((ea_change) => Object.keys(ea_change.changes)))];
            // console.log(
            //     '[F471TrackerApplicantsBulkEdit][promptBulkEditChangesConfirmation] changedAttributes:',
            //     changedAttributes
            // );
            setChangedAttributes(changedAttributes);
        }
    }, [nextButtonClicked]);

    // After the user clicks the next button, add the newly modified data/records to a state
    React.useEffect(() => {
        if (newBulkEditRows.length > 0) {
            setOpenBulkEditConfirmChangesTable(true);
        }
    }, [newBulkEditRows]);

    // After processRunUpdate, find and update the corresponding row in trackerData (needed to prevent display errors during bulk editing)
    React.useEffect(() => {
        const updatedTrackerData = reportData.map((row) => {
            if (row.client_id === pruUpdatedRow.client_id) {
                return pruUpdatedRow; // Replace with the updated row
            }
            return row; // Return unchanged row
        });

        setReportData(updatedTrackerData);
    }, [pruUpdatedRow]);

    // Triggered when the user clicks the BE button in the TrackerDataGrid
    React.useEffect(() => {
        if (bulkEditButtonClicked === true) {
            handleBulkEditButtonClicked();
            setBulkEditButtonClicked(false);
        }
    }, [bulkEditButtonClicked]);

    // If value is a number, return it.  If value is text then search the dropdown options for the numeric value (id).
    const correctTheDropdownValue = (value, ddoptions) => {
        if (value === null || value === undefined) {
            return 0;
        }
        if (Number.isInteger(value)) {
            return value;
        }
        if (
            value === '' ||
            value.toLowerCase() === '<default to primary contact>' ||
            value.toLowerCase() === '<not set>' ||
            value.toLowerCase() === '<blank>'
        ) {
            return 0;
        }

        let ddoption = ddoptions.find((element) => element.text === value);
        let ddvalue = ddoption?.value;
        return ddvalue;
    };

    // Provides a display text based on a provided ID and array of options
    const getOtherDisplayName = (id, dropDownOptions) => {
        if (!id) {
            return '';
        }

        const results = dropDownOptions.find((option) => option.value === id);
        return results?.text;
    };

    // Excludes numeric 0 from being falsy for dropdown edit options
    const isEmptyChecker = (value) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value.trim().length === 0) return true;
        return false;
    };

    const attributeHeaderMapping = {
        expectedNumberOfMinibids: 'Expected # of Mini-Bids',
        expectedNumberOfForm470s: 'Expected # of Forms 470',
        form470AFYstatusId: 'Form 470 Status (per-Applicant, per-FY)',
        form470InitialOutreachDate: 'Form 470 Initial Outreach Date',
        form470TrackerNotes: 'Form 470 Notes',
        expectedNumberOfForm471s: 'Expected # of Forms 471',
        form471TrackerNotes: 'Form 471 Notes',
    };

    // A mapping of attribute to state setter to check if any key in a Bulk Edit action has any varying values and set true/false accordingly. Used in checkIfAttrHasMultipleValues once the Bulk Edit button is clicked
    // Follow the format of HMV, IV, original setter
    const attributeBEStateMapping = {
        expectedNumberOfMinibids: [
            setBulkEditExpectedNumberOfMinibidsHMV,
            setBulkEditExpectedNumberOfMinibidsIV,
            setBulkEditExpectedNumberOfMinibids,
        ],
        expectedNumberOfForm470s: [
            setBulkEditExpectedNumberOfForm470sHMV,
            setBulkEditExpectedNumberOfForm470sIV,
            setBulkEditExpectedNumberOfForm470s,
        ],
        form470AFYstatusId: [
            setBulkEditForm470AFYstatusHMV,
            setBulkEditForm470AFYstatusIV,
            setBulkEditForm470AFYstatus,
        ],
        form470InitialOutreachDate: [
            setBulkEditForm470InitialOutreachDateHMV,
            setBulkEditForm470InitialOutreachDateIV,
            setBulkEditForm470InitialOutreachDate,
        ],
        expectedNumberOfForm471s: [
            setBulkEditExpectedNumberOfForm471sHMV,
            setBulkEditExpectedNumberOfForm471sIV,
            setBulkEditExpectedNumberOfForm471s,
        ],
    };

    // Cycles through the *bulk selected* records, and for each attribute, checks if there's any difference in value for that attr within the dataset, and sets the respective state to true if so. Ran when the BE button is clicked
    const checkIfAttrHasMultipleValues = (selected_records, attributeStateMapping) => {
        // console.log('[F471TrackerApplicantsBulkEdit][checkIfAttrHasMultipleValues][[selected_records] =', selected_records);
        // console.log('[F471TrackerApplicantsBulkEdit][checkIfAttrHasMultipleValues][attributeStateMapping] =', attributeStateMapping);

        for (const [key, valueSetters] of Object.entries(attributeStateMapping)) {
            let allHaveThisValue = '';
            const areAllValuesTheSame = selected_records.every((ea_obj) => {
                allHaveThisValue = selected_records[0][key];
                return ea_obj[key] === selected_records[0][key];
            });

            // If there are different values (AAVTS = false because every is all or nothing), then set MV to true.
            if (!areAllValuesTheSame) {
                valueSetters[0](true);
            } else {
                valueSetters[1](allHaveThisValue);
                valueSetters[2](allHaveThisValue);
            }
        }
    };

    // Comparing the old record values against the new edited values
    const findChangesBetweenRows = (oldRecords, newRecords) => {
        // console.log('[F471TrackerApplicantsBulkEdit][findChangesBetweenRows] oldRecords, newRecords:', oldRecords, newRecords);
        let changes = newRecords.map((newRecord, index) => {
            let oldRecord = oldRecords.find((oldRecord) => oldRecord.client_id === newRecord.client_id);

            // Likely no need for applicants table
            const oldNewRecordAttrTranslator = {};

            let recordChanges = {};
            Object.keys(attributeHeaderMapping).forEach((attr_key) => {
                // Check if there's any special old to new attribute mapping required, then just get the newRecord key value
                const translatedKey = oldNewRecordAttrTranslator[attr_key] || attr_key;
                let oldValue = oldRecord ? oldRecord[translatedKey] : undefined;
                let newValue = newRecord[attr_key];

                if (attr_key === 'form470AFYstatusId') {
                    if (oldValue !== newValue) {
                        newValue = getOtherDisplayName(newValue, afyStatusEditOptions);
                    }
                }

                if (oldValue !== newValue) {
                    recordChanges[attr_key] = { oldValue: oldValue, newValue: newValue };
                }
            });

            return {
                rowID: newRecord.client_id,
                ben: newRecord.bens[0],
                clientName: newRecord.clientName,
                changes: recordChanges,
            };
        });
        return changes;
    };

    // Used for the visual display of original/unmodified data to the user within the "confirmation page"
    const applyOldRowTransformations = (rowID, value, attr) => {
        const matching_row = oldBulkEditRows.find((ea_row) => ea_row.client_id === rowID);
        // console.log('[F471TrackerApplicantsBulkEdit][applyOldRowTransformations] old_matching_row:', matching_row);

        if (!matching_row) {
            return null;
        }

        switch (attr) {
            case 'expectedNumberOfMinibids':
                return matching_row.expectedNumberOfMinibids;
            case 'expectedNumberOfForm470s':
                return matching_row.expectedNumberOfForm470s;
            case 'form470AFYstatusId':
                return matching_row.form470AFYstatusText;
            case 'form470InitialOutreachDate':
                return matching_row.form470InitialOutreachDate;
            case 'expectedNumberOfForm471s':
                return matching_row.expectedNumberOfForm471s;
            default:
                return value;
        }
    };

    // Used for the visual display of new/modified data to the user within the "confirmation page"
    const applyNewRowTransformations = (rowID, value, attr) => {
        const matching_row = newBulkEditRows.find((ea_row) => ea_row.client_id === rowID);
        // console.log('[F471TrackerApplicantsBulkEdit][applyOldRowTransformations] new_matching_row:', matching_row);

        if (!matching_row) {
            return null;
        }

        const characterLimit = 250;
        switch (attr) {
            case 'expectedNumberOfMinibids':
                return matching_row.expectedNumberOfMinibids;
            case 'expectedNumberOfForm470s':
                return matching_row.expectedNumberOfForm470s;
            case 'form470AFYstatusId':
                return getOtherDisplayName(matching_row.form470AFYstatusId, afyStatusEditOptions);
            case 'form470InitialOutreachDate':
                return matching_row.form470InitialOutreachDate;
            case 'expectedNumberOfForm471s':
                return matching_row.expectedNumberOfForm471s;
            default:
                if (value.length > characterLimit) {
                    return `${value.substring(0, characterLimit)}...`;
                }
                return value;
        }
    };

    // Adds newly-selected existing data to a state, ran when the Bulk Edit button is clicked
    const setOldBulkEditSelectedRows = () => {
        let oldBulkEditRowsTemp = [];

        if (selectionModel.length > 0) {
            selectionModel.forEach((rowID) => {
                const row = reportData.find((row) => row.client_id === rowID);
                if (row) oldBulkEditRowsTemp.push(row);
            });
            // console.log(
            //     '[F471TrackerApplicantTable][setOldBulkEditSelectedRows] oldBulkEditRowsTemp:',
            //     oldBulkEditRowsTemp
            // );
            setOldBulkEditRows(oldBulkEditRowsTemp);
            return oldBulkEditRowsTemp;
        }
    };

    // Prevents the user from clicking outside of the dialog to exit, and throws a window if they cancel with values selected
    const handleBulkEditClose = (event, reason) => {
        // Disable exiting if user clicked outside the dialog.
        // User can exit by clicking the "Cancel" button or pressing escape.

        // Handles clicking outside of the dialog
        if (reason === 'backdropClick') {
            return;
        }

        // If they searched and selected values then confirm they want to exit.
        if (
            (!isEmptyChecker(bulkEditExpectedNumberOfMinibids) &&
                bulkEditExpectedNumberOfMinibids !== bulkEditExpectedNumberOfMinibidsIV) ||
            (!isEmptyChecker(bulkEditExpectedNumberOfForm470s) &&
                bulkEditExpectedNumberOfForm470s !== bulkEditExpectedNumberOfForm470sIV) ||
            (!isEmptyChecker(bulkEditForm470AFYstatus) && bulkEditForm470AFYstatus !== bulkEditForm470AFYstatusIV) ||
            (!isEmptyChecker(bulkEditForm470InitialOutreachDate) &&
                bulkEditForm470InitialOutreachDate !== bulkEditForm470InitialOutreachDateIV) ||
            (!isEmptyChecker(bulkEditExpectedNumberOfForm471s) &&
                bulkEditExpectedNumberOfForm471s !== bulkEditExpectedNumberOfForm471sIV) ||
            bulkEditForm470TrackerNotes ||
            bulkEditForm471TrackerNotes
        ) {
            const confirmation = window.confirm('Close without finishing?');
            if (!confirmation) return;
        }

        // Reset the state if the user confirmed they want to exit.
        resetThreeBulkStateSets();
    };

    const handleBulkEditCloseConfirmationPage = (event, reason) => {
        return;
    };

    // Opens the initial bulk editing dialog after the user clicks the Bulk Edit button. Checks if the old data has multiple values
    const handleBulkEditButtonClicked = () => {
        const oldBulkEditRowsTemp = setOldBulkEditSelectedRows();
        setOpenBulkEditDialog(true);
        checkIfAttrHasMultipleValues(oldBulkEditRowsTemp, attributeBEStateMapping);
    };

    // Prepends the newly-typed note to the corresponding already-existing note (ea record at a time)
    const handleAddNotesBulk = (oldValue, newValue) => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        const newNote = `${currentDate} (${username.email.split('@')[0]}) - ${newValue}\n\n`;
        if (!oldValue) return newNote;
        return `${newNote}${oldValue}`;
        // return newNote;
    };

    // Sets the newly-edited BE records to state after applying transformations after the user clicks the Next button
    const handleBulkEditNextButtonClicked = () => {
        const bulkSelectedRows = selectionModel.map((rowID) => {
            const specificRow = reportData.find((row) => row.client_id === rowID);

            let uedata = {
                ...specificRow,

                expectedNumberOfMinibids: !isEmptyChecker(bulkEditExpectedNumberOfMinibids)
                    ? bulkEditExpectedNumberOfMinibids
                    : specificRow.expectedNumberOfMinibids,
                expectedNumberOfForm470s: !isEmptyChecker(bulkEditExpectedNumberOfForm470s)
                    ? bulkEditExpectedNumberOfForm470s
                    : specificRow.expectedNumberOfForm470s,

                form470AFYstatusId: !isEmptyChecker(bulkEditForm470AFYstatus)
                    ? correctTheDropdownValue(bulkEditForm470AFYstatus, afyStatusEditOptions)
                    : specificRow.form470AFYstatusId,
                form470InitialOutreachDate: !isEmptyChecker(bulkEditForm470InitialOutreachDate)
                    ? bulkEditForm470InitialOutreachDate
                    : specificRow.form470InitialOutreachDate,
                expectedNumberOfForm471s: !isEmptyChecker(bulkEditExpectedNumberOfForm471s)
                    ? bulkEditExpectedNumberOfForm471s
                    : specificRow.expectedNumberOfForm471s,

                form470TrackerNotes: bulkEditForm470TrackerNotes
                    ? handleAddNotesBulk(specificRow.form470TrackerNotes, bulkEditForm470TrackerNotes)
                    : specificRow.form470TrackerNotes,
                form471TrackerNotes: bulkEditForm471TrackerNotes
                    ? handleAddNotesBulk(specificRow.form471TrackerNotes, bulkEditForm471TrackerNotes)
                    : specificRow.form471TrackerNotes,
            };

            return uedata;
        });

        // console.log('[F471TrackerApplicantsBulkEdit][handleBulkEditSaveAPICall] bulkSelectRows:', bulkSelectedRows);
        setNewBulkEditRows(bulkSelectedRows);
        setOpenBulkEditDialog(false);
        setNextButtonClicked(false);
    };

    // TODO: Apply loading spinner logic after this click. Makes the save call after the user clicks on the Confirm button
    const handleBulkEditConfirmButtonClicked = () => {
        setConfirmButtonIsDisabled(true);
        setCancelButtonIsDisabled(true);
        setBackButtonIsDisabled(true);
        setBulkEditProgressIsOpen(true);

        // Make the API call to save the data, then clear relevant state
        handleBulkEditSaveAPICall();
    };

    // Reopens the landing Bulk Editing component
    const handleBulkEditBackButtonClicked = () => {
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(true);
        setNextButtonClicked(false);
    };

    // Resets all states dealing with BE
    const resetThreeBulkStateSets = () => {
        handleBulkEditResetState();
        handleBulkEditHasMultipleValuesResetState();
        handleBulkEditInitialValuesResetState();
    };

    // Allows the user to keep BE changes if the API call fails
    const resetStateForFailedAPIResponse = () => {
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(false);
        setBulkEditProgressIsOpen(false);
        setConfirmButtonIsDisabled(false);
        setCancelButtonIsDisabled(false);
        setBackButtonIsDisabled(false);
        setNextButtonClicked(false);
    };

    // Clear out all of the bulk editing state management (after cancel or successful saving)
    const handleBulkEditResetState = () => {
        setBulkEditExpectedNumberOfMinibids('');
        setBulkEditExpectedNumberOfForm470s('');
        setBulkEditForm470AFYstatus('');
        setBulkEditForm470InitialOutreachDate('');
        setBulkEditExpectedNumberOfForm471s('');
        setBulkEditForm470TrackerNotes('');
        setBulkEditForm471TrackerNotes('');
        setOldBulkEditRows([]);
        setNewBulkEditRows([]);
        setOpenBulkEditConfirmChangesTable(false);
        setOpenBulkEditDialog(false);
        setBulkEditProgressIsOpen(false);
        setConfirmButtonIsDisabled(false);
        setCancelButtonIsDisabled(false);
        setBackButtonIsDisabled(false);
        setNextButtonClicked(false);
        setChangedAttributes([]);
        setAllChanges([]);
    };

    // Clear out all of the bulk editing multiple values state management (after cancel or successful saving)
    const handleBulkEditHasMultipleValuesResetState = () => {
        setBulkEditExpectedNumberOfMinibidsIV(false);
        setBulkEditExpectedNumberOfForm470sIV(false);
        setBulkEditForm470AFYstatusIV(false);
        setBulkEditForm470InitialOutreachDateIV(false);
        setBulkEditExpectedNumberOfForm471sIV(false);
    };

    // Clear out all of the bulk editing initial values
    const handleBulkEditInitialValuesResetState = () => {
        setBulkEditExpectedNumberOfMinibidsIV('');
        setBulkEditExpectedNumberOfForm470sIV('');
        setBulkEditForm470AFYstatusIV('');
        setBulkEditForm470InitialOutreachDateIV('');
        setBulkEditExpectedNumberOfForm471sIV('');
    };

    // Checks if any values have been selected, used to control disabled attributes
    const isNextButtonDisabled = !(
        (!isEmptyChecker(bulkEditExpectedNumberOfMinibids) &&
            bulkEditExpectedNumberOfMinibids !== bulkEditExpectedNumberOfMinibidsIV) ||
        (!isEmptyChecker(bulkEditExpectedNumberOfForm470s) &&
            bulkEditExpectedNumberOfForm470s !== bulkEditExpectedNumberOfForm470sIV) ||
        (!isEmptyChecker(bulkEditForm470AFYstatus) && bulkEditForm470AFYstatus !== bulkEditForm470AFYstatusIV) ||
        (!isEmptyChecker(bulkEditForm470InitialOutreachDate) &&
            bulkEditForm470InitialOutreachDate !== bulkEditForm470InitialOutreachDateIV) ||
        (!isEmptyChecker(bulkEditExpectedNumberOfForm471s) &&
            bulkEditExpectedNumberOfForm471s !== bulkEditExpectedNumberOfForm471sIV) ||
        bulkEditForm470TrackerNotes ||
        bulkEditForm471TrackerNotes
    );

    // Saves the Bulk Editing data and controls related Toast pop-ups
    const handleBulkEditSaveAPICall = async () => {
        try {
            const findNewData = () => {
                const stringKeys = [
                    // 'form470AFYstatusId',
                    'form470InitialOutreachDate',
                    'form470TrackerNotes',
                    'form471TrackerNotes',
                ];
                const newData = newBulkEditRows.map((record) => {
                    return changedAttributes.reduce((newRecord, field) => {
                        newRecord['client_id'] = record['client_id'];
                        newRecord['funding_year'] = record['fundingYear'];

                        if (record.hasOwnProperty(field)) {
                            let newValue = '';
                            if (!stringKeys.includes(field)) {
                                newValue = parseInt(record[field]);
                            } else {
                                newValue = record[field];
                            }
                            newRecord[field] = newValue;
                        }
                        return newRecord;
                    }, {});
                });
                return newData;
            };

            const fy = reportData[0]['fundingYear'];
            const formattedData = {
                recordIDs: selectionModel.map((ea_id) => ({ client_id: ea_id, funding_year: fy })),
                fieldsToChange: changedAttributes,
                uedata: findNewData(),
            };
            // console.log('[F471TrackerApplicantsBulkEdit][handleBulkEditSaveAPICall] formattedData:', formattedData);
            const apiResponse = await trackersAPI.SaveForm471TrackerApplicantsReportUEDBulk(formattedData);
            console.log('[F471TrackerApplicantsBulkEdit][handleBulkEditSaveAPICall] apiResponse:', apiResponse);

            if (apiResponse && apiResponse?.length) {
                const updatedRecordsMap = new Map(apiResponse.map((record) => [record.client_id, record]));
                // console.log('[F471TrackerApplicantsBulkEdit][handleBulkEditSaveAPICall] updatedRecordsMap:', updatedRecordsMap);

                const updatedTrackerData = reportData.map((record) => {
                    const uniqueID = record.client_id;
                    if (updatedRecordsMap.has(uniqueID)) {
                        // If the edited data has the same client_id as the current iteration of reportData...
                        changedAttributes.forEach((ea_attr) => {
                            if (ea_attr === 'form470AFYstatusId') {
                                record['form470AFYstatusText'] =
                                    updatedRecordsMap.get(uniqueID)['form470AFYstatusText'];
                            }
                            record[`${ea_attr}`] = updatedRecordsMap.get(uniqueID)[`${ea_attr}`];
                        });
                        record['userEnteredFieldUpdatedByUserName'] =
                            updatedRecordsMap.get(uniqueID)['userEnteredFieldUpdatedByUserName'];
                        record['userEnteredFieldUpdatedTimestamp'] =
                            updatedRecordsMap.get(uniqueID)['userEnteredFieldUpdatedTimestamp'];
                    }
                    return record;
                });

                setReportData(updatedTrackerData);
                resetThreeBulkStateSets();
                toast.success(`Successfully bulk edited (${selectionModel.length}) applications`, {
                    autoClose: 3000,
                });
            } else {
                resetStateForFailedAPIResponse();
                toast.error(`Error saving bulk edits for (${selectionModel.length}) applications`);
            }
        } catch (error) {
            resetStateForFailedAPIResponse();
            toast.error('An error occurred while saving bulk edits');
            console.error('Error in handleBulkEditSaveAPICall:', error);
        }
    };

    // ################################### Return Component #############################################
    return (
        <>
            <Dialog open={openBulkEditDialog} onClose={handleBulkEditClose} PaperProps={{ style: { minWidth: '80%' } }}>
                <DialogTitle sx={{ fontSize: '1.0rem' }}>
                    {`Bulk editing for (${selectionModel.length}) applications`}
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {/* FIRST COLUMN */}
                        <Grid item xs={6}>
                            {/* Expected Number of Minibids Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <BulkEditDropdown
                                    initialValue={bulkEditExpectedNumberOfMinibidsIV}
                                    value={bulkEditExpectedNumberOfMinibids}
                                    setValue={setBulkEditExpectedNumberOfMinibids}
                                    dropDownOptions={false}
                                    label={
                                        bulkEditExpectedNumberOfMinibidsHMV
                                            ? 'Expected # of Minibids (Multiple Values)'
                                            : 'Expected # of Minibids'
                                    }
                                    multipleValues={bulkEditExpectedNumberOfMinibidsHMV}
                                />
                            </FormControl>

                            {/* Expected Number of Form 470s Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <BulkEditDropdown
                                    initialValue={bulkEditExpectedNumberOfForm470sIV}
                                    value={bulkEditExpectedNumberOfForm470s}
                                    setValue={setBulkEditExpectedNumberOfForm470s}
                                    dropDownOptions={false}
                                    label={
                                        bulkEditExpectedNumberOfForm470sHMV
                                            ? 'Expected # of Forms 470 (Multiple Values)'
                                            : 'Expected # of Forms 470'
                                    }
                                    multipleValues={bulkEditExpectedNumberOfForm470sHMV}
                                />
                            </FormControl>

                            {/* Form 470 Status Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <InputLabel id='bulk-edit-form-470-status-label'>
                                    {bulkEditForm470AFYstatusHMV
                                        ? 'Form 470 Status (Multiple Values)'
                                        : 'Form 470 Status'}
                                </InputLabel>
                                <BulkEditDropdown
                                    initialValue={bulkEditForm470AFYstatusIV}
                                    // value={afyStatusEditOptions.find((option) => option.text === bulkEditForm470AFYstatus).value}
                                    value={bulkEditForm470AFYstatus}
                                    setValue={setBulkEditForm470AFYstatus}
                                    dropDownOptions={afyStatusEditOptions}
                                    label='form-470-status-label'
                                    multipleValues={bulkEditForm470AFYstatusHMV}
                                />
                            </FormControl>

                            {/*Form 470 Initial Outreach Date Picker */}
                            <BulkEditDatePicker
                                label={
                                    bulkEditForm470InitialOutreachDateHMV
                                        ? 'Form 470 Initial Outreach Date (Multiple Values)'
                                        : 'Form 470 Initial Outreach Date'
                                }
                                providedDate={bulkEditForm470InitialOutreachDate}
                                initialDate={bulkEditForm470InitialOutreachDateIV}
                                onDateChange={(newDate) => setBulkEditForm470InitialOutreachDate(newDate)}
                                multipleValues={bulkEditForm470InitialOutreachDateHMV}
                            />

                            {/* Form 470 Notes */}
                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='Form 470 Notes'
                                    value={bulkEditForm470TrackerNotes}
                                    id='form470_app_notes_textfield'
                                    onChange={(event) => setBulkEditForm470TrackerNotes(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={10}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bulkEditForm470TrackerNotes ? 'orange' : '',
                                                borderWidth: bulkEditForm470TrackerNotes ? '2px' : '1px',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        {/* SECOND COLUMN */}
                        <Grid item xs={6}>
                            {/* Expected Number of Form 471s Dropdown */}
                            <FormControl fullWidth margin='dense'>
                                <BulkEditDropdown
                                    initialValue={bulkEditExpectedNumberOfForm471sIV}
                                    value={bulkEditExpectedNumberOfForm471s}
                                    setValue={setBulkEditExpectedNumberOfForm471s}
                                    dropDownOptions={false}
                                    label={
                                        bulkEditExpectedNumberOfForm471sHMV
                                            ? 'Expected # of Forms 471 (Multiple Values)'
                                            : 'Expected # of Forms 471'
                                    }
                                    multipleValues={bulkEditExpectedNumberOfForm471sHMV}
                                />
                            </FormControl>

                            {/* Form 471 Notes */}
                            <FormControl fullWidth={true} margin='normal'>
                                <TextField
                                    label='Form 471 Notes'
                                    value={bulkEditForm471TrackerNotes}
                                    id='form471_app_notes_textfield'
                                    onChange={(event) => setBulkEditForm471TrackerNotes(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline
                                    rows={10}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bulkEditForm471TrackerNotes ? 'orange' : '',
                                                borderWidth: bulkEditForm471TrackerNotes ? '2px' : '1px',
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            handleBulkEditNextButtonClicked();
                            setNextButtonClicked(true);
                        }}
                        disabled={isNextButtonDisabled}
                    >
                        Next
                    </Button>
                    <Button onClick={handleBulkEditClose}>Cancel</Button>{' '}
                </DialogActions>
            </Dialog>

            {/* Creates the "confirmation page" dialog and displays the changes between the old matching records and the newly-edited records within a table */}
            <Dialog
                open={openBulkEditConfirmChangesTable}
                onClose={() => {
                    handleBulkEditCloseConfirmationPage();
                }}
                aria-labelledby='bulk-edit-changes-confirmation-title'
                fullWidth
                maxWidth='lg'
            >
                {bulkEditProgressIsOpen && (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.modal + 1,
                            display: 'flex',
                            position: 'absolute',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        open={bulkEditProgressIsOpen}
                        invisible={!bulkEditProgressIsOpen}
                    >
                        <CircularProgress color='inherit' />
                        <Typography variant='body1' sx={{ marginTop: 2, color: '#fff' }}>
                            Saving Bulk Edits...
                        </Typography>
                    </Backdrop>
                )}
                <DialogTitle id='bulk-edit-changes-confirmation-title' sx={{ fontSize: '1em' }}>
                    Confirm Bulk Edit Changes
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                        BEN
                                    </TableCell>
                                    <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                        Applicant Name
                                    </TableCell>
                                    {changedAttributes.map((changed_attr) => (
                                        <TableCell
                                            key={changed_attr}
                                            align='center'
                                            style={{ borderRight: '1px solid #ddd' }}
                                        >
                                            {attributeHeaderMapping[changed_attr]}
                                            {changed_attr.toLowerCase().includes('notes') && (
                                                <div style={{ fontSize: '0.75em', color: '#6c757d' }}>
                                                    (prepended to existing note)
                                                </div>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allChanges.map((change_obj) => (
                                    <TableRow key={change_obj.rowID}>
                                        <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                            {change_obj.ben}
                                        </TableCell>
                                        <TableCell align='center' style={{ borderRight: '1px solid #ddd' }}>
                                            {change_obj.clientName}
                                        </TableCell>
                                        {changedAttributes.map((changed_attr) => (
                                            <TableCell
                                                key={`${change_obj.rowID}-${changed_attr}`}
                                                align='center'
                                                style={{ whiteSpace: 'pre-wrap', borderRight: '1px solid #ddd' }}
                                            >
                                                {change_obj.changes[changed_attr] ? (
                                                    changed_attr.toLowerCase().includes('notes') ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {change_obj.changes[changed_attr].newValue !== undefined
                                                                ? '<Prepending Note>'
                                                                : '<blank>'}
                                                        </div>
                                                    ) : change_obj.changes[changed_attr].oldValue ===
                                                      change_obj.changes[changed_attr].newValue ? (
                                                        'No Change'
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                {change_obj.changes[changed_attr].oldValue !== undefined
                                                                    ? applyOldRowTransformations(
                                                                          change_obj.rowID,
                                                                          change_obj.changes[changed_attr].oldValue,
                                                                          changed_attr
                                                                      ) || '<blank>'
                                                                    : '<blank>'}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <KeyboardArrowDownIcon style={{ color: '#4CAF50' }} />
                                                            </div>
                                                            <div>
                                                                {change_obj.changes[changed_attr].newValue !== undefined
                                                                    ? applyNewRowTransformations(
                                                                          change_obj.rowID,
                                                                          change_obj.changes[changed_attr].newValue,
                                                                          changed_attr
                                                                      ) || ''
                                                                    : '<blank>'}
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    'No Change'
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button disabled={backButtonIsDisabled} onClick={handleBulkEditBackButtonClicked}>
                        Back
                    </Button>
                    <Box flexGrow={1} display='flex' justifyContent='flex-end'>
                        <Button
                            onClick={handleBulkEditConfirmButtonClicked}
                            color='primary'
                            disabled={confirmButtonIsDisabled}
                        >
                            Confirm
                        </Button>
                        <Button disabled={cancelButtonIsDisabled} onClick={handleBulkEditClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};
