import React from 'react';
import { useParams } from 'react-router-dom';
import ClientFundingYearFormDetailsAllECF from '../components/ClientFundingYearFormDetailsAllECF.js';
import ClientAPI from '../api/ClientAPI.js';

export default function ClientFundingYearFormDetailsAllECFPage() {
    const clientAPI = new ClientAPI();
    let params = useParams();

    // Prop names are defined in App.js.
    return (
        <ClientFundingYearFormDetailsAllECF
            clientAPI={clientAPI}
            kyList={params.kyList}
            frnNumber={params.frnNumber}
            searchType={params.searchType}
        />
    );
}
